import { CBContext, CBEventInfo, TagItem } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { SUse } from "../../shared-funcs/SUse"

export class sc_use_webcomponent extends HTMLElement {
    ci: web_sc_use | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            let nsid = this.getAttribute('nsid') as string;
            this.ci = new web_sc_use(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('sc-use', sc_use_webcomponent);

export class web_sc_use extends CodeBrick {

    element: HTMLElement;
    reqseq = 0;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("sc-use "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));

        this.emit_state("loading");

        let odata = SUse.GetSUseParams(this, input);
        let tag_vals = [] as any[];
        if(this.parsed_ins && this.parsed_ins[input]) {
            // There is a case
            // Where a tag has several sections.
            // When none of them has source, the tag should keep its value. This works fine on the client. But when it is a client to server request, the tag is resolved on the server, and the server has no memory of the tag value to keep. It resolves with the “” source, so it takes the first token that resolves. This might be the wrong one.
            // So we send the TagItem.vals. We send them by position in the tree

            let in_leaves = this.parsed_ins[input];     
            for(let leaf of in_leaves.leaves) {
                for(let part of leaf.parts) {
                    if(part.tag) {
                        tag_vals.push(part.tag.val);
                    }
                }
            }          

        }

        //console.log("sc-use "+this.blueprint.name+" params "+JSON.stringify(odata));

        let res_data = await this.api_request(odata, info, input, tag_vals);
        this.emit_state("");

        if(res_data && res_data.success === false) {
            console.error(this.blueprint.name+" emit response "+JSON.stringify(res_data));// + " "+res_data);
        }

        //console.log("sc-use "+this.blueprint.name+" return "+JSON.stringify(res_data));

        return res_data;   
    }

    async api_request(odata: any, info: CBEventInfo, input: string, tag_vals: any[]) : Promise<any> {

        let res_data: any;

        let method = "POST";

        let options = { method };
        let uri = "/cb_api/cb/sc-use"

        this.reqseq++;

        let post_body = {        
            composition: this.context.compositions[this.cid].composition,
            unit_path: this.blueprint.name,
            odata: odata,
            source: info.source,
            source_output: info.source_output,
            input: input,
            tag_vals: tag_vals,
            timezone_offset: this.context.timezone_offset,
            reqseq: this.reqseq
        } as any;

        if(this.context.composition_runners[this.cid].debugging_enabled) {
            post_body.debugging_enabled = this.context.composition_runners[this.cid].debugging_enabled
        }

        (<any>options).body = JSON.stringify(post_body);
        
        (<any>options).credentials = 'include';

        (<any>options).headers = { "Content-Type":"application/json"};

        //console.log("web_sc_use "+this.brick_id+" post_body "+JSON.stringify(post_body));

        let res = await fetch(uri, options);

        let respseq = res.headers.get("respseq");
        if(respseq) {
            if(Number(respseq) < this.reqseq) {
                //Stale response, a newer request has been made.
                return;
            }
        }

        let body = await res.text();
        if(body === "") {
            return; //We do not emit an empty resonse. This gives the server side a way no not emit. Used for request sequencing #rr
        }

        //console.log("web_sc_use "+this.brick_id+" response body "+body);



        try {
            res_data = JSON.parse(body);
        }
        catch(err) {
            res_data = body;
        }
        return res_data;
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}
