// Performance-optimized version
type StyleRule = {
    selector: string;
    styles: string;
    className?: string;
};

type NodeQueueEntry = {
    node?: HTMLElement;
    id?: string;
    rules: StyleRule[] | null;
}

class InlineStyleProcessor {
    private classCounter: number;
    private styleElement: HTMLStyleElement | null;
    private rootId: string;

    private has_rules = {} as { [rule:string]:string };

    queueId = 0;
    
    constructor(rootId: string) {
        this.classCounter = 1;
        this.styleElement = null;
        this.rootId = rootId;

        if (!this.styleElement) {
            this.styleElement = document.createElement('style');
            this.styleElement.id = "inline-classes";
            document.head.appendChild(this.styleElement);
        }
    }

    // style rules can be supplied, else they will be retreived from the inline-class attribute.
    public processNode(element: HTMLElement, rules: StyleRule[] | null = null): string[] {

        performance.mark('processStart');

        if(rules == null) {
            const inlineClass = element.getAttribute('inline-class');
            if (!inlineClass) return [];

            rules = this.parseInlineClass(inlineClass);
        }

        const addedClasses = this.rulesToStyles(rules);
        
        // Batch DOM operations
        element.classList.add(...addedClasses);
        element.removeAttribute('inline-class');

        performance.mark('processEnd');
        performance.measure('processingTime', 'processStart', 'processEnd');
        
        const measure = performance.getEntriesByName('processingTime')[0];
        //console.log(`Processing time: ${measure.duration}ms`);

        (<any>window).totalProcessNode += measure.duration;

        return addedClasses;
    }

    rulesToStyles(rules: StyleRule[] | null = null) : string[] {

        if(!rules) {
            return [];
        }

        const addedClasses: string[] = [];
        
        rules.forEach((rule) => {
            if(!rule.styles || rule.styles.indexOf(":") == -1) {
                return;
            }

            let ruleStr = `${rule.selector} {${rule.styles}}`;

            if(this.has_rules[ruleStr]) {
                addedClasses.push(this.has_rules[ruleStr]);
            }
            else {
                const className = `${this.rootId}-${this.classCounter}`;
                addedClasses.push(className);

                this.has_rules[ruleStr] = className;

                if(!this.styleElement) {
                    return;
                }

                let styleSheet = this.styleElement.sheet;

                if(!styleSheet) {
                    return addedClasses;
                }

                //console.log(className+rule.selector + "{"+rule.styles+"}");

                if(rule.selector.startsWith("sm")) {
                    //styleSheet?.insertRule(`@media (min-width:640px) { .${className}${rule.selector.substring(2)} {${rule.styles}} }`, this.getRuleInsertIdx(styleSheet.cssRules, ["640px"]));
                    this.insertRule(styleSheet, `.${className}${rule.selector.substring(2)} {${rule.styles}}`, "(min-width: 640px)", []);
                }
                else if(rule.selector.startsWith("md")) {
                    //styleSheet?.insertRule(`@media (min-width:768px) { .${className}${rule.selector.substring(2)} {${rule.styles}} }`, this.getRuleInsertIdx(styleSheet.cssRules, ["640px", "768px"]));
                    this.insertRule(styleSheet, `.${className}${rule.selector.substring(2)} {${rule.styles}}`, "(min-width: 768px)", ["640px"]);
                }
                else if(rule.selector.startsWith("lg")) {
                    //styleSheet?.insertRule(`@media (min-width:1024px) { .${className}${rule.selector.substring(2)} {${rule.styles}} }`, this.getRuleInsertIdx(styleSheet.cssRules, ["640px", "768px", "1024px"]));
                    this.insertRule(styleSheet, `.${className}${rule.selector.substring(2)} {${rule.styles}}`, "(min-width: 1024px)", ["640px", "768px"]);
                }
                else if(rule.selector.startsWith("xl")) {
                    //styleSheet?.insertRule(`@media (min-width:1280px) { .${className}${rule.selector.substring(2)} {${rule.styles}} }`, this.getRuleInsertIdx(styleSheet.cssRules, ["640px", "768px", "1024px", "1280px"]));
                    this.insertRule(styleSheet, `.${className}${rule.selector.substring(2)} {${rule.styles}}`, "(min-width: 1280px)", ["640px", "768px", "1024px"]);
                }
                else if(rule.selector.startsWith("2xl")) {
                    //styleSheet?.insertRule(`@media (min-width:1536px) { .${className}${rule.selector.substring(2)} {${rule.styles}} }`, this.getRuleInsertIdx(styleSheet.cssRules, ["640px", "768px", "1024px", "1280px", "1536px"]));
                    this.insertRule(styleSheet, `.${className}${rule.selector.substring(3)} {${rule.styles}}`, "(min-width: 1536px)", ["640px", "768px", "1024px", "1280px"]);
                }
                else {
                    //styleSheet?.insertRule(`.${className}${rule.selector} {${rule.styles}}`, this.getRuleInsertIdx(styleSheet.cssRules, []));
                    this.insertRule(styleSheet, `.${className}${rule.selector} {${rule.styles}}`, "", []);
                }
                
                this.classCounter++;
            }
        });

        return addedClasses;
    }

    insertRule(styleSheet: CSSStyleSheet, rule: string, insertInto: string, insertAfter: string[], ) {
        for(let i = styleSheet.cssRules.length - 1; i >= 0; i--) {
            let cssRule = styleSheet.cssRules[i] as any;
            if(!cssRule.conditionText) {
                if(insertInto) {
                    styleSheet.insertRule(`@media ${insertInto} {${rule}}`, i+1);
                }
                else {
                    styleSheet.insertRule(rule, i+1);
                }
                return;
            }
            if(cssRule.conditionText == insertInto) {
                styleSheet.insertRule(`@media ${insertInto} {${rule}}`, i+1);
                return;
            }
            for(let check of insertAfter) {
                if(cssRule.conditionText.indexOf(check) != -1) {
                    if(insertInto) {
                        styleSheet.insertRule(`@media ${insertInto} {${rule}}`, i+1);
                    }
                    else {
                        styleSheet.insertRule(rule, i+1);
                    }
                    return;
                }
            }
        }
        if(insertInto) {
            styleSheet.insertRule(`@media ${insertInto} {${rule}}`);
        }
        else {
            styleSheet.insertRule(rule);
        }
    }

    // Use regex compilation once
    private static ruleRegex = /\((.*?)\{(.*?)\}\)/g;
    
    private parseInlineClass(inlineClass: string): StyleRule[] {
        const rules: StyleRule[] = [];
        let match;

        while ((match = InlineStyleProcessor.ruleRegex.exec(inlineClass)) !== null) {
            rules.push({
                selector: match[1].trim(),
                styles: match[2].trim()
            });
        }

        return rules;
    }

    // Inject styles immediately when needed
//     public injectStyles(): void {

//         performance.mark('processStart');

//         if (!this.styleElement) {
//             this.styleElement = document.createElement('style');
//             this.styleElement.id = "inline-classes";
//             document.head.appendChild(this.styleElement);
//         }

//         let stylesheet = this.styleRules
//             .map(rule => `${rule.className}{${rule.styles}}`)
//             .join('\n');

//         const stylesheet_sm = this.styleRules_sm
//             .map(rule => `${rule.className}{${rule.styles}}`)
//             .join('\n');
        
//         const stylesheet_md = this.styleRules_md
//             .map(rule => `${rule.className}{${rule.styles}}`)
//             .join('\n');

//         const stylesheet_lg = this.styleRules_lg
//             .map(rule => `${rule.className}{${rule.styles}}`)
//             .join('\n');

//         const stylesheet_xl = this.styleRules_xl
//             .map(rule => `${rule.className}{${rule.styles}}`)
//             .join('\n');

//         const stylesheet_2xl = this.styleRules_2xl
//             .map(rule => `${rule.className}{${rule.styles}}`)
//             .join('\n');
            
//         if(stylesheet_sm) {
//             stylesheet += `
// @media (min-width: 640px) {
//     ${stylesheet_sm}
// }`;
//         }
//         if(stylesheet_md) {
//             stylesheet += `
// @media (min-width: 768px) { 
//     ${stylesheet_md}
// }`
//         }
//         if(stylesheet_lg) {
//             stylesheet += `
// @media (min-width: 1024px) {  
//     ${stylesheet_lg}
// }`
//         }
//         if(stylesheet_xl) {
//             stylesheet += `
// @media (min-width: 1280px) {
//     ${stylesheet_xl}
// }`
//         }
//         if(stylesheet_2xl) {
//             stylesheet += `
// @media (min-width: 1536px) {
//     ${stylesheet_2xl}
// }`
//         }
    
//         this.styleElement.textContent = stylesheet;

//         // Clear processed rules
//         //this.styleRules = [];

//         performance.mark('processEnd');
//         performance.measure('processingTime', 'processStart', 'processEnd');
        
//         const measure = performance.getEntriesByName('processingTime')[0];
//         //console.log(`Processing time: ${measure.duration}ms`);

//         (<any>window).totalInjectStyles += measure.duration;
//     }

    // queueNode(node: HTMLElement, rules: StyleRule[] | null = null) {
    //     // this.nodeQueue.push({ node, rules });

    //     // this.queueId++;
    //     // let qid = this.queueId;
    //     // let self = this;
    //     // setTimeout(function() { self.processQueue(qid) } , 1);

    //     this.processNode(node, rules);
    //     //this.injectStyles();   
        
    // }

    // queueNodeId(id: string, rules: StyleRule[] | null = null) {
    //     // this.nodeQueue.push({ id, rules });

    //     // this.queueId++;
    //     // let qid = this.queueId;
    //     // let self = this;
    //     // setTimeout(function() { self.processQueue(qid) } , 1);

    //     let node = document.getElementById(id) as HTMLElement;
    //     if(node) {
    //         this.processNode(node, rules);
    //         //this.injectStyles();  
    //     }
    // }

    // processQueue(qid: number) {
    //     if(qid == this.queueId) {
    //         for(let qe of this.nodeQueue) {
    //             let node = qe.node;
    //             if(!node && qe.id) {
    //                 node = document.getElementById(qe.id) as HTMLElement;
    //             }
    //             if(node) {
    //                 this.processNode(node, qe. rules);
    //             }
    //         }
    //         this.nodeQueue = [];
    //         this.injectStyles();   
    //     }
    // }

    processContainer(container: HTMLElement) {
        if(container) {
            const elements = container.querySelectorAll('[inline-class]');
            elements.forEach(element => {
                (<any>window).inlineClassProcessor.processNode(element as HTMLElement);
                //(<any>window).inlineClassProcessor.queueNode(element);
            });
            //(<any>window).inlineClassProcessor.injectStyles();
        }
        return container.innerHTML;
    }
}

// Usage with MutationObserver for dynamic content
export function setupInlineStyleProcessing(rootId: string) {
    (<any>window).inlineClassProcessor = new InlineStyleProcessor(rootId);

    (<any>window).totalProcessNode = 0;
    (<any>window).totalInjectStyles = 0;
    
    // Process existing elements
    const processExisting = () => {
        performance.mark('processStart');
        
        const elements = document.querySelectorAll('[inline-class]');
        elements.forEach(element => {
            (<any>window).inlineClassProcessor.processNode(element as HTMLElement);
        });
        //(<any>window).inlineClassProcessor.injectStyles();
        
        performance.mark('processEnd');
        performance.measure('processingTime', 'processStart', 'processEnd');
        
        const measure = performance.getEntriesByName('processingTime')[0];
        console.log(`Processing time: ${measure.duration}ms`);
    };

    // Process dynamic elements
    // const observer = new MutationObserver((mutations) => {
    //     performance.mark('processStart');
    //     let needsUpdate = false;
        
    //     mutations.forEach(mutation => {
            
    //         mutation.addedNodes.forEach(node => {
    //             if (node instanceof HTMLElement) {
    //                 if (node.hasAttribute('inline-class')) {
    //                     (<any>window).inlineClassProcessor.processNode(node);
    //                     needsUpdate = true;
    //                 }
                    
    //                 node.querySelectorAll('[inline-class]').forEach(element => {
    //                     (<any>window).inlineClassProcessor.processNode(element as HTMLElement);
    //                     needsUpdate = true;
    //                 });
    //             }
    //         });
    //     });
        
    //     if (needsUpdate) {
    //         (<any>window).inlineClassProcessor.injectStyles();
    //     }

    //     performance.mark('processEnd');
    //     performance.measure('processingTime', 'processStart', 'processEnd');
        
    //     const measure = performance.getEntriesByName('processingTime')[0];
    //     console.log(`MutationObserver Processing time: ${measure.duration}ms`);
    // });

    // Start observing
    // observer.observe(document.body, {
    //     childList: true,
    //     subtree: true
    // });

    // Initial processing
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', processExisting);
    } else {
        processExisting();
    }

    return {
        inlineClassProcessor: (<any>window).inlineClassProcessor,
        //observer,
        //cleanup: () => observer.disconnect()
    };
}