
// let CodeBricks_compostion_template = document.createElement('template');
// CodeBricks_compostion_template.innerHTML = `<style>
//     .c-button { background-color: blue }
// </style>
// <slot></slot>`;

import { CBCompositionRunner } from "../codebricks-runtime/CBCompositionRunner";
import { Brick, Composition, DebugLog } from "../codebricks-runtime/CBModels";
import { ObjectDeepMerge } from "../codebricks-runtime/CBUtil";
import { ExportFuncs } from "../shared-funcs/ExportFuncs";

export class web_composition_loader extends HTMLElement {

    composition_id = '_';

    rtcn: CBCompositionRunner | undefined;

    constructor() {
        super();
    }
    connectedCallback() {

        //let shadowRoot = this.attachShadow({ mode: 'open' });
        //shadowRoot.appendChild(CodeBricks_compostion_template.content.cloneNode(true));
        this.innerHTML = `<div></div>`;

        //let api_path = this.getAttribute('api-path');
        let composition = this.getAttribute('composition');
        if(composition == "null") {
            return;
        }

        let self = this;

        let urlParams = this.getUrlParams();

        if (composition) {
            fetch('/cb_api/cb/composition/' + composition, {
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json' 
                } 
            }).then(

                function (response) {
                    //console.log(api_path + '/' + composition);

                    if (response.status !== 200) {
                        console.log('Error fetching composition json. Status Code: ' +
                            response.status);
                        return;
                    }

                    response.json().then(function (compos: Composition) {

                        let context = (globalThis as any).codebricks_context;

                        if(context) {
                            delete context.compositions[self.composition_id];
                        }

                        self.rtcn = new CBCompositionRunner(composition as string, (<any>globalThis).codebricks_context,
                            // async function() {
                            //     return await CBWebUtil.GetRequest("/cb_api/admin/debug_log/"+composition);
                            // },
                            //@ts-expect-error
                            undefined,
                            // async function(led: DebugLog) {
                            //     return await CBWebUtil.PostRequest("/cb_api/admin/debug_log/"+composition, led);
                            // }
                        );

                        self.rtcn.context.platform = compos.platform || "web";
                        if(compos.snapshot && compos.snapshot.snapshot) {
                            ExportFuncs.ApplyBrickSnapshots(compos as Brick, compos.snapshot.snapshot);
                        }
                        self.rtcn.context.timezone_offset = new Date().getTimezoneOffset();
                        if(compos.snapshot && compos.snapshot.timezone_offset !== undefined) {
                            self.rtcn.context.timezone_offset = compos.snapshot.timezone_offset;
                        }

                        compos.ins = compos.ins || {};


                        if(compos.snapshot && compos.snapshot.snapshot) {
                            ExportFuncs.ApplyBrickSnapshots(compos as Brick, compos.snapshot.snapshot);
                        }

                        self.rtcn.init_composition(compos, urlParams);

                        compos.ins = compos.ins || {};
                        compos.ins.args = compos.ins.args || {};
                        let home_slug = compos.ins.args.slug;
                        ObjectDeepMerge(compos.ins.args, urlParams);
                        if(home_slug) {
                            compos.ins.args.slug = home_slug; //The home page is / so has no slug in url so it was set here in app.js
                        }

                        let slot = self.querySelector('div');
                        if(slot) {
                            slot.innerHTML = `<${compos.type} cid="${self.rtcn.cid}" name="${compos.name}"></${compos.type}>`;
                        }

                        if(window.localStorage.getItem("cb_locale")) {
                            compos.system_options.locale = window.localStorage.getItem("cb_locale");
                        }
                                            
                        self.rtcn.send_initialisation_events(null);
                    });
                }
            )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
        }
    }

    getUrlParams() {
        let urlParams = {} as { [key:string]: string };
        let match,
        pl = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s: string) {
            return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

        while (match = search.exec(query)) {
            if (decode(match[1]) in urlParams) {
                if (!Array.isArray(urlParams[decode(match[1])])) {
                    (<any>urlParams)[decode(match[1])] = [urlParams[decode(match[1])]];
                }
                (<any>urlParams[decode(match[1])]).push(decode(match[2]));
            } else {
                urlParams[decode(match[1])] = decode(match[2]);
            }
        }

        if(!urlParams.slug) {
            let s = window.location.pathname.split("/");
            let slug = s[s.length-1];
            urlParams.slug = slug;
        }

        return urlParams;
    }

      
    disconnectedCallback() {
        //console.log("disconnectedCallback");
        // let cbglobal = <CBGlobal>(<any>globalThis).cbglobal;

        // delete cbglobal.compositions[this.composition_id];

        // if(this.parentNode) {
        //     this.parentNode.removeChild(this);
        // }

        // alert("disconnectedCallback");

    }
      
    // attributeChangedCallback(name, oldValue, newValue) {
    //     console.log("attributeChangedCallback");
    // }
      
    adoptedCallback() {
        //console.log("adoptedCallback");
    }
    
}

customElements.define('cb-composition-loader', web_composition_loader);

// (function() {
//     console.log("HELLO");
// })()