import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_select } from "../controls/cb_select";
import { Select } from "../../shared-funcs/Select";

export class c_select_webcomponent extends HTMLElement {
    ci: web_c_select | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;

            //standalone
            let ins = this.getAttribute('ins') as string;

            this.ci = new web_c_select(context, cid, name, dc, Number(idx), container_id, this, ins);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-select', c_select_webcomponent);

export class web_c_select extends CodeBrick {

    element: HTMLElement;
    initialised = false;

    options = [] as any[];

    no_loader = false;

    cb_select_control: cb_select;

    snapshot = undefined as any;
    value = undefined as any;

    //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement, standalone_ins: string) {
        super(context, cid, name, dc, idx, container_id, standalone_ins);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        //Add this to all bricks that need to support standalone, as well as the ins parameter
        if(cid == undefined) {
            this.blueprint.name = element.id;
            this.brick_id = element.id + "_brick";
            (<any>window).so_bricks =  (<any>window).so_bricks || {};
            (<any>window).so_bricks[this.brick_id] = this;
        }

        this.element.innerHTML = `<div id="${this.brick_id}"></div>`;

        let self = this;
        this.cb_select_control = new cb_select(this.brick_id, this.blueprint.ins.cfg, function(value:any, option_object: any, value_snapshot: any) {
            self.snapshot = self.snapshot || {};
            self.snapshot.cfg = self.snapshot.cfg || {};
            self.snapshot.cfg.value = value_snapshot || value;

            self.value = value;
            self.cb_emit({"@": value, "@option_object": option_object, "@user_changed" : value });
        }, this.context.platform);

        //Add this also to all bricks that need to support standalone
        if(cid == undefined) {
            setTimeout(async function() {
                for(let input in self.blueprint.ins) {
                    let ret = await self.cb_event(input, self.blueprint.ins[input], {} as CBEventInfo);
                }
            } ,0);
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("c-select "+this.blueprint.name+" cb_event "+input+": "+JSON.stringify(cfg));

        if(input == 'cfg') {

            this.options = Select.getOptions(cfg, this.blueprint);

            //console.log("Select.getOptions "+JSON.stringify(this.options));
            
            let val = cfg.value;
            if(this.initialised) {
                if(!this.IsInsLeafFiring(input, "value")) {
                    val = this.value;
                }
            }

            this.cb_select_control.setOptions(this.options, cfg);
            let selected_option = this.cb_select_control.setSingleSelectValue(val, cfg);

            this.initialised = true;

            if(selected_option) { // && selected_option.value !== this.value) {//this.cb_select_control.value !== undefined && this.cb_select_control.value !== null) {

                this.value = selected_option.value;
                //console.log("c-select return "+this.brick_id+ ": " + JSON.stringify(selected_option));


                return { "@": selected_option.value, "@option_object": selected_option.full };
            }
            
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {

        //console.log("cb_status "+this.brick_id + " " +status);

        // if(status == "required") {
        //     let input = document.getElementById(this.brick_id);
        //     if(input) {
        //         (<HTMLSelectElement>input).classList.add("required");
        //     }
        // }

        let container = document.getElementById(this.brick_id+"$container");
        let loader = document.getElementById(this.brick_id+"_loader");
        if(status == "loading" && !this.no_loader) {
            if(container) {
                container.classList.add("loading");
            }
            if(loader) {
                loader.classList.add("loader-loading");
            }
        }
        else {
            if(container) {
                container.classList.remove("loading");
            }
            if(loader) {
                loader.classList.remove("loader-loading");
            }
        }
    }

    cb_snapshot() {
        return this.snapshot;
    }
}
