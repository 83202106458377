/*

Mode 1 Rules – “propagation”
1.	Populate and mark all terms
2.	Only evaluate sections that contain source. The rest stays as is.
3.	If evaluated section does not resolve, section undefined.
4.	If no evaluated sections in the tag resolve, the tag should return undefined.
Mode 0 rules – “initialisation”
1.	Populate and mark all terms
2.	Evaluate all sections, until a section resolves.
3.	The tag value is the value of that section, else if no sections resolve, undefined
Common rules
1.	A section resolves only if all terms resolve.
2.	Additionally, if you have ? without : the condition also has to resolve

The insleaves should indicate if it has run or not. 
If it has not, it has to run all tags.
Else it can stop at the first tag that does not resolve.


*/


import { codebricks_format } from "./CBFormatting";
import { SeqOutData, TagItem } from "./CBModels";
import { TemplateUtil } from "./TemplateUtil";

export class TagItemResolver {

    seq_out_data: SeqOutData;
    current_run_id: number;
    source: string;
    source_output: string;
    system_options: any;

    constructor(seq_out_data: SeqOutData, current_run_id: number, source: string, source_output: string, system_options: any) {
        this.seq_out_data = seq_out_data;
        this.current_run_id = current_run_id;
        this.source = source;
        this.source_output = source_output;
        this.system_options = system_options;
    }

    ResolveTag(tag: TagItem, mode: number, unresolved_out: string[]) : any {
        tag.contains_source = false; //the source changes, so we cant keep this
        if(tag.type[0] == "{") {  
            let volatile = tag.type[1] == "*"; //Note volatile applies to entire tag
            if(volatile && tag.run_id != this.current_run_id) {
                tag.val = undefined;
            }
            tag.run_id = this.current_run_id;
            if(tag.items) {
                for(let section of tag.items) {
                    if(section.type != ";") {
                        this.ResolveSection(section, mode, volatile, unresolved_out);
                    }
                    if(section.contains_source) {
                        tag.contains_source = true;
                    }
                }
                let resolve_section = undefined as any;
                let format_section = undefined as any;
                for(let section of tag.items) {
                    if(section.type == ";") {
                        format_section = section;
                    }
                    else if(resolve_section === undefined) { 
                        if(mode == 0) {
                            if(section.val !== undefined) {
                                resolve_section = section;
                            }
                        }
                        else if(mode == 1) {
                            if(section.contains_source) {
                                tag.contains_source = true;
                                if(section.val !== undefined) {
                                    resolve_section = section;
                                }
                            }
                        }
                    }
                }
                if(resolve_section) {
                    tag.val = resolve_section.val;
                    if(format_section) {
                        tag.val = codebricks_format(tag.val, format_section.slice, this.system_options, true).value;
                    }
                    tag.firing = true;
                    return tag.val;
                }
                else {
                    tag.firing = false;
                    return tag.val;

                    // if(mode == 0) {
                    //     return undefined; //This tag did not resolve in mode 0 This should be the same as tag.val, which should also be undefined here.
                    // }
                    // if(mode == 1) {
                    //     if(tag.contains_source) {
                    //         return undefined; //Don't propage if hit
                    //     }
                    //     else {
                    //         //This is a tag unrelated to this event. Just return its prev value
                    //         return tag.val;
                    //     }
                    // }
                }
            }
        }
    }

    ResolveSection(section: TagItem, mode: number, volatile: boolean, unresolved_out: string[]) {
        //section.val = undefined; -> We don't set to undefined, we leave as undefined
        let coerce_to_bool = false;
        section.contains_source = false;
        let has_unresolved_term = false;
        if(section.items) {
            //get the term values
            section.contains_source = false;
            //let no_non_static = true;
            //let has_static = false;
            for(let term of section.items) {
                if(term.type == "p") {
                    this.ResolveTerm(term, mode, volatile, unresolved_out);
                    if(term.val === undefined) {
                        section.val = undefined;
                        has_unresolved_term = true;
                    }
                }
                else if(term.type == "{") {
                    this.ResolveTag(term, 0, unresolved_out);
                    if(term.val === undefined) {
                        section.val = undefined;
                        has_unresolved_term = true;
                    }
                }
                else if(term.type == "~") {
                    if(!section.contains_source) { // Only fire {{a?b}} when a is source, never when b is source.
                        section.val = undefined;
                        return;
                    }
                }
                else if(term.val !== undefined) { //Exclude joining chars
                    term.static = true;
                }
                if(term.contains_source) {
                    section.contains_source = true;
                }
            }

            if(has_unresolved_term) {
                return;
            }

            let state = '';
            
            for(let t = 0; t < section.items.length; t++) {
                let term = section.items[t];

                if(term.type == '|') {
                    if(section.val) {
                        return;
                    }
                    coerce_to_bool = true;
                }
                if(term.type == '&') { 
                    if(!section.val) {
                        return;
                    }
                    coerce_to_bool = true;
                }

                if(term.type == "-") {
                //     let nextval = section.items[t + 1];
                //     if(nextval && nextval.val !== undefined) {
                //         if(typeof nextval.val == "number") {
                //             nextval.val = -nextval.val; //this is or x=-1 scenario, as opposed to {{x?y-z:a}}
                //             term.type = "+";
                //             //continue; 
                //         }
                //     }
                }

                if(term.val !== undefined) { //values (but not joining chars)

                    if(state == '' || state == '|' || state == '&') {
                        state = 'v';
                        section.val = term.val;
                    }
                    else if(state == 'v') {
                        //2 v's in a row? Error
                        //section.val = undefined;
                        console.error("Template error: 2 values with no operator: "+JSON.stringify(section));
                        break;
                    }
                    else if(state == '=') {
                        section.val = section.val == term.val;
                    }
                    else if(state == '!') {
                        section.val = section.val != term.val;
                    }
                    else if(state == '>') {
                        section.val = section.val > term.val;
                    }
                    else if(state == '<') {
                        section.val = section.val < term.val;
                    }
                    else if(state == '>=') {
                        section.val = section.val >= term.val;
                    }
                    else if(state == '<=') {
                        section.val = section.val <= term.val;
                    }
                    else if(state == '+') {
                        if(section.val === undefined) {
                            section.val =  term.val;
                        }
                        section.val = section.val + term.val;
                    }
                    else if(state == '-') {
                        if(section.val === undefined) {
                            section.val = -term.val;
                        }
                        section.val = section.val - term.val;
                    }
                    else if(state == '?' || state == '~') {
        
                        let choice_value = section.val;
        
                        //first value 1st:2nd
                        let first_value = term.val;
                        if((t + 2) < section.items.length && section.items[t + 1].type == ":") {
                            //Second value
                            let second_term = section.items[t+2];
                            let second_value = undefined;
                            if(second_term !== undefined) {
                                second_value = second_term.val;
                            }
        
                            //section.val = ((choice_value == true) ? first_value : second_value);
                            section.val = (choice_value ? first_value : second_value);

                            t += 2;
                        }
                        else if(first_value !== undefined) {
                            //This is for a==b?c
                            if(choice_value) {
                                section.val = first_value;
                            }
                            else {
                                section.val = undefined;
                                return;
                            }    
                        }
                        else {
                            section.val = '';
                        }
        
                    }
                }
                else {
                    state = term.type;
                }
            }
        }
        if(coerce_to_bool) {
            section.val = section.val == true;
        }
    }

    ResolveTerm(term: TagItem, mode: number, volatile: boolean, unresolved_out: string[]) {
        let tv = term.slice;

        let tparts = TemplateUtil.GetTermParts(tv);//tv.split('.');

        //convert a to a.@, and a.b to a.@.b, but leave a.@o, for template output including default (omitted @)
        if(tparts.length == 1) {
            tparts.push('@');
            tparts.push('d'); //add the d because seq_out_data has the seq_obj inserted, and the data is under d
        }
        else if(tparts[1][0] != '@') {
            tparts.splice(1, 0, '@', 'd');
        }
        else {
            tparts.splice(2, 0, 'd');
        }

        term.contains_source = tparts[0] == this.source && tparts[1] == this.source_output; 
        term.static = tparts[0][0] == "$"; //These are pre populated into seq_out_data, so we count them as available
        
        if(this.seq_out_data[tparts[0]] && this.seq_out_data[tparts[0]][tparts[1]]) {
            let seq_obj = this.seq_out_data[tparts[0]][tparts[1]];
            term.val = ''; //This is for when the value in the seq_out_data is undefined, it should still resolve if the output has emitted, so should not be undefined, but ''

            if(volatile) {
                //volatile means it must be from the same run id to resolve.
                if(seq_obj.r != this.current_run_id) {
                    term.val = undefined; //For volatile we do clear. -> We don't set to undefined, we leave as undefined. If it previously resolved, it should keep that value.
                    unresolved_out.push(tv);
                    return;
                }
            }

            let v = this.seq_out_data as any;
            let res = v;
            
            let done = false;
            for(let p of tparts) { 
                if(done) {
                    res = undefined; 
                    break;
                }
                if(v && v[p] !== undefined) {
                    if(v[p] !== null || typeof v[p] == 'object') {
                        v = v[p];
                    }
                    else {
                        res = v[p];
                        done = true;
                    }
                }
                else {
                    res = undefined;
                    done = true;
                }
            }
            if(!done) {
                res = v;
            }

            if(res !== undefined) {
                term.val = res;
            }
            else {
                unresolved_out.push(tv);
            }
            
        }
        else {
            //term.val = undefined; -> We don't set to undefined, we leave as undefined

            unresolved_out.push(tv);
        }

    }
}