import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { DataTransform } from "../../shared-funcs/DataTransform"

export class ci_datatransform_webcomponent extends HTMLElement {
    ci: web_ci_datatransform | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_datatransform(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-datatransform', ci_datatransform_webcomponent);

export class web_ci_datatransform extends CodeBrick {

    element: HTMLElement;
    data: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("ci-data "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));
        if(input == "cfg") {
            this.data = cfg.data; 

            let transform = cfg.transform;

            if(transform) {

                if(transform.column_transforms && transform.column_transforms.length > 0) {
                    this.data = DataTransform.transformCols(this.data, transform.column_transforms);
                }

                //group
                if(transform.group_by && transform.group_by.group_columns && transform.group_by.group_columns.length > 0) {
                    this.data = DataTransform.groupData(this.data, transform.group_by.group_columns, transform.group_by.count_columns, transform.group_by.sum_columns);
                }

                //console.log('Grouped data: '+JSON.stringify(data));

                if(transform.pivot && transform.pivot.row != "") {
                    this.data = DataTransform.pivotData(this.data, transform.pivot.row, transform.pivot.column, transform.pivot.data);
                }

                if (transform.limit) {
                    this.data = this.data.slice(0, transform.limit);
                }
                
            }

            return { "@" : this.data };
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        this.emit_state(status);
    }
    cb_snapshot() {}
}