import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import showdown from "showdown";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_markdown_webcomponent extends HTMLElement {
    ci: web_c_markdown | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_markdown(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-markdown', c_markdown_webcomponent);

export class web_c_markdown extends CodeBrick {

    element: HTMLElement;
    listener = false;
    md = "";
    no_loader = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.element.innerHTML = `<div id="${this.brick_id}"></div>`;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("CodeBricksHTML "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));

        if(input == 'cfg') {

            if(!cfg || cfg.md === null || cfg.md === undefined) {
                this.md = "";
            }
            else {
                showdown.setFlavor('github');
                var converter = new showdown.Converter(),
                html = converter.makeHtml(cfg.md);
                this.md = html;
            }

            let hidden = '';
            if(cfg.hidden || cfg.hide) {
                hidden = "hidden"
            }

            this.element.innerHTML = `<div id="${this.brick_id}" ${CBWebUtil.GetElementStylesString(hidden, cfg, "container")}>${this.md}</div>`;
            
            let btn = document.getElementById(`${this.brick_id}`);
            if(btn && !this.listener) {
                this.listener = true;
                let self = this;
                btn.addEventListener('click', function() {

                    //console.log(self.blueprint.name+' emit '+JSON.stringify(self.outdata));

                    self.cb_emit({"@":self.blueprint.name});
                });
                
            }
            
            this.no_loader = cfg.no_loader;
        }  
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            if(status == "loading" && !this.no_loader) {
                container.classList.add("loading");
            }
            else {
                container.classList.remove("loading");
            }
        }
        cb_tooltip.set_tooltips(this.element);
    }

    cb_snapshot() {}
}