import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_switchpanel_webcomponent extends HTMLElement {
    ci: web_cc_switchpanel | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_switchpanel(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-switchpanel', cc_switchpanel_webcomponent);

export class web_cc_switchpanel extends CodeBrick {

    element: HTMLElement;
    prev_indentifier = "";
    initialized = false;
    current_idx = 0;
    cements = {} as { [child_idx: number]: any };
    cfg = this.blueprint.ins.cfg;

    snapshot = undefined as any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });

        this.blueprint.ins = this.blueprint.ins || {};   
        this.blueprint.ins.cfg = this.blueprint.ins.cfg || {};   
        //this.blueprint.ins.layout.display = 'flex';
        
        this.element.innerHTML = `<div id="${this.brick_id}"></div>`;
        
        this.init_cement();
    }

    show_panel(panelid: string | number, no_emit = false) {
        let i = 0;
        if(!panelid) {
            panelid = "0";
        }
      
        if(this.blueprint.contains) {
            for(let sub of this.blueprint.contains) {
                let cement = sub.cement || {};
                if(cement.identifier == panelid || i + '' == panelid) {

                    if(this.cfg.type == "Radio") {
                        let radio = document.getElementById(`${this.brick_id}$${i}_radio`) as HTMLInputElement;
                        if(radio) {
                            radio.checked = true;
                        }
                    }
                    else {

                        let prev = document.getElementById(`${this.brick_id}$${this.prev_indentifier|| "0"}`) as HTMLElement;
                        if(prev) {
                            prev.style.display = "none";
                        }
                        let panel = document.getElementById(`${this.brick_id}$${cement.identifier || i}`) as HTMLElement;
                        if(panel) {
                            panel.style.display = "block";
                        }
                    }

                    if(this.cfg.type == "Tabs") {
                        let prev_tab = document.getElementById(`${this.brick_id}$${this.prev_indentifier|| i}_tab`) as HTMLElement;
                        if(prev_tab) {
                            prev_tab.classList.remove('cc-switchpanel-tab-active')
                        }
                        let panel_tab = document.getElementById(`${this.brick_id}$${cement.identifier || i}_tab`) as HTMLElement;
                        if(panel_tab) {
                            panel_tab.classList.add('cc-switchpanel-tab-active');
                        }
                    }
                    else if(this.cfg.type == "Wizard") {
                        let header = document.getElementById(`${this.brick_id}$_wizard_header`);
                        if(header) {
                            header.innerHTML = cement.title || '';
                        }
                        let back = document.getElementById(`${this.brick_id}$_back`);
                        if(back) {
                            if(i == 0) {
                                back.style.display = 'none';
                            }
                            else {
                                back.style.display = 'inline';
                            }
                        }
                    }
                    this.prev_indentifier = cement.identifier || i;
                    this.current_idx = 1;
                    if(!no_emit) {
                        this.snapshot = this.snapshot || {};
                        this.snapshot.switch = this.prev_indentifier;

                        this.cb_emit({ '@' : this.prev_indentifier });
                    }
                    return;
                }
                i++;
            }
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        if(input == "switch") {
            let new_id = cfg;
            if(cfg == null || JSON.stringify(cfg) == "{}") {
                return;
            }

            //console.log("cc-switchpanel "+this.blueprint.name+" switch "+cfg);

            if(new_id == 'next') {
                new_id = this.current_idx + 1;
            }
            else if(new_id == 'prev') {
                new_id = this.current_idx - 1;
            }
            
            this.show_panel(new_id, true);
            
        }
        else if(input == "cfg") {
            let self = this;
            if(!cfg.type) {
                cfg.type = "Tabs";
            }
            self.cfg = cfg;

            if(cfg.type == "Radio") {
                if(this.blueprint.contains) {
                    let i = 0;
                    for(let sub of this.blueprint.contains) {
                        let cement = this.cements[i] || sub.cement || {};
                        let radio = document.getElementById(`${this.brick_id}$${i}_radio`) as HTMLInputElement;
                        if(radio) {
                            radio.disabled = cfg.disabled || cement.disabled;
                        }
                        i++;
                    }
                }
            }
            else if(cfg.type == "Tabs") {
                if(this.blueprint.contains) {
                    let i = 0;
                    for(let sub of this.blueprint.contains) {
                        let cement = this.cements[i] || sub.cement || {};
                        let tabname = this.brick_id + "$" + cement.identifier + "_tab";
                        if(self.prev_indentifier === "") {
                            self.prev_indentifier = cement.identifier;
                        }
                        let tab = document.getElementById(tabname) as HTMLInputElement;
                        if(tab) {
                            //console.log(this.brick_id+" "+tabname+" "+Boolean(cfg.disabled || cement.disabled));
                            tab.classList.toggle("cc-switchpanel-tab-disabled", Boolean(cfg.disabled || cement.disabled));
                        }
                        i++;
                    }
                }
            }

            let container = document.getElementById(this.brick_id);
            if(container) {
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
            }
            
        }
        return { '@': this.prev_indentifier };
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
        this.cements = cements;

        let container = document.getElementById(this.brick_id);

        let cfg = this.blueprint.ins.cfg; //read static fields

        if(container) {

            let type = cfg.type || "Tabs";
            
            let innerHTML = ``;

            if(type == "Tabs") {
                innerHTML += `<div class="cc-switchpanel-tabheader">`;
                let i = 0;
                if(this.blueprint.contains) {
                    for(let sub of this.blueprint.contains) {
                        let active = "";
                        let cement = this.cements[i] || sub.cement || {};
                        if((!this.prev_indentifier && i == 0) || (cement.identifier !== undefined && cement.identifier == this.prev_indentifier)) {
                            active = "cc-switchpanel-tab-active";

                        }
                        innerHTML += `<button class="cc-switchpanel-tab ${active}" id="${this.brick_id}$${cement.identifier || i}_tab">${cement.title || ''}</button>`;
                        i++;
                    }          
                }
                innerHTML += `</div>`;
            }
            else if(type == "Wizard" || type == "Carousel" || type == "Switched") {
                let active_cement = {};
                if(this.blueprint.contains) {
                    for(let sub of this.blueprint.contains) {
                        let cement = sub.cement || {};
                        if(!active_cement) {
                            active_cement = cement;
                        }
                        if(!this.blueprint.ins.switch) {
                            active_cement = cement;
                            break;
                        }
                        if(cement.indentifier == this.prev_indentifier) {
                            active_cement = cement;
                        }
                    }
                }

                if(type == "Wizard") {
                    innerHTML += `<div><span class='cc-switchpanel-wizard-back' id='${this.brick_id}$_back' style='display:none'><</span><span class='cc-switchpanel-wizard-header' id='${this.brick_id}$_wizard_header'>${active_cement.title || ''}</span></div>`;
                }
            }

            let active_identifier;
            if(this.blueprint.contains) {
                let i = 0;
                for(let sub of this.blueprint.contains) {
                    let cement = this.cements[i] || sub.cement || '';

                    if(type == "Radio") {

                        let checked = ((!this.blueprint.ins.switch && i == 0) || (cement.identifier !== undefined && cement.identifier == this.prev_indentifier));
                        let disabled = cement.disabled ? " disabled" : "";

                        innerHTML += `<label class="cc-switchpanel-radio-container">
                                <input type="radio" value="${cement.identifier || i}" name="${this.brick_id}" id="${this.brick_id}$${i}_radio" ${checked ? "checked" : ""}${disabled}>
                                <span class="cc-switchpanel-radio-checkmark"></span>
                                <span class="cc-switchpanel-label">${cement.title || ''}</span>
                            </label>`;
                    }

                    let brick = CBWebUtil.BrickHtml(sub, this, i);

                    //let lop_style = "";
                    let lop_classes = "";              

                    if(type == "Tabs") {
                        lop_classes += " cc-switchpanel-tab-content";
                    }
                    if(type == "Radio") {
                        lop_classes += " cc-switchpanel-radio-content";
                    }
                    let display = type == "Radio" ? "" : "display:none;";
                    if((i == 0) || (cement.identifier !== undefined && cement.identifier == this.prev_indentifier)) {
                        display = "";
                        active_identifier = cement.identifier || 0;
                    }
                    //innerHTML += `<div style="${lop_style} ${display}" class="cc-switchpanel-content ${lop_classes}" id="${this.brick_id}$${cement.identifier || i}">${brick}</div>`;
                    innerHTML += `<div ${CBWebUtil.GetElementStylesString("cc-switchpanel-content "+lop_classes, cement, "", display)} id="${this.brick_id}$${cement.identifier || i}">${brick}</div>`;

                    i++;
                }
            }
            
            if(container) {
                container.innerHTML = innerHTML;
            }

            if(type == "Tabs") {
                if(this.blueprint.contains) {
                    let i = 0;
                    let self = this;
                    for(let sub of this.blueprint.contains) {
                        let cement = this.cements[i] || sub.cement || {};
                        
                        let tab = document.getElementById(`${this.brick_id}$${cement.identifier || i}_tab`);
                        if(tab) {
                            let tabidx = i;
                            tab.addEventListener('click', function() {

                                if(self.cfg.disabled) {
                                    return;
                                }

                                let panel_tab = document.getElementById(`${self.brick_id}$${cement.identifier || tabidx}_tab`) as HTMLElement;
                                if(panel_tab) {
                                    if(panel_tab.classList.contains("cc-switchpanel-tab-disabled")) {
                                        return;
                                    }
                                    panel_tab.classList.add('cc-switchpanel-tab-active');
                                }

                                let prev_tab = document.getElementById(`${self.brick_id}$${self.prev_indentifier}_tab`) as HTMLElement;
                                if(prev_tab == panel_tab) {
                                    return;
                                }
                                if(prev_tab) {
                                    prev_tab.classList.remove('cc-switchpanel-tab-active')
                                }

                                let prev = document.getElementById(`${self.brick_id}$${self.prev_indentifier}`) as HTMLElement;
                                if(prev) {
                                    prev.style.display = "none";
                                }
                                

                                let panel = document.getElementById(`${self.brick_id}$${cement.identifier || tabidx}`) as HTMLElement;
                                if(panel) {
                                    panel.style.display = "block";
                                }
                                
                                self.prev_indentifier = cement.identifier || tabidx;

                                self.snapshot = self.snapshot || {};
                                self.snapshot.switch = self.prev_indentifier;

                                self.cb_emit({ '@': self.prev_indentifier, '@user_changed' : self.prev_indentifier });
                            });
                        }
                        i++;
                    }
                }
            }

            if(type == "Wizard") {
                let back = document.getElementById(`${this.brick_id}$_back`);
                let self = this;
                if(back) {
                    back.addEventListener('click', function() { 
                        if(self.current_idx > 0) {
                            self.show_panel(self.current_idx - 1);
                        }
                    });
                }
            }

            if(type == "Radio") {
                if(this.blueprint.contains) {
                    let i = 0;
                    let self = this;
                    for(let sub of this.blueprint.contains) {
                        let radio = document.getElementById(`${this.brick_id}$${i}_radio`);
                        if(radio) {
                            radio.addEventListener('click', function() {
                                                            //this is the radio    
                                self.prev_indentifier = (<any>this).value;                                                                                     
                                self.cb_emit({ '@': (<any>this).value, '@user_changed' : (<any>this).value });
                            });
                        }
                        i++;
                    }
                }
            }

            this.prev_indentifier = active_identifier || "0";
        }
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
        this.cements[child_idx] = cement;

        let tab = document.getElementById(`${this.brick_id}$${cement.identifier || child_idx}_tab`);
        if(tab) {
            tab.innerHTML = cement.title || '';
            tab.classList.toggle("cc-switchpanel-tab-disabled", Boolean(this.cfg.disabled || cement.disabled));

            CBWebUtil.ApplyElementStyles(tab, cement, "");
        }
        
        let radio = document.getElementById(`${this.brick_id}$${child_idx}_radio`) as HTMLInputElement;
        if(radio && radio.parentElement) {
            radio.parentElement.children[2].innerHTML = cement.title || '';
        }
        if(radio) {
            radio.disabled = this.cfg.disabled || cement.disabled;

            CBWebUtil.ApplyElementStyles(tab, cement, "");
        }
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {
        return this.snapshot;
    }
}


