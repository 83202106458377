import { CBWebUtil } from "./cb_web_util";

export class cb_popout {
    id: string;
    settings: any;
    open = false;
    initialized = false;

    mouse_is_over = false;

    open_offset = null as any | null;

    keyboard_selected = 0;

    open_callback: () => void;
    close_callback: () => void;
    match_width = false;
    typename = "";

    disabled = false;

    constructor(id:string, no_click_close: boolean, settings: any, typename: string, match_width: boolean, only_the_drop: boolean, open_callback: () => void, close_callback: () => void) {
        this.id = id;
        let container = only_the_drop ? true : document.getElementById(id);

        this.match_width = match_width
        this.open_callback = open_callback;
        this.close_callback = close_callback;
        this.typename = typename;

        if(container) {

            let label = '';
            let classes = '';
            let styles = "";
            if(settings) {
                let cfg = settings;
    
                label = cfg.label;
                if(label && label[0] == "{") {
                    label = "";
                }
                        
            }

            //container.classList.add(`c-select-container`);
            if(!only_the_drop) {
                let html = "";
                html = `<label for="${this.id}$select" class="form-element-label c-select-label ${typename}-label ${(label || "") == "" ? "hidden" : ""}" id="${this.id+"$label"}">${label || ''}</label>`;
                html +=  `<div id="${this.id}$select" class="form-element c-select ${typename}-select ${classes}${no_click_close ? " no_click_close" : ""}" style="${styles}" tabindex="0"></div>`;

                container.innerHTML = html;

                CBWebUtil.ApplyElementStyles(container, settings, "container");
            }

            let dropdown_html =
                `<div id="${this.id}$drop" ${CBWebUtil.GetElementStylesString(`popover ${typename}-drop`, settings, "drop")}>
                    <div id="${this.id}$items" tabindex="0">
                    </div>
                </div>`;
            //We attach the drop to the window so we can position relative to the window, because we find the select's coordinates relative to the window
            window.document.body.insertAdjacentHTML('beforeend', dropdown_html);

        }     
    }

    init(no_keyboard = false) {

        // let drop = document.getElementById(this.id+"$drop") as HTMLElement;
        // if(drop) {
        //     drop.innerHTML = content;
        // }

        let self = this;
        let select = document.getElementById(this.id+"$select") as HTMLElement;
        if(select) {
            select.tabIndex = 0;

            if(!this.initialized) {
                select.addEventListener("click", function(e: any) {
                    if(self.isOpen()) {
                        self.closeSelect();
                    }
                    else {
                        if(!self.disabled) {
                            self.openPopout(self);
                        }
                    }
                });

                // if(!no_keyboard) {
                //     select.addEventListener("keydown", function(event: any) {
                //         if(event.key == "Enter" || event.key == "ArrowDown") {
                //             let select = document.getElementById(self.id+"$select") as HTMLElement;
                //             if(select) {
                //                 select.click();
                //             }
                //         }
                //     });
                // }
            }

        }

        this.initialized = true;
    }

    setLabel(value: string) {
        let label = document.getElementById(this.id+"$label") as HTMLElement;
        if(label) {
            if(value !== undefined && value !== "") {
                label.classList.remove("hidden");
                label.innerHTML = value;
            }
            else {
                label.classList.add("hidden");
            }
        }
    }

    setDisplayValue(value: string) {
        let select = document.getElementById(this.id+"$select") as HTMLElement;
        if(select) {
            select.innerHTML = value;
        }
    }

    setDropHtml(html: string) {
        let select = document.getElementById(this.id+"$drop") as HTMLElement;
        if(select) {
            select.innerHTML = html;
        }
    }

    setNoWindowClickClose(no_close: boolean) {
        let select = document.getElementById(this.id+"$select") as HTMLElement;
        if(select) {
            if(no_close) {
                select.setAttribute("no_window_click_close", "true");
            }
            else {
                select.removeAttribute("no_window_click_close");
            }
        }
    }
                    
    static getRect(element:any) {
        let rect = element.getBoundingClientRect();
        let x = rect.x;
        let y = rect.y;

        y += window.scrollY;
        x += window.scrollX;

        return { x : x, y : y, w: rect.width, h : rect.height};
    }

    openPopout(cb_popout_object:cb_popout) {
        let id = cb_popout_object.id;

        //initialise global object
        (<any>window).cb_popout_global = (<any>window).cb_popout_global as any || {
            cb_popouts_by_id : {} as { [id:string] : cb_popout },
            scroll: [] as {x: number, y: number}[],
            pop_idx: 0,
            open_id_stack: [],

            window_click_handler: function(e: any) {

                //Close when clicking anywhere
                let cb_popout_global = (<any>window).cb_popout_global;
                let max_iterations = 10;
                while(cb_popout_global.open_id !== undefined) {
                    max_iterations--;
                    if(max_iterations <= 0) {
                        break;
                    }
                    let select = document.getElementById(cb_popout_global.open_id+"$select");
                    if(select && select.contains(e.target)) {
                        return; //except select element
                    }
                    if(select && select.getAttribute("no_window_click_close")) {
                        return;
                    }
                    if(!cb_popout_global.open_drop_guard) {
                        let open_drop = document.getElementById(cb_popout_global.open_id+"$drop");
                        if(open_drop && select) {
                           
                            if(select.classList.contains("no_click_close")) {
                                if(open_drop.contains(e.target)) {
                                    return; //don't close on selection if multiselect
                                }
                            }

                            cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id].call_closed_callback();
                            cb_popout_global.open_id_stack.pop();
                            cb_popout_global.open_id = cb_popout_global.open_id_stack[cb_popout_global.open_id_stack.length-1];
                            open_drop.classList.remove("popover_show");//.style.display = 'none';
                            open_drop.style.removeProperty("transition");

                        }
                    }
                }
            } as any,

            mouse_wheel_handler: function(evt:any) {
                //Close the drop on scroll, otherwise the element will scroll away from the drop. 
                //We can't make the container relative because if the parent is overflow: scroll the drop will not be able to hover over the end, 
                // but will be hidden behind the scoll
                //Note: THis hides the drop on scroll. select2's solution is to block scroll when select open.
                if(cb_popout_global.open_id !== undefined) {
               
                    //But don't close if scolling on the drop element
                    let elements_under_mouse = document.querySelectorAll(':hover');
                    //@ts-expect-error
                    for(let elem of elements_under_mouse) {
                        if(elem.classList.contains("select-items")) {

                            let cs = getComputedStyle(elem);
                            if(cs.height < cs.maxHeight) {
                                evt.preventDefault();
                            }                          
                            return;
                        }
                    }

                    let open_drop = document.getElementById(cb_popout_global.open_id+"$drop");
                    if(open_drop) {
                        if(cb_popout_global.open_id !== undefined) {
                            cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id].call_closed_callback();
                        }
                        cb_popout_global.open_id_stack.pop();
                        cb_popout_global.open_id = cb_popout_global.open_id_stack[cb_popout_global.open_id_stack.length-1];
                        open_drop.classList.remove("popover_show");//.style.display = 'none';   
                        open_drop.style.removeProperty("transition");
                   
                    }
                    
                }
            },

            check_drop_moved: function(popidx: number) {
                let cb_popout_global = (<any>window).cb_popout_global;
                if(popidx != cb_popout_global.pop_idx) {
                    return;
                }
                if(cb_popout_global.open_id !== undefined) {
                    let cb_popout_object = cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id];
                    let select = document.getElementById(cb_popout_global.open_id+"$select");

                    //Only block scrolling if scolling on the drop element
                    let on_drop = false;
                    let elements_under_mouse = document.querySelectorAll(':hover');
                    for(let e of elements_under_mouse) {
                        if(e.classList.contains("c-select-dropdown")) {
                            on_drop = true;
                            break;
                        }
                    }
            
                    if(select && cb_popout_object.open_offset != null) {
                        var offset = cb_popout.getRect(select);
            
                        if(on_drop || (cb_popout_object.open_offset.x == offset.x && 
                            cb_popout_object.open_offset.y == offset.y
                        )) {

                            setTimeout(cb_popout_global.check_drop_moved.bind(null, popidx), 101);
                        }
                        else {
                            if(cb_popout_global.open_id !== undefined) {
                                cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id].call_closed_callback();
                            }                           
                            let drop = document.getElementById(cb_popout_global.open_id+"$drop");
                            cb_popout_global.open_id_stack.pop();
                            cb_popout_global.open_id = cb_popout_global.open_id_stack[cb_popout_global.open_id_stack.length-1];
                            if(drop) {
                                drop.classList.remove("popover_show");//.style.display = 'none';    
                                drop.style.removeProperty("transition");

                            }
                        }
                        
                    }
                }
            }
        };

        let cb_popout_global = (<any>window).cb_popout_global;
        cb_popout_global.cb_popouts_by_id[cb_popout_object.id] = cb_popout_object;

        if(!cb_popout_global.initialized) {
            //set global listeners
            window.addEventListener('click', cb_popout_global.window_click_handler);
            cb_popout_global.initialized = true;

            let body = document.querySelector("body");
            
            if(body) {
                body.addEventListener("mousewheel", cb_popout_global.mouse_wheel_handler, { passive: false });
                body.addEventListener("touchmove", cb_popout_global.mouse_wheel_handler, { passive: false });
            }
        }

        //If another one is open, close it
        if(cb_popout_global.open_id !== undefined && cb_popout_global.open_id != id) {
            let open_drop = document.getElementById(cb_popout_global.open_id+"$drop");
            if(open_drop) {   
                
                //if this launcher is inside the open_drop, don't close the open_drop
                let inside = false;
                let launcher = document.getElementById(this.id+"$select");
                if(launcher) {
                    let max_iterations = 100;
                    while(launcher.parentElement) {
                        max_iterations--;
                        if(max_iterations <= 0) {
                            break;
                        }
                        if(launcher.parentElement.id == cb_popout_global.open_id+"$drop") {
                            inside = true;
                            break;
                        }
                        launcher = launcher.parentElement;
                    }
                }
                if(!inside) {
                    open_drop.classList.remove("popover_show"); //.style.display = 'none';
                    open_drop.style.removeProperty("transition");
                }
            }
        }

        //Open newly clicked drop
        cb_popout_global.open_id_stack.push(id);
        cb_popout_global.open_id = id;

        this.position_popout(true);

        cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id].call_opened_callback();
    }

    position_popout(newpop = false) {
        let cb_popout_global = (<any>window).cb_popout_global;
        let cb_popout_object = cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id];
        let new_drop = document.getElementById(cb_popout_global.open_id+"$drop");
        if(new_drop) {
            cb_popout_global.open_drop_guard = true;
            setTimeout(() => (
                cb_popout_global.open_drop_guard = false
            ), 100); //setTimeout so the window handler below does not close it immediately. You cant use stopproagation because it will prevent another open select from closing if you open this while that is open.

            let select = document.getElementById(cb_popout_global.open_id+"$select");
            if(select) {

                //move the absolute positioned drop to the possibly moved (scrolled) element.
                let height = select.offsetHeight;
                if(this.match_width) {
                    let width = select.offsetWidth;
                    new_drop.style.minWidth = width + "px";  
                }
                
                var offset = cb_popout.getRect(select);
                cb_popout_object.open_offset = { x: offset.x, y : offset.y };

                let use_top_offset = offset.y + height

                //prevent the drop from going out the screen horizontally
                let use_offset_x = offset.x;

                let drop_computed_style = getComputedStyle(new_drop);

                let dropWidth = Number(drop_computed_style.width.replace("px",""));// + Number(drop_computed_style.paddingRight.replace("px",""));// +10; //new_drop.offsetWidth;
                let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
                if(offset.x + dropWidth > screenWidth) {
                    //use_offset_x = offset.x - ((offset.x + dropWidth) - screenWidth);
                    //if it goes off the screen we align the right of the drop with the right of the launcher

                    use_offset_x = offset.x - dropWidth + offset.w;
                }

                new_drop.style.top = (use_top_offset) + "px";
                new_drop.style.left = (use_offset_x) + "px";
                             
                
                let the_drop = new_drop;
                if(newpop) {
                    setTimeout(function() { //This setTimeout prevents it from appearing to come down from the top of the page on first open
                        //new_drop.style.display = 'block';
                        the_drop.classList.add("popover_show");
                        the_drop.style.transition = getComputedStyle(the_drop).transition; //Make the transition apply on close. We use the value from popover_show. If we set this before the first open, the drop appears to come down from the top of the page on first open

                        the_drop.scrollTo(0,0);
                        // let searchbox = document.getElementById(cb_popout_global.open_id+"$search") as HTMLInputElement;
                        // if(searchbox) {
                        //     if(searchbox.classList.contains("hidden")) {
                        //     }
                        //     else {
                        //         searchbox.value = "";
                        //         searchbox.dispatchEvent(new Event('input', {bubbles:true}));
                        //         searchbox.focus();
                        //     }
                        // }

                        //check if the drop would go out the bottom of the screen, if so pop to the top
                        let dropHeight = the_drop.offsetHeight;
                        let screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

                        let exceed_screen_bottom = (use_top_offset + dropHeight + height) > screenHeight + window.scrollY;
                        let exceed_screen_top = (screenHeight - use_top_offset - dropHeight - height) < 0;
                        let below_mid_Screen = use_top_offset > (screenHeight/2);

                        let use_bottom_offset = 0;
                        if((exceed_screen_bottom && !exceed_screen_top) || (exceed_screen_bottom && exceed_screen_top && below_mid_Screen)) {
                            //use_top_offset-= dropHeight + height;
                            use_bottom_offset = screenHeight - (use_top_offset - height);

                            the_drop.style.bottom = use_bottom_offset + "px";
                            the_drop.style.removeProperty("top");
                        }
                        else {
                            the_drop.style.removeProperty("bottom");
                            the_drop.style.top = (use_top_offset) + "px";   
                        }

                        
                        cb_popout_global.pop_idx++; 
                        setTimeout(cb_popout_global.check_drop_moved.bind(null, cb_popout_global.pop_idx), 100);    
                        
                    },1);
                }
            }
        }
    }

    call_opened_callback() {
        this.open_callback();
    }

    call_closed_callback() {
        this.close_callback();
    }

    closeSelect() {
        let cb_popout_global = (<any>window).cb_popout_global;
        if(cb_popout_global) {
            let open_drop = document.getElementById(cb_popout_global.open_id+"$drop");
            if(open_drop) {

                cb_popout_global.cb_popouts_by_id[cb_popout_global.open_id].call_closed_callback();

                cb_popout_global.open_id_stack.pop();
                cb_popout_global.open_id = cb_popout_global.open_id_stack[cb_popout_global.open_id_stack.length-1];

                //open_drop.style.display = 'none';
                open_drop.classList.remove("popover_show");
                open_drop.style.removeProperty("transition");

                let select = document.getElementById(this.id+"$select") as HTMLElement;
                if(select) {
                    select.focus();
                }
            }
        }
    }

    isOpen() {
        let cb_popout_global = (<any>window).cb_popout_global;
        if(!cb_popout_global) {
            return false;
        }

        return cb_popout_global.open_id === this.id;
    }



}