import Sugar from "sugar";
import { drill } from "../codebricks-runtime/CBUtil";

export class Tokenfield {

    static getTokens(value: any, separators: string[], path: string) {
        if(typeof value == "string") {
            if(value == "") {
                return [];
            }
            let splitter = new RegExp(
                
                "[" + Sugar
                .RegExp.escape(separators.join(""))
                +"]", 
                'g');
            let tokens = value.split(splitter);
            return tokens;
        }
        else if(Array.isArray(value)) {
            if(value.length > 0) {
                if(typeof value[0] == "object" && path) {
                    let ret = [] as string[];
                    for(let v of value) {
                        ret.push(drill(path, v));
                    }
                    return ret;
                }
            }
            return value;
        }
        else {
            return ["" + value];
        }
    }

}