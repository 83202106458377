import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { SUse } from "../../shared-funcs/SUse";


export class c_editor_richtext_webcomponent extends HTMLElement {
    ci: web_c_editor_richtext | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_editor_richtext(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-editor-richtext', c_editor_richtext_webcomponent);

export class web_c_editor_richtext extends CodeBrick {
    
    element: HTMLElement;
    //shadowroot: ShadowRoot;
    editor: any | undefined;

    required = false;
    value = "";
    initialized = false;

    cfg: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        let html = `<label class="c-editor-richtext-label hidden" id="${this.brick_id}$label" for="${this.brick_id}"></label>
        <textarea name="${this.brick_id}" id="${this.brick_id}"></textarea><div class="c-input-validation-message" id="${this.brick_id}&validation" style="display:none"></div>`;

        element.innerHTML = html;

        this.blueprint.ins.cfg = this.blueprint.ins.cfg || {};

        //@ts-expect-error
        this.editor = CKEDITOR.replace(this.brick_id, {
            width: "100%",
            removeButtons: this.blueprint.ins.cfg.remove_buttons || "",
            extraPlugins: 'uploadimage,image2',
            uploadUrl: '/cb_api/cb/sc-use',
            versionCheck: false
        });

        let self = this;

        //@ts-expect-error
        this.editor.on( 'change', function( evt ) {
            let text = evt.editor.getData();
            text = text.replace(/{/g, '&#123;').replace(/}/g, '&#125;'); //Don't treat {{ in this contents as tags
            self.value = text;
            self.cb_emit({ '@' : text });
        });
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        if(input == 'value') {

            //console.log("set editor value: "+cfg); 
            let self = this;
            this.editor.setData(cfg);
            // , {
            //     callback: function() {
            //         self.cb_emit({ '@' : self.editor.getData() });
            //     }
            // });
            self.value = cfg;

            let contents_block_id = "cke_" + this.brick_id; 
            let contents_block = document.getElementById(contents_block_id);
            if(contents_block) {
                contents_block.classList.remove("c-input-validation-failed");
            }
            let validation_message_container = document.getElementById(`${this.brick_id}&validation`);
            if(validation_message_container) {
                validation_message_container.style.display = "none";
                validation_message_container.innerHTML = "";
            }

            return { '@': cfg }; //This does not have the dont_escape_html option, so we emit rather
        }
        else if(input == "cfg") {
            this.required = cfg.required == true;
            if(!this.initialized) {
                if(cfg.upload_target) {

                    let self = this;
                    self.cfg = cfg;

                    //this.editor.config.extraPlugins = 'uploadimage'; done in init

                    this.editor.config.uploadUrl = '/cb_api/cb/sc-use';

                    this.editor.on( 'fileUploadRequest', function( evt:any ) {

                        //console.log("fileUploadRequest");

                        let formData = new FormData();           
                        formData.append("composition", self.context.compositions[self.cid].composition);
                        formData.append("unit_path", self.blueprint.name);
                        if(self.context.composition_runners[self.cid].debugging_enabled) {
                            formData.append("debugging_enabled", "" + self.context.composition_runners[self.cid].debugging_enabled); 
                        }
                        if(info) {
                            formData.append("source", info.source);
                            formData.append("source_output", info.source_output);
                        }
                        formData.append("unwrap_output", "true");

                        
                        let params = SUse.GetPValuePairs(self, "cfg");

                        //Only post the p values
                        if(params) {
                            for(let k in params) {
                                formData.append(k, params[k]);
                            }
                        }

                        var fileLoader = evt.data.fileLoader,
                            xhr = fileLoader.xhr;
                    
                        xhr.open( 'POST', fileLoader.uploadUrl, true );
                        formData.append( 'file', fileLoader.file, fileLoader.fileName );
                        fileLoader.xhr.send( formData );
                    
                        // Prevented the default behavior.
                        evt.stop();
                    }, null, null, 4 );

                    this.editor.on( 'fileUploadResponse', function( evt:any ) {
                        // Get XHR and response.
                        var data = evt.data,
                            xhr = data.fileLoader.xhr,
                            response = xhr.responseText;

                        //console.log("@upload_response "+response);

                        try {
                            response = JSON.parse(response);
                        }
                        catch(err) {
                        }
               
                        self.cb_emit({ "@upload_response" : response });
                    } );

                    // this.editor.on( 'fileUploadRequest', async function( evt:any ) {
                    //     console.log(" fileupload");
                    //     evt.data.requestData["composition"] = self.context.compositions[self.cid].composition;
                    //     evt.data.requestData["unit_path"] = self.blueprint.name;
                    //     if(self.cfg.upload_form_values) {
                    //         for(let k in self.cfg.upload_form_values) {
                    //             evt.data.requestData[k] = self.cfg.upload_form_values[k];
                    //         }
                    //     }
                        
                    //     // let formData = new FormData();   

                    //     // formData.append("file", this.fileName);
                    //     // formData.append("composition", self.context.compositions[self.cid].composition);
                    //     // formData.append("unit_path", self.blueprint.name);
                    //     // if(self.cfg.form_values) {
                    //     //     for(let k in cfg.form_values) {
                    //     //         formData.append(k, self.cfg.form_values[k]);
                    //     //     }
                    //     // }
                    //     // let res = await fetch('/cb_api/cb/sc-use', {
                    //     //     method: "POST", 
                    //     //     body: formData
                    //     // });
                    //     // let body = await res.text();

                    //     // let res_data;
                    //     // try {
                    //     //     res_data = JSON.parse(body);
                    //     // }
                    //     // catch(err) {
                    //     //     res_data = body;
                    //     // }
                    //     // console.log("res_data "+JSON.stringify(res_data));

                    //     //self.cb_emit(res_data);

                    //     // Prevent the default behavior.
                    //     //evt.stop();
                    // });
                }

                this.initialized = true;
            }

            if(cfg.label === null || cfg.label === undefined) {
                cfg.label = "";
            }

            let label_element = document.getElementById(`${this.brick_id}$label`) as HTMLLabelElement;
            label_element.innerHTML = cfg.label;
            if(cfg.label === "") {
                label_element.classList.add("hidden");
            }
            else {
                label_element.classList.remove("hidden");
            }
        }
    }

    cb_validate(): boolean {
        let validates = true;
        let validation_message = "";
        if(this.required) {

            if((this.value || "").toLowerCase().indexOf("<img ") != -1) {
                //If it has just an image it's also not empty
            }
            else {

                let text = Sugar.String.stripTags(this.value).trim();

                if(text == "") {
                    validates = false;
                    validation_message = "Please enter a value";
                }
            }
        }

        let validation_message_container = document.getElementById(`${this.brick_id}&validation`);

        let contents_block_id = "cke_" + this.brick_id; //this.editor.id + "_contents";
        let contents_block = document.getElementById(contents_block_id);

        if(contents_block) {

            if(validates) {
                contents_block.classList.remove("c-input-validation-failed");

                if(validation_message_container) {
                    validation_message_container.style.display = "none";
                    validation_message_container.innerHTML = "";
                }
            }
            else {
                contents_block.classList.add("c-input-validation-failed");

                if(validation_message_container) {
                    validation_message_container.innerHTML = validation_message;
                    validation_message_container.style.display = "block";
                }
            }
        }

        return validates;
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {

    }

    cb_snapshot() {}
}