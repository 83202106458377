import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_switch_webcomponent extends HTMLElement {
    ci: web_c_switch | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_switch(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-switch', c_switch_webcomponent);

export class web_c_switch extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    value = "";
    options = [];

    initial_sent = false;
    selected_idx = undefined as any;

    snapshot = undefined as any;
    
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        let label = '';
        if(this.blueprint.ins.cfg) {
            label  = this.blueprint.ins.cfg.label || '';
        }
        let html = `<div class='form-element-container c-switch-container' id="${this.brick_id}$container"><label for="${this.brick_id}" class="form-element-label c-switch-label" id="${this.brick_id+"$label"}">${label}</label><div class="btn-group" id="${this.brick_id}"></div></div>`;

        this.element.innerHTML = html;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksSwitch "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));

        if(input == 'cfg') {

            this.options = cfg.options;
            let html = '';
            let btnid = 0;
            let selected_id = (cfg.value === undefined || cfg.value == '') ? null : cfg.value;
            
            let set_selected_class = "";

            let container = document.getElementById(this.brick_id+"$container");

            let switches = document.getElementById(this.brick_id);
            if(switches) {
                CBWebUtil.ApplyElementStyles(switches, cfg, "switchgroup");
            }

            if(this.options && this.options.length > 0) {
                let keys = Object.keys(this.options[0]);
                for(let i in this.options) {
                    if(typeof this.options[i] == "string") {
                        set_selected_class = 'c-switch-button-inactive'; 
                        if(selected_id === null) {                       
                            if(cfg.display && cfg.display.toLowerCase().indexOf("radio") != -1) {
                                selected_id = this.options[i];
                            }
                            else {
                                selected_id = this.options[i];
                                this.selected_idx = 0;
                            }
                        }
                        let checked = "";
                        if(selected_id === this.options[i]) {
                            set_selected_class = "btn-primary c-switch-btn-active"; 
                            this.selected_idx = btnid;
                            checked = "checked";
                        }
                        else {
                            set_selected_class = "btn-secondary c-switch-btn-inactive"; 
                        }
                        if(cfg.display && cfg.display.toLowerCase().indexOf("radio") != -1) {
                            html += `<div><input type="radio" id="${this.brick_id+"$"+btnid}" name="${this.brick_id}" value="${this.options[i]}" ${checked}><label for="${this.brick_id+"$"+btnid}">${this.options[i]}</label></div>`;
                            btnid++;
                        }
                        else {
                            html += `<button id="${this.brick_id+"$"+btnid++}" class="btn btn-group-btn c-switch-button ${set_selected_class}" value="${this.options[i]}" ${this.selected_idx === btnid ? ' checked="checked"':""}>${this.options[i]}</button>`; 
                        }

                    }
                    else if(typeof this.options[i] == "object") {
                        let id = '';
                        if(cfg.value_column) {
                            id = this.options[i][cfg.value_column];
                        }
                        else {
                            id = this.options[i][keys[0]];
                        }

                        let label = '';
                        if(cfg.label_column) {
                            label = this.options[i][cfg.label_column] || '';
                        }
                        else {
                            let lkid = 0;
                            if(keys.length > 1) {
                                lkid = 1;
                            }
                            label = this.options[i][keys[lkid]] || '';
                        }

                        if(selected_id === null) {                    
                            if(cfg.display && cfg.display.toLowerCase().indexOf("radio") != -1) {
                                selected_id = id;
                            }
                            else {
                                selected_id = id;
                                this.selected_idx = 0;
                            }
                        }

                        let checked = "";
                        set_selected_class = ''; 
                        if(selected_id === id) {
                            set_selected_class = "btn-primary c-switch-btn-active"; 
                            this.selected_idx = btnid;
                            checked = "checked";
                        }
                        else {
                            set_selected_class = "btn-secondary c-switch-btn-inactive"; 
                        }

                        if(cfg.display && cfg.display.toLowerCase().indexOf("radio") != -1) {
                            html += `<div><input type="radio" id="${this.brick_id+"$"+btnid}" name="fav_language" value="${id}" ${checked}><label for="${this.brick_id+"$"+btnid}">${label || ''}</label></div>`;
                            btnid++;
                        }
                        else {
                            html += `<button id="${this.brick_id+"$"+btnid++}" class="btn c-switch-button ${set_selected_class} " value="${id}">${label || ''}</button>`;
                        }

                    }
                }
            }

            let switch_container = document.getElementById(this.brick_id) as HTMLElement;
            if(switch_container) {
                if(cfg.display && cfg.display.toLowerCase().indexOf("radio") != -1) {
                    switch_container.classList.add("c-switch-radios");
                }
                else {
                    switch_container.classList.add("c-switch-button-group");
                }
                switch_container.innerHTML = html;

                CBWebUtil.ApplyElementStyles(switch_container, cfg, "container");
            }

            let label = document.getElementById(`${this.brick_id+"$label"}`);
            if(label) {
                label.innerHTML = cfg.label || '';

                if(!cfg.label) {
                    label.classList.add('hidden');
                }
                else {
                    label.classList.remove('hidden');
                }
            }

            this.set_event_handlers(cfg)

            if(!cfg.user_changes_only) {
                return {"@": selected_id, "@option_object": this.options[this.selected_idx] };
            }
        
        }
    }

    set_event_handlers(cfg: any) {
        let options = cfg.options;
        let self = this;
        if(cfg.extra_option && cfg.extra_option.value !== undefined) {
        }
        let idx = 0;
        if(options && options.length > 0) {
            let keys = Object.keys(this.options[0]);
            for(let i in options) {
                let value: any;

                if(typeof options[i] == "string") {
                    value = options[i];
                }
                else if(typeof options[i] == "object") {
                    if(cfg.value_column) {
                        value = options[i][cfg.value_column];
                    }
                    else {
                        value = options[i][keys[0]];
                    }
                }

                let btn = document.getElementById(this.brick_id+"$"+i);
                if(btn) {
                    let btn_idx = idx;
                    btn.addEventListener('click', function() {

                        if(cfg.display && cfg.display.toLowerCase().indexOf("radio") != -1) {
                        }
                        else {
                            let current_selected  = document.getElementById(self.brick_id+"$"+self.selected_idx);
                            if(current_selected) {
                                    current_selected.classList.remove("btn-primary");
                                current_selected.classList.remove("c-switch-btn-active");
                                current_selected.classList.add("btn-secondary");
                                current_selected.classList.add("c-switch-btn-inactive");
                                
                            }

                            self.selected_idx = btn_idx;

                            let new_selected  = document.getElementById(self.brick_id+"$"+self.selected_idx);
                            if(new_selected) {
                                new_selected.classList.add("btn-primary");
                                new_selected.classList.add("c-switch-btn-active");
                                new_selected.classList.remove("c-switch-btn-inactive");
                                new_selected.classList.remove("btn-secondary");
                            }
                        }   

                        self.snapshot = self.snapshot || {};
                        self.snapshot.cfg = self.snapshot.cfg || {};
                        self.snapshot.cfg.value = value;

                        self.cb_emit({"@": value, "@option_object": self.options[self.selected_idx], "@user_changed": value });
                    });
                    
                }
                idx++;
            }
        }

    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {
        return this.snapshot;
    }
}
