import { DrillSet, ObjectDeepMerge } from "../codebricks-runtime/CBUtil";
import { PivotTable } from "./PivotTable";

export class Data {
    static ProcessDataInput(input: string, cfg: any, self: any) {
        if(input == "data") {
            if(cfg.ignore_unchanged || cfg.ingore_unchanged) {//fixed typo in spec
                if(self.prev_data == JSON.stringify(cfg.value)) {
                    return;
                }
            } 
            self.data = cfg.value;

            //group
            if(cfg.group_by && cfg.group_by.group_cols && cfg.group_by.group_cols.length > 0) {
                self.data = PivotTable.groupData(self.data, cfg.group_by.group_cols, cfg.group_by.count_cols, cfg.group_by.sum_cols);
            }

            //console.log('Grouped data: '+JSON.stringify(data));

            if(cfg.pivot && cfg.pivot.rows && cfg.pivot.rows.length > 0 &&
                cfg.pivot.columns && cfg.pivot.columns.length > 0 &&
                cfg.pivot.data_columns && cfg.pivot.data_columns.length > 0 ) {
    
                self.data = PivotTable.pivotData(self.data, cfg.pivot.rows[0], cfg.pivot.columns[0], cfg.pivot.data_columns, false);
            }

            let dont_emit = false;
            if(cfg.ingore_initial) {
                if(self.prev_data === "") {
                    dont_emit = true;
                }
            }
            self.prev_data = JSON.stringify(self.data);
            if(!dont_emit) {

                if(cfg.no_event) {
                    self.cb_emit({ "@constraint_failed": false, "@" : self.data }, undefined, true);
                }
                else {
                    return { "@constraint_failed": false, "@" : self.data };
                }
            }
        }
        else if(input == "merge") {
            if(cfg.value !== "") {
                ObjectDeepMerge(self.data || {}, cfg.value);

                return {"@constraint_failed": false, "@" : self.data  };
            }
        }
        else if(input == "set_property") {
            if(self.data && cfg.value !== "" && cfg.path !== undefined) {
                self.data = DrillSet(self.data, cfg.path, cfg.value);
                return { "@constraint_failed": false, "@" : self.data  };
            }
        }
        else if(input == "push") {
            if(cfg.value !== "") {
                self.data = self.data || [];

                if(Array.isArray(self.data)) {
                    
                    if(!this.CheckConstraint(self,  self.data, cfg.value)) {
                        return { "@constraint_failed": true };
                    }
    
                    self.data.push(cfg.value);
                    return { "@constraint_failed": false, "@" : self.data };
                }         
            }
        }
        else if(input == "insert") {
            if(cfg.value !== "" && Array.isArray(self.data)) {

                if(!this.CheckConstraint(self,  self.data, cfg.value)) {
                    return { "@constraint_failed": true };
                }

                self.data.splice(cfg.position, 0, cfg.value);
                return { "@constraint_failed": false, "@" : self.data };
            }
        }
        else if(input == "delete_position") {
            if(cfg.count && Array.isArray(self.data)) {
                self.data.splice(cfg.position, cfg.count);
                return { "@constraint_failed": false, "@" : self.data  };
            }
        }
        else if(input == "delete_where") {
            if(cfg.value !== "" && Array.isArray(self.data)) {
                for(let i = self.data.length; i >= 0; i--) {
                    if(cfg.property) {
                        if(typeof self.data[i] == "object" && self.data[i][cfg.property] == cfg.value) {
                            self.data.splice(i, 1);
                        }
                    }
                    else {
                        if(self.data[i] == cfg.value) {
                            self.data.splice(i, 1);
                        }
                    }
                }
                return { "@constraint_failed": false, "@" : self.data  };
            }
        }
        else if(input == "replace_where") {
            if(cfg.value !== "" && Array.isArray(self.data)) {
                for(let i = self.data.length; i >= 0; i--) {
                    if(cfg.property) {
                        if(typeof self.data[i] == "object" && self.data[i][cfg.property] == cfg.value) {
                            self.data[i] = cfg.new_value
                        }
                    }
                    else {
                        if(self.data[i] == cfg.value) {
                            self.data[i] = cfg.new_value
                        }
                    }
                }
                return { "@constraint_failed": false, "@" : self.data  };
            }
        }
        else if(input == "unique_constraints") {
            self.unique_constraints = cfg;
        }
    }


    static CheckConstraint(self: any, data: any, value: any) {
        if(Array.isArray(data)) {
                    
            if(self.unique_constraints && self.unique_constraints.enable) {
                if(self.unique_constraints.check_properties) {
                    for(let property of self.unique_constraints.check_properties) {
                        if(data.some((e: { [x: string]: any; }) => e[property] == value[property])) {
                            return false;
                        }
                    }
                }
                else {
                    if(self.data.includes(value)) {
                        return false;
                    }
                }
            }

            return true;
        } 
    }
}