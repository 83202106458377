import { Clone, ObjectDeepMerge } from "../../codebricks-runtime/CBUtil";
import { Prettyfy } from "../../shared-funcs/Util";
import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from '../../codebricks-runtime/CodeBrick';
import { codebricks_format } from '../../codebricks-runtime/CBFormatting';
import { Chart,  ChartItem } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CBWebUtil } from "../controls/cb_web_util";
Chart.register(ChartDataLabels);

export class c_chart_webcomponent extends HTMLElement {
  ci: web_c_chart | undefined;
  constructor() {
      super();
  }
  connectedCallback() {
    if(!this.ci) {
        let context = (globalThis as any).codebricks_context;
        let cid = this.getAttribute('cid') as string;
        let name = this.getAttribute('name') as string;
        let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
        this.ci = new web_c_chart(context, cid, name, dc, Number(idx), container_id, this);
    }
  }
  disconnectedCallback() {
    if(this.ci) {
        this.ci.destructor();
    }
}
}
customElements.define('c-chart', c_chart_webcomponent);

export class web_c_chart extends CodeBrick {
    element: HTMLElement;
    chart: any;
    cfg: any;
    initial = true;
    //unformatted_labels = [] as string[];
    unformatted_data: any;

  //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });
        //let html = `<div><div id="${this.brick_id}$title"></div><div class='c-chart' id="${this.brick_id}"></div></div>`;
        
        let html = `<div class="c-chart-container"><div id="${this.brick_id}$title" class="c-chart-title"></div><div class='c-chart' id="${this.brick_id}$chartcontainer"><canvas id="${this.brick_id}"></canvas></div><div id="${this.brick_id}$empty" class="empty-state c-chart-empty hidden"></div></div>`;

        this.element.innerHTML = html;

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("web_c_chart "+this.brick_id+" "+input+" "+JSON.stringify(cfg));

        if(input == 'cfg') {
          
            this.cfg = cfg;

            //// @ts-expect-error
            //google.charts.load('current', { 'packages': ['corechart', 'line', 'table', 'bar', 'treemap'], 'language': 'en-za' });

            let self = this;

        
            let title_container = document.getElementById(self.brick_id+'$title');
            if(title_container) {
                if(cfg.title) {
                    title_container.innerHTML = '<h2>'+cfg.title+'</h2>';
                    CBWebUtil.ApplyElementStyles(title_container.firstElementChild as HTMLElement, cfg, "title");
                }
                else {
                    title_container.innerHTML = '';
                }
                CBWebUtil.ApplyElementStyles(title_container.parentElement as HTMLElement, cfg, "container");
            }
          
            self.cfg.formatted_data = self.cfg.data;
            if(cfg.column_formats && cfg.column_formats.length > 0) {
                let data = Clone(self.cfg.data);
                for(let row of data) {
                    for(let cf of cfg.column_formats) {                   
                        if(cf.format) {                  
                            row[cf.column] = codebricks_format(row[cf.column], cf.format, this.context.composition_runners[this.cid].compos?.system_options || {}).value;
                        }
                        if(cf.hidden) {
                            delete row[cf.column];
                        }
                    }
                }
                self.cfg.formatted_data = data;
            }

            let container = document.getElementById(this.brick_id+'$chartcontainer');    
            if(cfg.height) {            
                if(container) {
                    container.style.height = cfg.height;
                    if(cfg.charttype == "pie" || cfg.charttype == "donut" || cfg.charttype == "doughnut") {
                        container.style.maxWidth = cfg.height;
                    }
                    else if(cfg.charttype == "bar" ) {
                        if(cfg.data) {
                            if(cfg.data.count > 0) {
                                let keys = Object.keys(self.cfg.formatted_data[0]);
                                container.style.height = 40 * (keys.length - 1) * cfg.data.length + "px";
                            }
                        }
                    }
                    else {
                        container.style.removeProperty("max-width");
                    }
                }
            }
            CBWebUtil.ApplyElementStyles(container as HTMLElement, cfg, "chart");

            let type = self.cfg.charttype;

            let canvas = document.getElementById(this.brick_id) as ChartItem;
            
            let data = self.cfg.formatted_data || [];
            this.unformatted_data = cfg.data;
            //self.unformatted_labels = [];
            if(canvas) {
                let chartconfig = {} as any;

                if(type == "bar" || type == "column" || type == "line") {
                    if(type == "column") {
                        type = "bar";
                    }
                
                    if(data && data.length > 0 && this.unformatted_data) {
                        let keys = Object.keys(data[0]);

                        let x_col = 0;

                        if(cfg.x_axis_column) {
                            for(let k = 0; k < keys.length; k++) {
                                if(keys[k] == cfg.x_axis_column) {
                                    x_col = k;
                                    break;
                                }
                            }
                        }

                        let labels = data.map(row => row[keys[x_col]]);
                        //console.log("labels "+JSON.stringify(labels));

                        let datasets = [];
                        for(let i = 0; i < keys.length; i++) {

                            if(i == x_col) {
                                continue;
                            }

                            let dataset = {
                                label: keys[i],
                                data: data.map(row => row[keys[i]])
                            } as any;

                            //console.log("dataset "+JSON.stringify(dataset));
                            if(cfg.color_per_bar) {
                                if(cfg.colors) {
                                    let color_offset = 0; 
                                    dataset.backgroundColor = [];
                                    for(let i = 0; i < data.length; i++) {
                                        if(cfg.colors[i]) {
                                            let clr_i = i + color_offset;
                                            if(clr_i >= cfg.colors.length) {
                                                clr_i = clr_i % cfg.colors.length;
                                            }
                                            dataset.backgroundColor.push(cfg.colors[clr_i]);
                                        }
                                    }
                                }
                            }
                            else {
                                if(cfg.colors && cfg.colors[i - 1]) {
                                    dataset.borderColor = cfg.colors[i - 1],
                                    dataset.backgroundColor = cfg.colors[i - 1]
                                }
                            }

                            datasets.push(dataset);
                        }

                        let options = {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                datalabels: {
                                    display: function(context:any) {  //We can't put this in the nativeoptions in the yaml - because it would be a string "function(..."
                                        return context.dataset.data[context.dataIndex] >= 1; //This hides 0 labels
                                    }
                                }
                            }
                        } as any;

                        if(self.cfg.charttype == "bar") {
                            options.indexAxis = 'y';
                        }

                        if(cfg.legend) {
                            if(cfg.legend != 'none') {
                                options.plugins.legend = options.plugins.legend || {}
                                options.plugins.legend.display = true;
                                options.plugins.legend.position = cfg.legend;
                            }
                        }

                        chartconfig = {
                            type: type,
                            data: {
                                labels: labels,
                                datasets: datasets
                            },
                            options: options
                        }
                        
                    }
                }
                else if((type == "pie" || type == "donut" || type == "doughnut") && data && data.length > 0) {
                    let keys = Object.keys(data[0]);
                    
                    let labels = [] as string[];
                    let values = [] as number[];
                    for(let row of data) {
                        labels.push(row[keys[0]]);
                        values.push(row[keys[1]]);
                    }
                    // for(let row of unformatted_data) {
                    //     self.unformatted_labels.push(row[keys[0]]);
                    // }
                    
                    let dataset = {
                        label: "",
                        data: values
                    } as any;

                    let color_offset = 0; //If we don't do this the colors change on redraw
                
                    if(cfg.colors) {
                        dataset.backgroundColor = [];
                        for(let i = 0; i < data.length; i++) {
                            if(cfg.colors[i]) {
                                let clr_i = i + color_offset;
                                if(clr_i >= cfg.colors.length) {
                                    clr_i = clr_i % cfg.colors.length;
                                }
                                dataset.backgroundColor.push(cfg.colors[clr_i]);
                            }
                        }
                    }

                    let options = {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            datalabels: {
                                display: function(context:any) {  //We can't put this in the nativeoptions in the yaml - because it would be a string "function(..."
                                    return context.dataset.data[context.dataIndex] !== 0; //This hides 0 labels
                                }
                            }
                        }
                    } as any;

                    if(cfg.legend) {
                        if(cfg.legend != 'none' && options && options.plugins && options.plugins.legend) {
                            options.plugins.legend.display = true;
                            options.plugins.legend.position = cfg.legend;
                        }
                    }

                    chartconfig = {
                        type: "doughnut",
                        data: {
                            labels: labels,
                            datasets: [dataset]
                        },
                        options: options
                    }

                }


                
                if(this.cfg.nativeoptions && this.cfg.nativeoptions.chartjs) {

                    if(this.cfg.nativeoptions.chartjs && 
                        this.cfg.nativeoptions.chartjs.options &&
                        this.cfg.nativeoptions.chartjs.options.plugins &&
                        this.cfg.nativeoptions.chartjs.options.plugins.datalabels && 
                        this.cfg.nativeoptions.chartjs.options.plugins.datalabels.display) {
                            //if(display is true, we should not overwrite, becaue we need to then use the display function)

                            delete this.cfg.nativeoptions.chartjs.options.plugins.datalabels.display;
                        }

                    chartconfig = ObjectDeepMerge(chartconfig, this.cfg.nativeoptions.chartjs);

                    //console.log(type + " nativeoptions "+JSON.stringify(this.cfg.nativeoptions.chartjs));
                }

                

                if(this.chart) {
                    this.chart.destroy();
                }

                //console.log("chartconfig "+JSON.stringify(chartconfig));

                this.chart = new Chart(
                    canvas,
                    chartconfig
                );
                
                if(this.initial) {
                
                    //@ts-expect-error\
                    canvas.onclick = (evt) => {
                        const res = self.chart.getElementsAtEventForMode(
                            evt,
                            'nearest',
                            { intersect: true },
                            true
                        );
                        // If didn't click on a bar, `res` will be an empty array
                        if (res.length === 0) {
                            return;
                        }
   

                        let bar_data = self.unformatted_data[res[0].index];

                        //alert('You clicked on ' + JSON.stringify(bar_data));

                        self.cb_emit({"@" : self.chart.data.labels[res[0].index], "@option_object" : bar_data, "@index": res[0].index });
                    };
            
                }
                this.initial = false;
                
                if(container) {
                    let empty_element = document.getElementById(this.brick_id+"$empty");
                    if(empty_element) {
                        empty_element.innerHTML = cfg.no_data_message || "No Results";
                        CBWebUtil.ApplyElementStyles(empty_element as HTMLElement, cfg, "empty");
                        if(!cfg.data || cfg.data.length == 0) {
                            container.classList.add("hidden");
                            empty_element.classList.remove("hidden");
                        }
                        else {
                            empty_element.classList.add("hidden");
                            container.classList.remove("hidden");
                        }
                    }
                }
            }
      }
  }
  cb_initial_cement(cements: { [child_idx: number]: any }) {
  }
  cb_update_cement(child_idx: number, cement: any, row_idx: number) {
  }

  cb_status(status: string): any {
    let container = document.getElementById(this.brick_id+"$chartcontainer");
    if(container) {
        if(status == "loading") {          
            container.classList.add("chart-loading");  
            //container.innerHTML = `<img src="/static/chart-skeleton.svg" alt="Loading chart..." style="height: 300px; width: auto;">`;
        }
        else {
            container.classList.remove("chart-loading");   
        }
    }
  }

  transformDataIntoOptions(options: any, data: any) {
      if(data && data.length > 0) {
          if(options.chart.type == "bar") {

              let categories = [] as any[];
              let series_data = {} as { [column: string] : any };

              let keys = Object.keys(data[0]);
              for(let i = 1; i < keys.length; i++) {
                  series_data[keys[i]] = [];
              }

              for(let row of data) {
                  categories.push(row[keys[0]]);
                  for(let i = 1; i < keys.length; i++) {
                      series_data[keys[i]].push(row[keys[i]]);
                  }
              }

              let series = [] as any;
              for(let i = 1; i < keys.length; i++) {
                  series.push({name: Prettyfy(keys[i]), data: series_data[keys[i]] });
              }

              options.series = series;
              options.xaxis.categories = categories;
          }
          else if(options.chart.type == "pie" || options.chart.type == "donut") {
              let series = [] as any[];
              let labels = [] as any[];
              let keys = Object.keys(data[0]);
              for(let row of data) {
                  labels.push(Prettyfy(row[keys[0]]));
                  series.push(row[keys[1]]);
              }
              options.series = series;
              options.labels = labels;
              options.chart.width = 400; //TODO
          }
          //else TODO
      }
  }

  objectArrayToDataTable(oa: any, charttype: string) : any {
      if (oa.length > 0) {
          var dt = {cols: [], rows: []} as { cols: {id: string, label: string, type: string}[], rows: {c: {v: string}[] }[]};
          var keys = Object.keys(oa[0]);
          dt.cols.push({ id: "", label: keys[0], type: "string" });
          for (var k = 1; k < keys.length; k++) {
              if (isNaN(oa[0][keys[k]])) {
                  dt.cols.push({ id: "", label: keys[k], type: "string" });
              }
              else {
                  dt.cols.push({ id: "", label: keys[k], type: "number" });
              }
          }
          for (var r = 0; r < oa.length; r++) {
              var c = [];
              for (var k = 0; k < keys.length; k++) {
                  c.push({ v: oa[r][keys[k]], f: null });
              }
              dt.rows.push({ c: c });
          }

          // @ts-expect-error
          return new google.visualization.DataTable(dt);
      }
  }

  cb_snapshot() {}
}