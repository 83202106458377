import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class ci_funnel_webcomponent extends HTMLElement {
    ci: web_ci_funnel | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_funnel(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-funnel', ci_funnel_webcomponent);

export class web_ci_funnel extends CodeBrick {

    element: HTMLElement;
    data = [] as any[];
    max_idx = 0;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("ci-funnel "+this.brick_id+" cb_event "+input+"  "+JSON.stringify(cfg));
        if(input == "data") {
            if(info && info.source_idx !== undefined) {

                this.data[info.source_idx] = cfg;

                //console.log("ci-funnel "+this.brick_id+" cb_event "+input+" info.source_idx "+info.source_idx+" data "+JSON.stringify(cfg));
                
                //return { "@" : this.data };
                //emit_deferred, else we will send an event for each brick on each row. This will send one event right at the end of everything.
                this.cb_emit_deferred({ "@" : this.data });
                
            }
        }
        else if(input == "clear") {
            this.data = [] as any[];
        }
    }
    cb_validate(): boolean {
        return this.validate_sources("");
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}