import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class ci_redirect_webcomponent extends HTMLElement {
    ci: web_ci_redirect | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_redirect(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-redirect', ci_redirect_webcomponent);

export class web_ci_redirect extends CodeBrick {

    element: HTMLElement;
    link_comp = "";
    fallback_url_if_404 = "";
    querystring = "";
    new_tab = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        //console.log("CodeBricksLinkComposition cb_event "+input+" "+JSON.stringify(cfg));

        if(input == "cfg") {
            this.link_comp = cfg.target;
            this.fallback_url_if_404 = cfg.fallback_url_if_404;

            this.querystring = "";
            let p_count = 0;
            if(cfg.parameters) {
                if(typeof cfg.parameters == 'object') {
                    for(let k in cfg.parameters) {
                        if(p_count == 0) {
                            this.querystring = "?";
                        }
                        else {
                            this.querystring += "&";
                        }
                        this.querystring += k + "="+cfg.parameters[k];
                        p_count++;
                    }
                }
            }
            if(cfg.new_tab) {
                this.new_tab = true;
            }
            
            if(this.fallback_url_if_404) {

                try {

                    let options = { method: "GET" };
                    let uri = "/cb_api/auth/has_access/"+this.link_comp;

                    (<any>options).credentials = 'include';

                    let res = await fetch(uri, options);

                    let body = await res.text();
                
                    let res_data = JSON.parse(body);

                    if(!res_data.success) {
                        (<any>window).location = `/${this.fallback_url_if_404}`;
                        return;
                    }
                }
                catch(err) {
                    console.error(this.blueprint.name + ": fallback_url_if_404 failed: "+err+"; "+err.stack);
                }
            }

            let prefix = "/";
            if(this.link_comp && (this.link_comp[0] == "/" || this.link_comp.indexOf("://") != -1)) {
                prefix = "";
            }

            if(this.new_tab){
                (<any>window).open(`${prefix}${this.link_comp}${this.querystring}`, '_blank');
            }
            else {
                (<any>window).location = `${prefix}${this.link_comp}${this.querystring}`;
            }
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}

