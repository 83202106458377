import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class ci_fetch_webcomponent extends HTMLElement {
    ci: web_ci_fetch | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_fetch(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-fetch', ci_fetch_webcomponent);

export class web_ci_fetch extends CodeBrick {

    element: HTMLElement;

    //shadowroot: ShadowRoot;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //console.log("CodeBricksRequestHttp contructor");
        //this.shadowroot = this.attachShadow({ mode: 'open' });
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("ci-fetch "+this.brick_id+" cb_event "+input+": "+JSON.stringify(cfg));

        if(input == 'cfg')
        {
            this.emit_state("loading");

            let request = cfg;

            let res_data: any;

            let method = (request.http_method || "GET").toUpperCase();

            let options = { method };
            let uri = request.url;

            if(request.headers) {
                (<any>options).headers = request.headers;
            }
            if(request.auth_type == "basic") {
                (<any>options).headers = (<any>options).headers || {};
                
                (<any>options).headers['Authorization'] = 'Basic ' + btoa(unescape(encodeURIComponent(request.auth_username + ":" + request.auth_secret)));
            }

            if(request.body) {
                if(typeof request.body == "object") {
                    (<any>options).body = JSON.stringify(request.body);
                }
                else {
                    (<any>options).body = request.body;
                }
            }

            (<any>options).credentials = 'include';

            let res = await fetch(uri, options);

            let body = await res.text(); //JSON.stringify(res.body);
            if(body === "") {
                return; //We do not emit an empty resonse. This gives the server side a way no not emit. Used for request sequencing #rr
            }

            try {
                res_data = JSON.parse(body);
            }
            catch(err) {
                res_data = body;
            }

            //console.log(this.blueprint.name+" "+JSON.stringify(cfg)+" response "+res.status + " "+JSON.stringify(res_data) +"\n");//+" "+JSON.stringify(res.headers));

            //this.cb_emit("", res_data, trigger);
            //this.cb_emit("code", res.status, trigger);
            this.emit_state("");

            return { "@": { body: res_data, code : res.status }};
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}
