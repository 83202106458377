import { InsLeaves, TagItem } from "./CBModels";
import { LeafResolver } from "./LeafResolver";
import { TagItemParser } from "./TagItemParser";

export class TemplateUtil {
    
    static ExtractDollars(template: any, keys = [] as string[]): string[] {
        let ret = keys;
        if(typeof template == "string") {
            return TemplateUtil.ExtractDollarsFromString(template, ret);
        }
        else if(typeof template == "object" && template !== null) {
            for(let k in template) {
                TemplateUtil.ExtractDollars(template[k], keys);
            }
        }
        return ret;
    }
    
    private static ExtractDollarsFromString(template_str: string, keys: string[]) : string[] {
        let state = '';
        let start = 0;
        let ret = keys;
        let hasKeys = {} as { [key:string] : boolean };
        for(let i = 0; i < template_str.length; i++) {
            if(state == '' && template_str[i] == '{') {
                state = '{';
            } 
            else if(state == '{') {
                if(template_str[i] == '{') {
                    state = '{{';
                }
                else {
                    state = '';
                }
            }
            else if(state == '{{') {
    
                if(template_str[i] == '"') {
                    state = '"';
                }
                else if(template_str[i] == "'") {
                    state = "'";
                }
                else if(template_str[i] == "$") {
                    state = "$";
                    start = i;
                }
                else if(template_str[i] == '}' && template_str[i + 1] == '}') {
                    state = '';
                }
            }
            else if(state == "$") {
                if((template_str[i] >= '0' && template_str[i] <= '9') || (template_str[i] >= 'a' && template_str[i] <= 'z') || (template_str[i] >= 'A' && template_str[i] <= 'Z') || template_str[i] == '_' || template_str[i] == '.' ) {
                }
                else if(template_str[i] == "[") {
                    state = "$[";
                }
                else {
                    let tpl = template_str.substring(start, i);
                    if(!hasKeys[tpl]) {
                        ret.push(tpl);
                        hasKeys[tpl] = true;
                    }
                    state = '{{';
                    i--;
                }
            }
            else if(state == "$[") {
                if(template_str[i] == "]") {
                    state = "$";
                }
            }
            else if(state == '"') {
                if(template_str[i] == '"') {
                    state = "{{";
                }
            }
            else if(state == "'") {
                if(template_str[i] == "'") {
                    state = "{{";
                }
            }
        }
        return ret; 
    }
    
    static RedactNonTags(template: any) {
        let ret = {} as any;
        if(typeof template == "string") {
            let tags = [] as string[];
            tags = TemplateUtil.ExtractTagsFromString(template, tags);
            // for(let t = tags.length - 1; t >= 0; t--) {
            //     if(tags[t].startsWith("{{$") || tags[t].indexOf("$app") != -1 || tags[t].indexOf("$session") != -1) {
            //         tags.splice(t, 1);
            //     }
            // }
            // ret = tags.join(' ');

            for(let t = tags.length - 1; t >= 0; t--) {
                let ti = TagItemParser.ParseTagItem(tags[t], { i: 2 }, "}}");
                if(ti.items) {
                    let tag_str = "{{";
                    for(let section of ti.items) {
                        if(section.type == ";") {
                            tag_str += ";" + section.slice;
                            continue;
                        }
                        if(tag_str != "{{") {
                            tag_str += ",";
                        }
                        let section_has_dollar = false;
                        if(section.items) {
                            section_has_dollar = this.RecurseHasDollarPValues(section);
                        
                            if(section_has_dollar) {
                                let ptags = [] as string[];
                                this.RecurseGatherNonDollarPValues(ptags, section);
                                tag_str += ptags.join("+");
                            }
                            else {
                                tag_str += this.UnParseSection(section);
                            }
                        }
                    }
                    tag_str += "}}"; 
                    tags[t] = tag_str;

                    
                    // let has_dollar = false;
                    // for(let tag of ti.items) {
                    //     if(tag.type == "p") {
                    //         if(tag.slice[0] == "$") {
                    //             has_dollar = true;
                    //             break;
                    //         }
                    //     }
                    // }
                    // if(has_dollar) {
                    //     let ptags = [] as string[];
                    //     for(let tag of ti.items) {
                    //         if(tag.type == "p") {
                    //             if(tag.slice[0] != "$") {
                    //                 ptags.push(tag.slice);
                    //             }
                    //         }
                    //     }
                    //     tags[t] = "{{" + ptags.join(",") + "}}";
                    // }
                }
            }

            ret = tags.join(' ');
        }
        else if(typeof template == "object" && template !== null) {
            for(let k in template) {
                let val = this.RedactNonTags(template[k]);
                if(val !== "") {
                    ret[k] = val;
                }
            }
        }
        return ret;
    }

    private static RecurseHasDollarPValues(ti: TagItem) {
        if(ti.type[0] == "p") {
            if(ti.slice[0] == "$") {
                return true;
            }
        }
        else if(ti.items) {
            for(let item of ti.items) {
                if(TemplateUtil.RecurseHasDollarPValues(item)) {
                    return true;
                }
            }
        }
        return false
    }

    private static RecurseGatherNonDollarPValues(p_values: string[], ti: TagItem) {
        if(ti.type[0] == "p") {
            if(ti.slice[0] != "$") {
                p_values.push(ti.slice);
            }
        }
        else if(ti.items) {
            for(let item of ti.items) {
                TemplateUtil.RecurseGatherNonDollarPValues(p_values, item);
            }
        }
    }
    
    static ExtractTags(template: any, tags = [] as string[]): string[] {
        let ret = tags;
        if(typeof template == "string") {
            return TemplateUtil.ExtractTagsFromString(template, ret);
        }
        else if(typeof template == "object" && template !== null) {
            for(let k in template) {
                TemplateUtil.ExtractTags(template[k], tags);
            }
        }
        return ret;
    }
    
    private static ExtractTagsFromString(template_str: string, tags: string[]) : string[] {
        let state = '';
        let start = 0;
        let ret = tags;
        for(let i = 0; i < template_str.length; i++) {
            if(state == '' && template_str[i] == '{') {
                state = '{';
            } 
            else if(state == '{') {
                if(template_str[i] == '{') {
                    state = '{{';
                    start = i - 1;
                }
                else {
                    state = '';
                }
            }
            else if(state == '{{') {
    
                if(template_str[i] == '"') {
                    state = '"';
                }
                else if(template_str[i] == "'") {
                    state = "'";
                }
                else if(template_str[i] == "[") {
                    state ="[";
                }
                else if(template_str[i] == '}' && template_str[i + 1] == '}') {
                    state = '';
                    let tpl = template_str.substring(start, i + 2);
    
                    ret.push(tpl);
                }
            }
            else if(state == '"') {
                if(template_str[i] == '"') {
                    state = "{{";
                }
            }
            else if(state == "'") {
                if(template_str[i] == "'") {
                    state = "{{";
                }
            }
            else if(state == "[") {
                if(template_str[i] == "]") {
                    state = "{{";
                }
            }
        }
        return ret; 
    }
    
    static GetTermParts(term: string) {
        let parts = [] as string[];
        let part = "";
        let state = "";
        for(let i = 0; i < term.length; i++) {
            if(state == "") {
                if(term[i] == ".") {
                    parts.push(part);
                    part = '';
                }
                else if(term[i] == "[") {
                    parts.push(part);
                    part = '';
                    state = "[";
                }
                else {
                    part += term[i];
                }
            }
            else if(state == "[") {
                if(term[i] == "'") {
                    state = "'";
                }
                else if(term[i] == '"') {
                    state = '"';
                }
                else if(term[i] == "]") {
                    state = '';
                }
                else {
                    part += term[i];
                }
            }
            else if(state == "'") {
                if(term[i] == "'" && term[i+1] == "]") {
                    i++;
                    state = '';
                }
                else {
                    part += term[i];
                }
            }
            else if(state == '"') {
                if(term[i] == '"' && term[i+1] == "]") {
                    i++;
                    state = '';
                }
                else {
                    part += term[i];
                }
            }
    
        }
        parts.push(part);
        return parts;
    }


    static ExtractKeys(template: any, keys = [] as string[]): string[] {
        let ret = keys;
        if(typeof template == "string") {
            return TemplateUtil.ExtractKeysFromString(template, ret);
        }
        else if(typeof template == "object" && template !== null) {
            for(let k in template) {
                TemplateUtil.ExtractKeys(template[k], keys);
            }
        }
        return ret;
    }

    static ExtractKeysFromString(template_str: string, keys: string[]) : string[] {
        let state = '';
        let start = 0;
        let len = 0;
        let ret = keys;
        //let hasKeys = {} as { [key:string] : boolean };
        for(let i = 0; i < template_str.length; i++) {
            if(state == '' && template_str[i] == '{') {
                state = '{';
            } 
            else if(state == '{') {
                if(template_str[i] == '{') {
                    state = '{{';
                    start = i;
                }
                else {
                    state = '';
                }
            }
            else if(state == '{{' && template_str[i] == '}' && template_str[i + 1] == '}') {
                state = '';
                len = i - start;

                let tpl = template_str.substr(start + 1, len - 1);

                //if(!hasKeys[tpl]) {
                    ret.push(tpl);
                //    hasKeys[tpl] = true;
                //}
            }
        }
        return ret; 
    }

    static ExtractAndReplaceKeysFromString(template_str: string, keys: string[], replacement: string) : string {
        let state = '';
        let start = 0;
        let len = 0;
        let ret = keys;

        let ret_str = "";
        let from = 0;

        for(let i = 0; i < template_str.length; i++) {
            if(state == '' && template_str[i] == '{') {
                state = '{';
            } 
            else if(state == '{') {
                if(template_str[i] == '{') {
                    state = '{{';
                    start = i;
                    ret_str += template_str.substring(from, start - 1) + replacement;
                }
                else {
                    state = '';
                }
            }
            else if(state == '{{' && template_str[i] == '}' && template_str[i + 1] == '}') {
                state = '';
                len = i - start;
                from = i + 2;

                let tpl = template_str.substr(start + 1, len - 1);

                ret.push(tpl);
            }
        }

        if(from < template_str.length - 1) {
            ret_str += template_str.substring(from, template_str.length);
        }

        return ret_str; 
    }

    static PrefixPValues(leaves: InsLeaves, prefix: string) {
        for(let input in leaves) {
            let in_leaves = leaves[input];
            for(let leaf of in_leaves.leaves) {
                for(let part of leaf.parts) {
                    if(part.tag) {     
                        TemplateUtil.PrefixPValuesRecurse(part.tag, prefix);
                    }
                }
            }
        }
    } 

    private static PrefixPValuesRecurse(ti: TagItem, prefix: string) {
        if(ti.type[0] == "p") {
            if(ti.slice[0] != "$") {
                ti.slice = prefix + ti.slice;
            }
        }
        else if(ti.items) {
            for(let item of ti.items) {
                TemplateUtil.PrefixPValuesRecurse(item, prefix);
            }
        }
    }

    static UnParseInsLeaves(leaves: InsLeaves) {
        let res_obj = {} as any;
        for(let input in leaves) {
            let in_leaves = leaves[input];
            let leaf_obj = {};
            for(let leaf of in_leaves.leaves) {
                let leaf_val = "";            
                for(let part of leaf.parts) {
                    if(part.tag) {                
                        leaf_val += TemplateUtil.UnParseTagItem(part.tag) || "";
                    }
                    else if(part.str) {
                        leaf_val += (part.str || "");
                    }
                }
                LeafResolver.SetPath(leaf.path, leaf_val, leaf_obj);
            }
            res_obj[input] = leaf_obj;
        }
        return res_obj;
    }

    static UnParseTagItem(ti: TagItem, is_bracket = false) {

        let ret = "";
        if(ti.type[0] == "{") {
            ret += is_bracket ? "(" : ("{" + ti.type);
            let first = true;
            if(ti.items) {
                for(let section of ti.items) {
                    if(first) {
                        first = false;
                    }
                    else if(section.type == ";") {
                        ret += ";";
                    }
                    else {
                        ret += ",";
                    }
                    if(section.type[0] == "{") {
                        ret += TemplateUtil.UnParseTagItem(section);
                    }
                    else if(section.type == ";") {
                        ret += section.slice;
                    }
                    else {
                        ret += TemplateUtil.UnParseSection(section);
                    }
                }       
            }
            ret += is_bracket ? ")" : "}}";

            return ret;
        }    
    }

    static UnParseSection(section: TagItem) {
        let res = "";
        if(section.items) {
            for(let t = 0; t < section.items.length; t++) {
                let term = section.items[t];
                if(term.type == 'p') {
                    res += term.slice;
                }
                else if(term.type == "s") {
                    res += "'" + this.escapeHtml(term.slice) + "'";
                }
                else if(term.type == "n" || term.type == "b") {
                    res += term.slice;
                }
                else if(term.type == "0") {
                    res += "null";
                }
                else if(term.type[0] == "{") {
                    res += TemplateUtil.UnParseTagItem(term, true);
                }
                else {
                    if(term.type == "!") {
                        res += "!=";
                    }
                    else {
                        res += term.type;
                    }
                }
            }
        }
        return res;
    }

    static escapeHtml(unsafe: string) {
		if (unsafe && typeof unsafe == "string") {
			return unsafe
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;")
				.replace(/'/g, "&#039;");
		}
		return unsafe;
	}

    public static TagHasDollar(tag: TagItem) {
        if(tag.type == "p") {
            //If you change this, also change in RedactNonTags
            if(tag.slice && (tag.slice.startsWith("$") || tag.slice.indexOf("$app") != -1 || tag.slice.indexOf("$session") != -1)) { 
                return true;
            }
        }
        if(tag.items) {
            for(let item of tag.items) {
                if(TemplateUtil.TagHasDollar(item)) {
                    return true;
                }
            }
        }
        return false;
    }

    static PValuesFromInsLeaves(ins_leaves: InsLeaves) {
        let ret = {} as any;

        for(let input in ins_leaves) {
            let in_leaves = ins_leaves[input];
            for(let leaf of in_leaves.leaves) {
                leaf.firing = false;
                for(let part of leaf.parts) {
                    if(part.tag) {  
                        this.RecurseTagItemsGetPValues(part.tag, ret);
                    }
                }
            }
        }

        return ret;
    }

    static RecurseTagItemsGetPValues(ti: TagItem, ret: any ) {
        if(ti.type == "p") {
            ret[ti.slice] = true;
        }
        if(ti.items) {
            for(let it of ti.items) {
                this.RecurseTagItemsGetPValues(it, ret);
            }
        }
    }
}