import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_textarea_webcomponent extends HTMLElement {
    ci: web_c_textarea | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_textarea(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-textarea', c_textarea_webcomponent);

export class web_c_textarea extends CodeBrick {
    
    element: HTMLElement;
    initialised = false;
    value = "";
    label = "";
    validation = null as any;

    validate = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("CodeBricksTextArea "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.cfg)+" => "+JSON.stringify(cfg));

        let name = this.brick_id;
        if(input == 'value') {
            this.value = cfg;
            let input = document.getElementById(`${name}`) as HTMLInputElement;
            if(input) {
                input.value = this.value;
                //this.cb_emit({"": this.value}, false); //trigger false, for the dest to keep the input value change.

                this.clearValidation();
                
                let input_wrap = document.getElementById(`${this.brick_id}$wrap`) as HTMLInputElement;
                if(input_wrap) {
                    input_wrap.classList.remove("c-input-validation-failed");
                }
                let validation_message_container = document.getElementById(`${this.brick_id}&validation`);
                if(validation_message_container) {
                    validation_message_container.style.display = "none";
                    validation_message_container.innerHTML = "";
                }

                return {"@": this.value};
            }
        }
        else if(input == 'cfg') {
            this.element.innerHTML = `<div class="form-element-container c-textarea-container"><label class="form-element-label c-textarea-label" for="${name}">${cfg.label || ''}</label><textarea id="${this.brick_id}" class="form-element c-textarea" rows="${cfg.rows || 5}">${this.value}</textarea></div><div class="c-input-validation-message" id="${this.brick_id}&validation" style="display:none"></div>`;
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(!this.initialised) {
                this.initialised = true;
                
                input.innerHTML = this.value;
                let self = this;
                if(input) {
                    input.addEventListener('change', function() {
                        if(self.validate) {
                            self.cb_validate();
                        }
                        self.cb_emit({ "@": input.value});
                    });
                    // input.addEventListener('input', function(event) {
                    //     self.cb_emit("asyoutype", input.value, trigger);
                    // });
                }
                
            }
            CBWebUtil.ApplyElementStyles(input, cfg, "input");
            let container = input.parentElement;
            CBWebUtil.ApplyElementStyles(container, cfg, "container");
            CBWebUtil.ApplyElementStyles(container?.firstElementChild as HTMLElement, cfg, "label");
            this.label = cfg.label;
            this.validation = cfg.validation;

        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        if(status == "required") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLTextAreaElement>input).classList.add("required");
            }
        }
        else if(status == "") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLTextAreaElement>input).classList.remove("required");
            }
        }
    }

    clearValidation() {
        let input_wrap = document.getElementById(`${this.brick_id}`) as HTMLInputElement;

        input_wrap.classList.remove("c-input-validation-failed");

        let validation_message_container = document.getElementById(`${this.brick_id}&validation`);

        if(validation_message_container) {
            validation_message_container.style.display = "none";
            validation_message_container.innerHTML = "";
        }
        this.validate = false;
    }

    cb_validate(): boolean {
        let validates = true;
        let validation_message = "";
        if(this.validation) {
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(input) {
                let value = input.value;
                
                if(this.validation.required && (value === "" || value === null || value === undefined)) {
                    validates = false;
                    validation_message = (this.label || "Value") + " is a required field.";
                }
                else if(this.validation.minlength > 0 && value.length < this.validation.minlength) {
                    validates = false;
                    validation_message = this.label + " must be at least " + this.validation.minlength + " characters.";
                }
                else if(this.validation.maxlength > 0 && value.length > this.validation.maxlength) {
                    validates = false;
                    validation_message = this.label + " may not exceed " + this.validation.maxlength + " characters.";
                }
                else if(this.validation.pattern !== "" && this.validation.pattern !== null && this.validation.pattern !== undefined) {
                    if(!new RegExp( '^(?:' + this.validation.pattern.trim() + ')$').test( value )) {
                        validates = false;
                        validation_message = this.validation.pattern_message;
                    }
                }
                else if(this.validation.invalidate_msg) {
                    validates = false;
                    validation_message = this.validation.invalidate_msg;
                }
            }

            let validation_message_container = document.getElementById(`${this.brick_id}&validation`);

            let input_wrap = document.getElementById(`${this.brick_id}`) as HTMLInputElement;

            if(validates) {
                input_wrap.classList.remove("c-input-validation-failed");

                if(validation_message_container) {
                    validation_message_container.style.display = "none";
                    validation_message_container.innerHTML = "";
                }
            }
            else {
                input_wrap.classList.add("c-input-validation-failed");

                if(validation_message_container) {
                    validation_message_container.innerHTML = validation_message;
                    validation_message_container.style.display = "block";
                }
            }
            
        }
        this.validate = true;
        return validates;
    }
    cb_snapshot() {}
}