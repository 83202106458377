import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { Data } from "../../shared-funcs/Data";

export class ci_data_webcomponent extends HTMLElement {
    ci: web_ci_data | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_data(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-data', ci_data_webcomponent);

export class web_ci_data extends CodeBrick {

    element: HTMLElement;
    data: any;
    prev_data = "";
    save_to_snapshot = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("ci-data "+this.brick_id+" cb_event "+input+" "+JSON.stringify(cfg));
        this.save_to_snapshot = cfg.save_to_snapshot || false;
        let ret = Data.ProcessDataInput(input, cfg, this);

        //console.log("ci-data "+this.brick_id+" ret "+JSON.stringify(ret));

        return ret;
    }
    cb_validate(): boolean {
        return this.validate_sources("");
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        this.emit_state(status);
    }
    cb_snapshot() {
        if(this.save_to_snapshot) {
            let snapshot = {
                cfg: {
                    value: this.data
                }
            };
            return snapshot;
        }
    }
}