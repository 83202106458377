import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import Sugar from "sugar";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_filedownload_webcomponent extends HTMLElement {
    ci: web_c_filedownload | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_filedownload(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-filedownload', c_filedownload_webcomponent);

export class web_c_filedownload extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //console.log("CodeBricksRequestHttp contructor");
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("sc-use "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));
        
        if(input != "composition") // || !this.blueprint.ins.ins) {
        {
            let composition = this.context.compositions[this.cid].composition
            let unit_path = this.blueprint.name;
            let qs = "";
            if(cfg.parameters) {
                qs = "?"+Sugar.Object.toQueryString(cfg.parameters);
                //for(let p in cfg.parameters) {
                    //TODO the above does not url encode
                //}
            }
            if(this.context.composition_runners[this.cid].debugging_enabled) {
                if(qs) {
                    qs += "&debugging_enabled=1"
                }
                else {
                    qs = "?debugging_enabled=1"
                }
            }
            let html = `<a id="${this.brick_id}" class="btn btn-primary c-filedownload" href="/cb_api/cb/filedownload/${composition}.${unit_path}${qs}" download>${cfg.button_label || "Download"}</a>`;
            this.element.innerHTML = html;

            let element = document.getElementById(this.brick_id);
            if(element) {
                // if(cfg.style) {
                //     for(let v in cfg.style) {
                //         element.style.setProperty(v, cfg.style[v]);
                //     }
                // }
                CBWebUtil.ApplyElementStyles(element, cfg, "filedownload");
                
                if(cfg.hidden) {
                    element.classList.add("hidden");
                }
                else {
                    element.classList.remove("hidden");
                }
 
                // if(cfg.style_classes) {
                //     for(let cl of cfg.style_classes) {
                //         if(cl) {
                //             element.classList.add(cl);
                //         }
                //     }
                // }
            }
        }   
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}
