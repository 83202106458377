import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_select } from "../controls/cb_select";

export class c_muliselect_webcomponent extends HTMLElement {
    ci: web_c_muliselect | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_muliselect(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-multiselect', c_muliselect_webcomponent);

export class web_c_muliselect extends CodeBrick {

    element: HTMLElement;

    no_loader = false;

    cb_select_control: cb_select;
    options = [] as any[];
    cfg = {} as any;

    selection_string = "";

    snapshot = undefined as any;

    constructor(context: CBContext, cid: string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.element.innerHTML = `<div id="${this.brick_id}"></div>`;

        let self = this;
        this.blueprint.ins.cfg.multiselect = true;
        this.cb_select_control = new cb_select(this.brick_id, this.blueprint.ins.cfg, function(value:any, option_value: any) {

            let selection_string = "";
            if(Array.isArray(value)) {
                for(let so of value) {
                    if(selection_string != "") {
                        selection_string += ",";
                    }
                    selection_string += String(so);
                }
            }
            else {
                selection_string = String(value);
            }

            self.selection_string = selection_string;

            self.snapshot = self.snapshot || {};
            self.snapshot.cfg = self.snapshot.cfg || {};
            self.snapshot.cfg.value = selection_string;

            self.cb_emit({"@": selection_string, "@user_changed": selection_string, "@array" : value });
        }, this.context.platform);

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksSelect "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));

        if(input == "cfg") {

            this.cfg = cfg;

            this.cb_select_control.default_label = cfg.default_label || "";

            this.options = [];

            //let html = '';

            //this.selected_option_idx = -1;

            //let itemidx = 0;
            if(cfg.extra_options && cfg.extra_options.length > 0) {
                for(let option of cfg.extra_options) {
                    // if(this.selected_option_idx == -1 || cfg.value == option.value) {
                    //     this.selected_option_idx = itemidx;
                    // }
                    //html += `<div id="${this.brick_id}$i$${itemidx++}" class="c-select-item">${option.label}</div>`;//`<option value="${option.value}">${option.label}</option>`;                   
                    this.options.push(option);

                }
            }

            if(cfg.options && cfg.options.length > 0) {
                
                let keys = Object.keys(cfg.options[0]);
                for(let i in cfg.options) {
                    if(typeof cfg.options[i] == "string") {
                        //html += `<option value="${options[i]}">${options[i]}</option>`;
                        //html += `<div id="${this.brick_id}$i$${itemidx++}" class="c-select-item">${cfg.options[i]}</div>`;
                        this.options.push({value: cfg.options[i], label: cfg.options[i], full: cfg.options[i]});

                        // if(this.selected_option_idx == -1 || cfg.value == cfg.options[i]) {
                        //     this.selected_option_idx = itemidx;
                        // }
                    }
                    else if(typeof cfg.options[i] == "object") {
                        let id = '';
                        if(cfg.value_column) {
                            id = cfg.options[i][cfg.value_column];
                        }
                        else {
                            id = cfg.options[i][keys[0]];
                        }


                        let label = '';
                        if(cfg.label_column) {
                            label = cfg.options[i][cfg.label_column];
                        }
                        else {
                            let lkid = 0;
                            if(keys.length > 1) {
                                lkid = 1;
                            }
                            label = cfg.options[i][keys[lkid]];
                        }

                        this.options.push({value: id, label: label, full: cfg.options[i]});

                    }
                }
            }

            if(cfg.no_loader) {
                this.no_loader = true;
            }
            else {
                this.no_loader = false;
            }

            cfg.multiselect = true;
            this.cb_select_control.setOptions(this.options, cfg)
            let selected_options = this.cb_select_control.setMultiSelectValue(cfg.value, cfg);

            let selection_string = "";
            if(selected_options) {
                for(let so of selected_options) {
                    if(selection_string != "") {
                        selection_string += ",";
                    }
                    selection_string += so[cfg.value_column || "value"];
                }
            }

            //if(this.cb_select_control.value) {
                this.selection_string = selection_string;
                return { "@": selection_string || "", "@array" : selected_options };
            //}

        }


    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }

    cb_status(status: string): void {

        let container = document.getElementById(this.brick_id+"$container");
        let loader = document.getElementById(this.brick_id+"_loader");
        if(status == "loading" && !this.no_loader) {
            if(container) {
                container.classList.add("loading");
            }
            if(loader) {
                loader.classList.add("loader-loading");
            }
        }
        else {
            if(container) {
                container.classList.remove("loading");
            }
            if(loader) {
                loader.classList.remove("loader-loading");
            }
        }
    }

    cb_validate(): boolean {
        let validates = true;
        let validation_message = "";
        if(this.cfg.validation) {
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(input) {
                if(this.cfg.validation.required && (this.selection_string  === "" || this.selection_string  === null || this.selection_string  === undefined)) {
                    validates = false;
                    validation_message = (this.cfg.label || "Value") + " is required.";

                    let select = document.getElementById(this.brick_id + "$select");
                    if(select) {
                        select.classList.add("c-input-validation-failed");
                    }
                    return false;
                }
            }
        }
        let select = document.getElementById(this.brick_id + "$select");
        if(select) {
            select.classList.remove("c-input-validation-failed");
        }
        return true;
    }

    cb_snapshot() {
        return this.snapshot;
    }

}
