import Sugar from "sugar";

export function codebricks_format(value: any, format: string, system_options: any, dont_format_numbers = false) : { value: string | number, type: string } {
    try {
        if(format) {
            let base_type = format;
            let base_param_str = "" as any;
            let chain_str = "";
            let open_bracket = 0;
            let is_regexp = false;
            let is_money = false;
            let is_boolean = false;
            for(let i = 0; i < format.length; i++) {
                if(format[i] == "(") {
                    open_bracket = i;
                }
                else if(open_bracket && format[i] == ")") {
                    base_param_str = format.substring(open_bracket + 1, i);
                    base_type = format.substring(0, open_bracket);
                    chain_str = format.substring(i + 1);
                    break;
                }
                else if(open_bracket == 0 && format[i] == ".") {
                    base_type = format.substring(0, i);
                    chain_str = format.substring(i+1);
                    break;
                }
            }
            base_type = base_type.toLowerCase();

            if(value === null || value === undefined || value === "") {
                return { value: value, type: base_type };
            }

            base_param_str = TrimQuotes(base_param_str);

            let formatted_value = value;
            if(base_type == "date") {
                
                if(system_options.locale) {
                    setLocale(system_options.locale);
                }

                if(typeof value == "string") {
                    value == value.trim();
                    //@ts-expect-error
                    if(!isNaN(value)) {
                        value = Number(value);
                    }
                }
                if(typeof value == "string" && value.endsWith('Z')) {
                    value = Sugar.Date.create(value, { setUTC: true });

                    let timezone_offset = value.getTimezoneOffset();
                    if(system_options.timezone_offset !== undefined) {
                        timezone_offset = system_options.timezone_offset;
                    }

                    value = Sugar.Date.addMinutes(value, -timezone_offset);
                }
                else {
                    value = Sugar.Date.create(value);
                }
            }
            else if(base_type == "datefromutc") {

                if(system_options.locale) {
                    setLocale(system_options.locale);
                }

                //let prevalue = value;

                if(typeof value == "string") {
                    value == value.trim();
                    //@ts-expect-error
                    if(!isNaN(value)) {
                        value = Number(value);
                    }
                }

                if(typeof value == "string" && value.endsWith('Z')) {
                    value = Sugar.Date.create(value, { setUTC: true });
                }
                else {
                    value = Sugar.Date.create(value);
                }

                //let startdate = value;
                //console.log("startdate "+startdate);

                //let startstring = Sugar.Date.format(value, "{short} {HH}:{mm}");


                let timezone_offset = value.getTimezoneOffset();
                if(system_options.timezone_offset !== undefined) {
                    timezone_offset = system_options.timezone_offset;
                }

                value = Sugar.Date.addMinutes(value, -timezone_offset);

                //console.log("date datefromutc "+prevalue+": "+startstring+" ("+startdate+")->"+Sugar.Date.format(value, "{short} {HH}:{mm}")+" ("+value+") timezone_offset "+timezone_offset + " system_options "+JSON.stringify(system_options));

                base_type = "date";
            }
            else if(base_type == "datetoutc") {

                if(system_options.locale) {
                    setLocale(system_options.locale);
                }

                //let prevalue = value;

                if(typeof value == "string") {
                    value == value.trim();
                    //@ts-expect-error
                    if(!isNaN(value)) {
                        value = Number(value);
                    }
                }

                if(typeof value == "string" && value.endsWith('Z')) {
                    value = Sugar.Date.create(value, { setUTC: true });
                }
                else {
                    value = Sugar.Date.create(value);
                }
                //let startdate = value;
                //let startstring = Sugar.Date.format(value, "{short} {HH}:{mm}");

                let timezone_offset = value.getTimezoneOffset();
                if(system_options.timezone_offset !== undefined) {
                    timezone_offset = system_options.timezone_offset;
                }

                //value = Sugar.Date.create(value, {setUTC: true});
                value = Sugar.Date.addMinutes(value, timezone_offset);

                //console.log("date datetoutc "+prevalue+": "+startstring+" ("+startdate+")->"+Sugar.Date.format(value, "{short} {HH}:{mm}")+" ("+value+") timezone_offset "+timezone_offset + " system_options "+JSON.stringify(system_options));
                base_type = "date";
            }
            else if(base_type == "number") { 
                if(!isNaN(value)) {
                    value = Number(value);
                }      
            }
            else if(base_type == "string") {
                value = value + "";
            }
            else if(base_type == "object" || base_type == "array") {

            }
            else if(base_type == "regexp") {
                value = value + "";
                is_regexp = true;
            }
            else if(base_type == "money") {
                is_money = true;
                base_type = "number";
            }
            else if(base_type == "bool") {
                is_boolean = true;
            }
            else {
                //Don't allow execing functions on other types!
                return { value: value, type: base_type };
            }

            if(base_type == "number") {
                if(system_options && system_options.dec_sep) {
                    Sugar.Number.setOption('decimal', system_options.dec_sep);
                }
                if(system_options && system_options.thousands_sep) {
                    Sugar.Number.setOption('thousands', system_options.thousands_sep);
                }
            }

            if(chain_str ) {
                let chain = getChainFromStr(chain_str); //chain_str.split(".");
                formatted_value = ExecChain(value, chain, 0, is_regexp);
            }
            else {
                formatted_value = value;
            }

            //Final format:
            if(typeof formatted_value == "object") {
                if(formatted_value._sugar_utc !== undefined) { //If it has this prop its a date object
                    if(open_bracket && base_param_str) {
                        formatted_value = Sugar.Date.format(formatted_value, base_param_str);
                    }
                    else {
                        formatted_value = Sugar.Date.format(formatted_value, system_options.date_format);
                    }
                }
            }
            else if(typeof formatted_value == "number") {
                if(base_type != "number" && dont_format_numbers ) {
                    return { value: formatted_value, type: "number" };
                }
    
                if(open_bracket && base_param_str !== "" && !isNaN(base_param_str)) {
                    formatted_value = Sugar.Number.format(formatted_value, Number(base_param_str));
                }
                else {
                    let dec_places = system_options.dec_places;
                    if(dec_places === "" || dec_places === null || dec_places ===undefined) {
                        dec_places = 2;
                    }
                    formatted_value = Sugar.Number.format(formatted_value, dec_places);
                }
            }
            else if(typeof formatted_value == "boolean") {
                if(is_boolean) {
                    formatted_value = formatted_value == true ? "Yes" : "No";
                }
            }

            if(is_money && system_options.currency_symbol && formatted_value !== "") {
                if(dont_format_numbers) {
                    return { value: value, type: "number" };
                }
                formatted_value = system_options.currency_symbol + " " + formatted_value;
            }

            return { value: formatted_value, type: base_type };
            
        }
        else {
            if(value !== null && !isNaN(value)) {
                return { value: value, type: "number" }; //The tables will text-align:right number, this is the use of the type.
            }
        }
    }
    catch(err) {
        console.error("Format Error: value "+value+" format "+format+" error: "+err+"; "+(<any>err).stack);
    }
    
    return { value: value, type: format };
}

function TrimQuotes(str: string) {
    if(str) {
        if(str[0] == "'" || str[0] == '"') {
            return str.substring(1, str.length-1);
        }
        else if(!isNaN(Number(str))) {
            return Number(str);
        }
    }
    return str;
}

function ExecChain(initial_value: any, chain: string[], chain_idx: number, is_regexp: boolean) {
    for(let f = chain_idx; f < chain.length; f++){
        initial_value = ExecFuncStr(initial_value, chain[f], is_regexp);
        is_regexp = false;
    }
    return initial_value;
}

function ExecFuncStr(initial_value: any, func_str: string, is_regexp: boolean) {
    let params_start = func_str.indexOf("(");
    if(params_start > 1) {
        let param_str = func_str.substring(params_start + 1, func_str.length-1);
        let parray = [] as any[];
        if(param_str !== "") {
            parray = param_str.split(",").map(item => TrimQuotes(item.trim()));
        }
        let func_name = func_str.substring(0, params_start);
        if(typeof initial_value == "object") {
            if(Array.isArray(initial_value)) {
                if(func_name == "toJSON") {
                    return JSON.stringify(initial_value);
                }
                else if(func_name == "toPrettyJSON") {
                    return JSON.stringify(initial_value, null, 2);
                }
                ////@ts-expect-error
                // if(!Sugar.Array[func_name]) {
                //     console.error("Formatting function " +func_name+ "() does not exist on Sugar.Array");
                //     return initial_value;
                // }
                //@ts-expect-error
                return Sugar.Array(initial_value)[func_name](...parray).raw; //works for Sugar.Array([]).includes("")
            }
            else if(initial_value._sugar_utc !== undefined) { //If it has this prob its a date object
                if(func_name == "toUTC") {

                }
                //@ts-expect-error
                return Sugar.Date[func_name](initial_value, ...parray);
            }
            else {
                if(func_name == "toJSON") {
                    return JSON.stringify(initial_value);
                }
                else if(func_name == "toPrettyJSON") {
                    return JSON.stringify(initial_value, null, 2);
                }
                //@ts-expect-error
                return Sugar.Object[func_name](initial_value, ...parray);
            }
        }
        else if(typeof initial_value == "string") {
            if(is_regexp) {
                //@ts-expect-error
                return Sugar.RegExp[func_name](initial_value, ...parray);
            }
            else {
                if(func_name == "toLowerCase") {
                    return initial_value.toLowerCase();
                }
                else if(func_name == "toUpperCase") {
                    return initial_value.toUpperCase();
                }
                //@ts-expect-error
                return Sugar.String[func_name](initial_value, ...parray);
            }
        }
        else if(typeof initial_value == "number") {
            if(func_name == "mult") {
                return initial_value * Number(parray[0]);
            }
            else if(func_name == "toYesNo") {
                return initial_value > 0 ? "Yes" : "No";
            }
            //@ts-expect-error
            return Sugar.Number[func_name](initial_value, ...parray);
        }
        else if(typeof initial_value == "boolean") {
            if(func_name == "toYesNo") {
                return initial_value == true ? "Yes" : "No";
            }
        }
    }
    return initial_value;
}

function getChainFromStr(chain_str: string) {
    //we can't just split on . because for example Number.mutl(0.1)
    let state = 0;
    let ret = [] as string[];
    let start = 0;
    for(let i = 0; i < chain_str.length; i++) {
        let c = chain_str[i];
        if(c == "." && state == 0) {
            ret.push(chain_str.substring(start, i));
            start = i + 1;
        }
        else if(c == "(") {
            state++;
        }
        else if(c == ")") {
            state--;
        }
    }
    ret.push(chain_str.substring(start));
    return ret;
}

export function setLocale(locale: string) {
    if(!locale) {
        return;
    }

    if(locale == "en-ZA") {
        let all = Sugar.Date.getAllLocaleCodes();

        if(!all.includes(locale)) {
            let locale_obj = Object.create(Sugar.Date.getLocale("en")); //Clone(Sugar.Date.getLocale("en"));
            
            locale_obj.code = locale;
            locale_obj.short = "{yyyy}/{MM}/{dd}";

            Sugar.Date.addLocale(locale, locale_obj);
        }
    }
    else if(locale == "en-UK") {
        let all = Sugar.Date.getAllLocaleCodes();

        if(!all.includes(locale)) {
            let locale_obj = Object.create(Sugar.Date.getLocale("en")); // Clone(Sugar.Date.getLocale("en"));
            
            locale_obj.code = locale;
            locale_obj.short = "{yyyy}-{MM}-{dd}";

            Sugar.Date.addLocale(locale, locale_obj);
        }
    }
    Sugar.Date.setLocale(locale);
}