import { Brick, CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class c_export_webcomponent extends HTMLElement {
    ci: web_c_export | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_export(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-export', c_export_webcomponent);

export class web_c_export extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log("c-snapshot "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));
        
        if(input == "cfg")
        {
            let snapshot_name = cfg["snapshot_name"];
            let container_brick_name = cfg["container_brick"];

            let container_brick = this.context.compositions[this.cid];
            let snapshot_composition = container_brick.composition;
            if(container_brick_name) {
                container_brick = this.find_brick(this.context.compositions[this.cid], container_brick_name);
            }

            if(container_brick) {
                let snapshot = this.take_snapshot(container_brick);

                let snapshot_post_obj = {
                    snapshot_name: snapshot_name,
                    snapshot: snapshot
                };
                
                //Create the snapshot
                let res = this.api_request(snapshot_composition, snapshot_post_obj);
                //Download it:
                //Redirect to download link for snapshot

                return { "@": res };
            }
            else {
                console.error("c-snapshot: Container brick "+container_brick_name+" not found.");
            }
        }   
    }

    take_snapshot(blueprint: Brick) {
        let snapshot_obj = {
            name: blueprint.name
        } as any;
        let ci = this.get_brick_by_name(blueprint.name);
        if(ci) {
            snapshot_obj.snapshot = ci.cb_snapshot();
        }
        if(blueprint.contains) {
            snapshot_obj.children = [];
            for(let b of blueprint.contains) {
                snapshot_obj.children[b.name] = this.take_snapshot(b);
            }
        }
    }

    get_brick_by_name(name: string) {
        let ci = this.context.bricks["cb_"+this.cid+"_"+name];
        //We do not take snapshots of dynamic bricks. So this wil return undefined for those bricks, that also have the dc in the brick_id
        return ci;
    }

    find_brick(at: any, name: string) : any {
        if(at.name == name) {
            return at;
        }
        if(at.contains) {
            for(let c of at.contains) {
                let b = this.find_brick(at.contains[c], name);
                if(b) {
                    return b;
                }
            }
        }
        return null;
    }

    async api_request(composition_name: string, snapshot_post_obj: any) : Promise<any> {

        this.emit_state("loading");

        let res_data: any;

        let method = "POST";

        let options = { method };
        let uri = "/cb_api/cb/snapshot/"+composition_name;

        (<any>options).body = JSON.stringify(snapshot_post_obj);
        
        (<any>options).credentials = 'include';

        (<any>options).headers = { "Content-Type":"application/json" };

        let res = await fetch(uri, options);

        let body = await res.text();

        try {
            res_data = JSON.parse(body);
        }
        catch(err) {
            res_data = body;
        }
        this.emit_state("");
        return res_data;
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {
    }
}
