export class Unit {
    static PassThrough(input: string, cfg: any) {
        let output = input;
        if(output == "default") {
            output = "";
        }
        let ret = {} as any;
        ret["@" + output] = cfg;
        return ret;
    }
}