import { codebricks_format } from "../codebricks-runtime/CBFormatting";

export class DateRange {
    static valueToOutput(cfg: any, val: string) : { start: string, end: string } {
        let presets = cfg.presets;
        for(let p = 0; p < presets.length; p++) {
            if(val == presets[p].label || val == p + '') {
                let outval = { start: presets[p].start, end: presets[p].end};
                // this.datepicker_from.selectDate(outval.start);
                // this.datepicker_to.selectDate(outval.end);
                // if(this.input) {
                //     this.input.value = presets[p].label;
                // }
                return outval;
            }
        }
        if(val.indexOf(' - ') != -1) {
            let s = val.split(' - ');
            return { start: s[0], end: s[1] };
        }
        return { start: val, end: val };
    }

    static GetValues(cfg: any, system_options: any) {
        
        let ret = {} as any;

        ret.displayformat = cfg.displayformat || system_options.date_format || "Date({short})";
        ret.outputformat = cfg.outputformat || ret.displayformat;

        ret.preset = cfg.value;

        let start = Sugar.Date.create(); //Sugar.Date.format(Sugar.Date.create(), ret.dateformat);
        let end = Sugar.Date.create(); //Sugar.Date.format(Sugar.Date.create(), ret.dateformat);
        if(cfg.value) {
            if(typeof cfg.value == "string" && cfg.value.indexOf(" - ") != -1) {
                let s = cfg.value.split(" - ");
                start = Sugar.Date.create(s[0]); //Sugar.Date.format(Sugar.Date.create(s[0]), ret.dateformat);
                end = Sugar.Date.create(s[1]); //Sugar.Date.format(Sugar.Date.create(s[1]), ret.dateformat);

                ret.preset = "";
            }
            else if(Array.isArray(cfg.value.value)) {
                if(cfg.value[0]) {
                    start = Sugar.Date.create(cfg.value[0]); //Sugar.Date.format(Sugar.Date.create(cfg.value[0]), ret.dateformat);
                }
                if(cfg.value[1]) {
                    end = Sugar.Date.create(cfg.value[1]); //Sugar.Date.format(Sugar.Date.create(cfg.value[1]), ret.dateformat);
                }
                ret.preset = "";
            }
            else if(typeof cfg.value.value == "object") {
                if(cfg.value.start) {
                    start = Sugar.Date.create(cfg.value.start); //Sugar.Date.format(Sugar.Date.create(cfg.value.start), ret.dateformat);
                }
                if(cfg.value.end) {
                    end = Sugar.Date.create(cfg.value.end); //Sugar.Date.format(Sugar.Date.create(cfg.value.end), ret.dateformat);
                }
                ret.preset = "";
            }
            else {
                if(cfg.presets) {
                    for(let preset of cfg.presets) {
                        if(preset.label == ret.preset) {
                            start = Sugar.Date.create(preset.start); //Sugar.Date.format(Sugar.Date.create(preset.start), ret.dateformat);
                            end = Sugar.Date.create(preset.end); //Sugar.Date.format(Sugar.Date.create(preset.end), ret.dateformat);
                            break;
                        }
                    }
                }
            }
        }
        else {
            ret.preset = "";
        }

        ret.display_value = codebricks_format(start, ret.displayformat, system_options).value + " - " + codebricks_format(end, ret.displayformat, system_options).value;

        ret.date1 = cfg.from == "" ? 
            Sugar.Date.create() : Sugar.Date.create(
                start);

        ret.date2 = cfg.to == "" ? 
            Sugar.Date.create() : Sugar.Date.create(
                end);
        
        return ret;
    }
}