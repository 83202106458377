import { Brick } from "../codebricks-runtime/CBModels";

export class LayoutGrid {
    static orderChilds(brick: Brick) {
        let ordered_childs = [] as any[];
        let p = 0;
        if(brick.contains) {
            for(let sub of brick.contains) {
                let added = false;
                sub.cement = sub.cement || {};
                for(let i = 0; i < ordered_childs.length; i++) {
                    if((sub.cement.row || 999) <= (ordered_childs[i].sub.cement.row || 998) && (sub.cement.position || 999) <= (ordered_childs[i].sub.cement.position || 998)) {
                        
                        ordered_childs.splice(i, 0, { p: p, sub: sub});
                        added = true;
                        break;
                    }
                }
                if(!added) {
                    ordered_childs.push({ p: p, sub: sub });
                }
                p++;
            }
        }

        return ordered_childs;
    }
}