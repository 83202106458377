import { codebricks_format } from "../codebricks-runtime/CBFormatting";

export interface PivotHeader {
    col: string;
    cc: string | undefined;
    dc: string;
    ren: string;
    frmt: string | undefined;
    col_format: string | undefined;
}

export interface PTableRow {
    col: string,
    format: string | undefined,
    rename: string,
    val: any,
    display_val: any
}

export interface PivotTreeNode {
    key: string,
    val: string,
    children: PivotTreeNode[],
    rowskeys: PTableRow[],
    vals: { [key:string]: number }
    atrow: number, 
    tval: string,
    open: boolean,
    idx: number,
    format: string
}

export interface TreeData {
    tree: PivotTreeNode,
    pivotHeaders: PivotHeader[]
}

export interface PivotCol {
    col: string;
    format?: string;
    rename?: string;
}

export class PivotTable {
    static dataToTreeP(data: any, rows: PTableRow[], cols: { column: string, format: string}[], dataCols: PTableRow[], openlevel: number, searchTerm: string, DontOrderDataCols: boolean, system_options: any, no_format = false, dont_sort_nodes = false) : TreeData | null {
        if (!data) {
            return null;
        }
        if(data.length == 0) {
            return data;
        }

        var pivotHeaders = [] as PivotHeader[];
        for(var c = 0; c < cols.length; c++) {
            let col = cols[c];
            var colcols = [] as string[];
            for(var r = 0; r < data.length; r++) {
                let col_val = data[r][col.column];
                let formatted_col_val = no_format ? col_val : codebricks_format(col_val, col.format, system_options || {}).value;
                data[r][col.column] = formatted_col_val;             
                if(DontOrderDataCols) {
                     this.addDistinct(colcols, formatted_col_val);
                }
                else {
                    this.addDistinctOrdered(colcols, formatted_col_val);
                }
            }
            for(var cc = 0; cc < colcols.length; cc++) {
                for(var d = 0; d < dataCols.length; d++) {
                    //pivotHeaders.push({ col: cols[c], cc: colcols[cc], dc: dataCols[d].col, tmpl: dataCols[d].template ? (dataCols[d].template.replace("{{"+dataCols[d].col+"}}", "<<"+dataCols[d].col+">>")) : "" });
                    pivotHeaders.push({ col: cols[c].column, cc: colcols[cc], dc: dataCols[d].col, ren: dataCols[d].rename, frmt: dataCols[d].format, col_format: cols[c].format });
                }
            }
        }

        if(cols.length == 0) {
            for(var d = 0; d < dataCols.length; d++) {
                pivotHeaders.push({ col: null as any as string, cc:undefined, dc: dataCols[d].col, ren: dataCols[d].rename, frmt: dataCols[d].format, col_format: cols[c] !== undefined ? cols[c].format : "" });
            }
        }

        var root = { key: 'root', val: 'All', children:[] as PivotTreeNode[], rowskeys: [] as PTableRow[], vals: {} as { [key:string]: number } } as PivotTreeNode;

        //root.rowskeys = [] as PivotTreeNode[];
        //root.vals = {};
        for(var ph = 0; ph < pivotHeaders.length; ph++) {
            root.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc] = 0;
        }
        var row0keys = Object.keys(data[0]);
        for(var r = 0; r < rows.length; r++) {
            if(isNaN(rows[r].col as any)) {
                root.rowskeys.push(rows[r]);
            }
            else { //column index can be specified instead of column name for pivot rows list. This is useful when column names are data driven according to database by a ColTransform
                root.rowskeys.push({ col: row0keys[rows[r].col as any], rename: rows[r].rename, format: rows[r].format, val: '' });      
            }
        }

        //console.log("pivotHeaders: "+JSON.stringify(pivotHeaders));
        var searchlc;
        if(searchTerm) {
            searchlc = searchTerm.toLowerCase();
        }
        for(var r = 0; r < data.length; r++) {
            var rowBranch = this.getRowBranchP(data[r], rows);
            let inSearch = this.getAddBranchP(root, rowBranch, pivotHeaders, data[r], 0, openlevel, root.rowskeys, searchlc as any as string, r, dont_sort_nodes);
            for (var ph = 0; ph < pivotHeaders.length && inSearch; ph++) {
                if(data[r][pivotHeaders[ph].col as string] == pivotHeaders[ph].cc) {

                    //console.log(pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc +" " +root.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc]+" += "+data[r][pivotHeaders[ph].dc]);

                    root.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc] += data[r][pivotHeaders[ph].dc];
                }
            }
        }
        //console.log("tree: "+JSON.stringify(root));

        //this.treeToDCData(root, pivotHeaders);

        return { tree: root, pivotHeaders: pivotHeaders };
    }


    static getRowBranchP(row: any, rows: PTableRow[]) {
        var ret = [];
        var rowkeys = Object.keys(row);
        for(var k = 0; k < rows.length;  k++) {
            if(isNaN(rows[k].col as any)) {
                ret.push(row[rows[k].col] || "");
            }
            else {
                ret.push(row[rowkeys[rows[k].col as any]] || "");
            }
        }
        return ret;

    }

    static getAddBranchP(node: PivotTreeNode, branch: any, pivotHeaders: PivotHeader[], row: any, at: number, openlevel: number, rows: PTableRow[], searchTerm: string, data_idx: number, dont_sort_nodes: boolean) {

        if(searchTerm) {
            var searchfound = false;
            for(var b = 0; b < branch.length; b++) {
                if((branch[b].toString().toLowerCase()).indexOf(searchTerm) != -1) {
                    searchfound = true;
                    break;
                }
            }
            if(!searchfound) {
                return false;
            }
        }

        var found = null;
        for(var i = 0; i < node.children.length; i++) {
            if(node.children[i].val == branch[at]) {
                found = node.children[i];
            }
        }
        if(found == null) {
            var tval = branch[at];
            // if(rows[at].template) {
            //     tval = Handlebars.render(rows[at].template, row);
            // }

            if(at < branch.length - 1) {
                found = { val: branch[at], tval: tval, vals: {}, tvals: {}, children:[], open: (at < openlevel), idx: data_idx, format: rows[at].format } as any as PivotTreeNode;
            }
            else {
                found = { val: branch[at], tval: tval, vals: {}, tvals: {}, open: (at < openlevel), idx: data_idx, format: rows[at].format} as any as PivotTreeNode;
            }
            var spliced = false;
            if(!dont_sort_nodes) {
                for(var i = 0; i < node.children.length; i++) {
                    if(found.val < node.children[i].val) {
                        node.children.splice(i, 0, found);
                        spliced = true;
                        break;
                    }
                }
            }
            if(!spliced) {
                node.children.push(found);
            }
            for(var ph = 0; ph < pivotHeaders.length; ph++) {
                if(row[pivotHeaders[ph].col] == pivotHeaders[ph].cc) {
                    found.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc] = row[pivotHeaders[ph].dc];
                }
                else {
                    found.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc] = 0;
                }
                // if(pivotHeaders[ph].tmpl) {

                //     var trow = Object.create(row);
                //     trow[pivotHeaders[ph].col] = pivotHeaders[ph].cc; //MobileInvoiceSummary month by month drill through. We want to update the one val for the template, without changing the original row, or having to clone the whole original, so we use prototypal inheritance to inherit from it and override just the prop.
                //     //if you update the row itself the totals break.
                    
                //     found.tvals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc] =  Handlebars.render(pivotHeaders[ph].tmpl, trow);
                // }
            }
        }
        else {
            for(var ph = 0; ph < pivotHeaders.length; ph++) {
                if(row[pivotHeaders[ph].col] == pivotHeaders[ph].cc) {
                    found.vals[pivotHeaders[ph].col+"~"+pivotHeaders[ph].cc+"~"+pivotHeaders[ph].dc] += row[pivotHeaders[ph].dc];
                }
            }
        }
        if(at < branch.length - 1) {
            this.getAddBranchP(found, branch, pivotHeaders, row, at + 1, openlevel, rows, searchTerm, data_idx, dont_sort_nodes);
        }

        return true;
    }

    //cut n paste from v2
    static groupData(data: any, groupcols: string[], countcols: string[], sumcols: string) {
        var groupDict = {} as any;
        for(var r = 0; r < data.length; r++) {
            var group = "";
            for(var g = 0; g < groupcols.length; g++) {
                group += data[r][groupcols[g]] + "^";
            }
            var grouprow = groupDict[group];
            if(!grouprow) {
                grouprow = {};
                for(var g = 0; g < groupcols.length; g++) {
                    grouprow[groupcols[g]] = data[r][groupcols[g]];
                }
                if(countcols) {
                    for(var c = 0; c < countcols.length; c++) {
                        grouprow[countcols[c]] = 1;
                    }
                }
                if(sumcols) {
                    for(var s = 0; s < sumcols.length; s++) {
                        grouprow[sumcols[s]] = data[r][sumcols[s]];
                    }
                }
                groupDict[group] = grouprow;
            }
            else {
                if(countcols) {
                    for(var c = 0; c < countcols.length; c++) {
                        grouprow[countcols[c]]++;
                    }
                }
                if(sumcols) {
                    for(var s = 0; s < sumcols.length; s++) {
                        grouprow[sumcols[s]] += data[r][sumcols[s]];
                    }
                }
            }
        }
    
        var keys = Object.keys(groupDict);
        var ret = [];
        for(var k = 0; k < keys.length; k++) {
            ret.push(groupDict[keys[k]]);
        }
        return ret;
    }
    //cut n paste from v2
    static dataCol(col: string, dataCols: string[], d: number, multiHeader: boolean) {
        if(dataCols.length == 1 && !multiHeader) {
            return col; //dataCols[0];
        }
        return col + '_' + dataCols[d];
    }
    //cut n paste from v2
    static pivotData(data: any, row: string, col: string, dataCols: string[], multiHeader: boolean) {
        if(data.length == 0) {
            return data;
        }
    
        var pivotCols = [] as string[];
        for(let r = 0; r < data.length; r++) {
            for(var d = 0; d < dataCols.length; d++) {
                this.addDistinctOrdered(pivotCols, this.dataCol('' + data[r][col], dataCols, d, multiHeader)); 
            }
        }
    
        var ret = [];
        var atRow = "-1";
        var r = null as any;
        var keys = Object.keys(data[0]); 
        for(var dr = 0; dr < data.length; dr++) {
            var drow = data[dr];
            var rowAdded = false;
            if(drow[row] != atRow) {
                r = this.findRow(ret, row, drow[row]);
                if(r == null) {
                    r = {}; 
                    atRow = drow[row];
                    for(var k = 0; k < keys.length; k++)
                    {
                        var dc = false;
                        for(var d = 0; d < dataCols.length; d++) {
                            if(keys[k] == dataCols[d]) {
                                dc = true;
                                break;
                            }
                        }
                        if(dc) {
                            continue;
                        }
                        if(keys[k] != col)
                        {
                            r[keys[k]] = drow[keys[k]] || "";
                        }
                    }
                    for(var pc = 0; pc < pivotCols.length; pc++) {
                        var set = false;
                        for(var d = 0; d < dataCols.length; d++) {
                            if(pivotCols[pc] == this.dataCol(drow[col], dataCols, d, multiHeader)) {
                                r[pivotCols[pc]] = drow[dataCols[d]] ? drow[dataCols[d]] : 0;
                                set = true;
                            }
                        }
                        if(!set) {
                            r[pivotCols[pc]] = 0;
                        }
                    }
                    ret.push(r);
                    rowAdded = true;
                }
                else {
                    atRow = drow[row];
                }
            }
            
            if(!rowAdded) {
                for(var d = 0; d < dataCols.length; d++) {
                    r[this.dataCol(drow[col], dataCols, d, multiHeader)] = r[this.dataCol(drow[col], dataCols, d, multiHeader)] + drow[dataCols[d]];
                }
            }
        }
        //console.log("pivoted: "+JSON.stringify(ret));
        return ret;
    }
    //cut n paste from v2
    static findRow(data: any, colname: string, value: any) {
        for(var r = 0; r < data.length; r++) {
            if(data[r][colname] == value) {
                return data[r];
            }
        }
        return null;
    }

    static addDistinctOrdered(list: string[], v: string) {
        for(var i = 0; i < list.length; i++) {
            if(v == list[i]) {
                return;
            }
            if (this.compareMaybeDate(v, list[i])) {
                list.splice(i, 0, v);
                return;
            }
        }
        list.push(v);
    }

    static compareMaybeDate(v1: string, v2: string) {
        //check if date in form MMM-YY
        if (!v1) {
            v1 = '';
        }
        if (!v2) {
            v2 = '';
        }
        // if (v1.length == 6 && v2.length == 6 && v1[3] == '-' && v2[3] == '-') {
        //     return moment(v1, "MMM-YY") < moment(v2, "MMM-YY");
        // }
        return v1 < v2;
    }

    static addDistinct(list: string[], v: string) {
        for(var i = 0; i < list.length; i++) {
            if(v == list[i]) {
                return;
            }
        }
        list.push(v);
    }

    static orderTree(tree: PivotTreeNode, pivotHeaders: PivotHeader[], orderPHIdx: number) {
        var val = pivotHeaders[orderPHIdx].col+"~"+pivotHeaders[orderPHIdx].cc+"~"+pivotHeaders[orderPHIdx].dc;
        this.orderChildren(tree, val);
    }

    static orderChildren(node: PivotTreeNode, val: string) {
        node.children.sort(function(a, b) { 
            return b.vals[val] - a.vals[val];
        });
        for(var c = 0; c < node.children.length; c++) {
            if(node.children[c].children) {
                this.orderChildren(node.children[c], val);
            }
        }
    }
}