import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class ci_periodic_webcomponent extends HTMLElement {
    ci: web_ci_periodc | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_periodc(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-periodic', ci_periodic_webcomponent);

export class web_ci_periodc extends CodeBrick {
    element: HTMLElement;

    cfg: any;
    runs = 0;
    waiting = false; //Prevent multiple setTimeouts running concurrently
    initialized = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("ci-periodic "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(cfg));

        this.cfg = cfg;

        if(!cfg.disabled && cfg.period_ms && !this.waiting) {
            this.repeat();
        }

        if(cfg.run_initially && !this.initialized) {       
            this.initialized = true;   
            return { "@" : cfg.value };
        }
    }

    repeat() {
        this.waiting = false;
        if(this.cfg.disabled || !this.cfg.period_ms) {
            return;
        }

        let self = this;
        setTimeout(function() {

            if(self.cfg.disabled) {
                return;
            }

            //console.log("ci-periodic "+self.blueprint.name + " emit "+self.cfg.value);

            self.cb_emit({"@": self.cfg.value});

            self.cfg.runs++;
            if(self.cfg.limit_repeats) {
                if(self.runs >= self.cfg.limit_repeats) {
                    return;
                }
            }

            self.repeat();

        }, self.cfg.period_ms);
        
        this.waiting = true;
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}