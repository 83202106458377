import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { Unit } from "../../shared-funcs/Unit"
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_use_webcomponent extends HTMLElement {
    ci: web_cc_use | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_use(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-use', cc_use_webcomponent);

export class web_cc_use extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;


        //this.child_run_list_name = this.blueprint.nsid || "";

        let innerHTML = `<div id="${this.brick_id}">`;
        if(this.blueprint.contains) {
            let i = 0;
            for(let sub of this.blueprint.contains) {
                let brick = CBWebUtil.BrickHtml(sub, this, i);
                innerHTML += brick;
                i++;
            }
        }
        this.element.innerHTML = innerHTML + "</div>";


        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("cc-use "+this.brick_id+" input "+input+" cfg "+JSON.stringify(cfg));

        let ret = Unit.PassThrough(input, cfg);

        let emit = null as any;
        if(this.context.composition_runners[this.cid].debugging_enabled) {
            emit = {
                emitter: this.brick_id,
                emit_data: ret,
                events: []
            };
            this.context.debug_log.emits.push(emit);
        }

        //This has resolved, so we should init the contents, it was not init until now, it is in a separate run list, which is the nsid, which is equal to this.blueprint.name of the xc-use.
        await this.context.composition_runners[this.cid].run_init_passes(info, this.blueprint.name, emit);
       
        //console.log("cc-use "+this.brick_id+" return "+JSON.stringify(ret));
        
        return ret;
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        this.emit_state(status);
    }
    cb_snapshot() {}
}

