import { cb_popout } from "./cb_popout";
import { CBWebUtil } from "./cb_web_util";

export class cb_select {
    id: string;
    settings: any;
    options = [] as any[];
    selected_option_idx = null as number | null;
    multi_selected_idxs = [] as any[];
    all_idxs = [] as number[];
    open = false;
    callback: (value:any, option_object: any, value_snapshot: any) => any;
    initialized = false;

    mouse_is_over = false;

    value:any;
    full:any;

    is_multiselect = "";

    multiselection_changed = false;

    open_offset = null as any | null;

    keyboard_selected = 0;

    popout = {} as cb_popout;

    disabled = false;
    search_hidden = false;

    default_label = "None Selected";

    platform = "";

    constructor(id:string, settings: any, callback: (value:any, option_object: any, value_snapshot: any) => any, platform: string) {
        this.id = id;
        this.callback = callback;
        this.platform = platform;
        let container = document.getElementById(id);

        if(settings.multiselect) {
            this.is_multiselect = "multi";
        }

        if(container) {

            let transformed_select = false;
            let extract_options = [];
            if(container.tagName == "SELECT") {
                transformed_select = true;
                let select_options = (container as HTMLSelectElement).options;
                
                //@ts-expect-error
                for(let o of select_options) {
                    extract_options.push({ value: o.value, label: o.text });
                }
                var new_container = document.createElement('div');
                container.insertAdjacentElement('afterend', new_container);
                container.style.display = "none";
                container = new_container;

            }

            let label = '';
            let classes = '';
            let styles = "";
            if(settings) {
                let cfg = settings;
    
                label = cfg.label;
                if(label && label[0] == "{") {
                    label = "";
                }
                        
                if(cfg.style) {
                    for(let v in cfg.style) {
                        if(v != 'src') {
                            styles += v+':'+cfg.style[v]+";"
                        }
                    }
                }
               
                if(cfg.style_classes) {
                    for(let cl in cfg.style_classes) {
                        classes += " " +cfg.style_classes[cl];
                    }
                }
            }

            container.classList.add("form-element-container");
            container.classList.add(`c-${this.is_multiselect}select-container`);
            let html = "";        
            if(!transformed_select) {
                let display = "";
                if((label || "") == "") {
                    display = `style="display:none"`;
                }
                html = `<label for="${this.id}$select" class="form-element-label c-${this.is_multiselect}select-label" id="${this.id+"$label"}" ${display}>${label || ''}</label>`;
            }
            html +=  `<div id="${this.id}$select" class="form-element c-${this.is_multiselect}select ${classes}" style="${styles}" tabindex="0"></div>`;

            container.innerHTML = html;

            // let dropdown_html =
            //     `<div class="popover c-select-dropdown " id="${this.id}$drop">
            //         <div class="c-select-search-container">
            //             <input type="text" id="${this.id}$search" class="c-select-search-input" placeholder="Search" />
            //         </div>
            //         <div id="${this.id}$items" tabindex="0"></div>
            //     </div>`;
            // //We attach the drop to the window so we can position relative to the window, because we find the select's coordinates relative to the window
            // window.document.body.insertAdjacentHTML('beforeend', dropdown_html);

            let self = this;

            this.popout = new cb_popout(id, settings.multiselect, settings, "c-select", true, false,
            function() {
                //pop opened
                self.set_keyboard_selection(self.selected_option_idx || 0);   
                if(!self.search_hidden) {
                    let searchbox = document.getElementById(self.id+"$search") as HTMLInputElement;
                    if(searchbox) {
                        if(searchbox.classList.contains("hidden")) {
                        }
                        else {
                            searchbox.value = "";
                            searchbox.dispatchEvent(new Event('input', {bubbles:true}));
                            searchbox.focus();
                        }
                    }
                }
                else {
                    let items = document.getElementById(self.id+"$items") as HTMLElement;
                    if(items) {
                        items.focus();
                    }
                }
            },
            function() {
                
                // let emit_val = 
                //     //@ts-expect-error
                //     Sugar.Date.format(
                //         self.date, self.dateformat)

                // console.log(self.brick_id+" emit "+emit_val);

                // self.cb_emit({"@": emit_val });

                //self.callback(self.options[self.selected_option_idx || 0].value, self.options[self.selected_option_idx || 0].full, self.options[self.selected_option_idx || 0].snapshot);
                self.multiselectClosed();
            });

            if(transformed_select) {
                this.setOptions(extract_options, settings);
                this.setSingleSelectValue(settings.value, settings);
            }

        }     
    }

    setOptions(p_options: any, settings: any) {
        this.options = [];
        this.all_idxs = [];
        this.settings = settings;


        let select = document.getElementById(this.id+"$select") as HTMLElement;
        if(select) {
            if(settings.disabled || p_options == null || p_options.length == 0) {
                this.disabled = true;
                select.classList.add("c-select-disabled");
                this.popout.disabled = true;
            }
            else {
                this.disabled = false;
                select.classList.remove("c-select-disabled");
                this.popout.disabled = false;
            }
        }

        let html = '';

        //this.selected_option_idx = null;
        //this.multi_selected_idxs = [] as any[];

        if(this.is_multiselect) {//} && (settings.all_value || settings.all_value === 0)) {
           
            


            
            // let v = settings.value as any;
            let all_selected = "";
            // if((settings.all_by_default && (settings.value == "" || (Array.isArray(v) && settings.value.length == 0)))
            // || (settings.value == settings.all_value)
            // ) {
            //     settings.value = settings.all_value;
            //     all_selected = " c-multiselect-all-selected"
            // }


            //Add 'All' option:
            if(!settings.max_selections) {
                html += `<div id="${this.id}$i$all" class="c-multiselect-item c-multiselect-all${all_selected}">${settings.max_selections ? ("Select Max ("+settings.max_selections+ ")") : "All"}</div>`;
            }
        }

        let itemidx = 0;
        if(p_options && p_options.length > 0) {
            
            let keys = Object.keys(p_options[0]);
            for(let i in p_options) {
                let option_selected = false;
                if(typeof p_options[i] != "object") {

                    if(this.is_multiselect && (settings.all_value || settings.all_value === 0) && p_options[i] === settings.all_value) {
                        //Don't add all_option from data, we add it explicitly first
                        continue;
                    }

                    html += `<div id="${this.id}$i$${itemidx++}" class="c-${this.is_multiselect}select-item">${p_options[i]}</div>`;
                    this.options.push({value: p_options[i], label: p_options[i], full: p_options[i].full, value_snapshot: p_options[i].value_snapshot });

                    // if(this.selected_option_idx === null || settings.value == p_options[i]) {
                    //     this.selected_option_idx = itemidx;
                    //     option_selected = true;
                    // }

                    if(this.is_multiselect) {
                        // if((settings.all_value || settings.all_value === 0) && settings.value == settings.all_value) { 
                        //     this.multi_selected_idxs.push(itemidx); //If value is all_value, then select all
                        // }
                        // else {
                        //     if(settings.value && Array.isArray(settings.value)) {
                        //         for(let m of settings.value) {
                        //             if(p_options[i] == m) {
                        //                 this.multi_selected_idxs.push(itemidx);
                        //                 option_selected = true;
                        //             }
                        //         }
                        //     }   
                        // }
                        this.all_idxs.push(itemidx);
                    }
                }
                else if(typeof p_options[i] == "object") {
                    let id = '';
                    // if(settings.value_column) { we take this out because c-select does it. If we ALSO do it it will be wrong
                    //     id = p_options[i][settings.value_column];
                    // }
                    // else {
                        id = p_options[i][keys[0]];
                    //}

                    if(this.is_multiselect && (settings.all_value || settings.all_value === 0) && id === settings.all_value) {
                        //Don't add all option from data, we add it explicitly first
                        continue;
                    }

                    // if(this.selected_option_idx === null || settings.value == id) {
                    //     this.selected_option_idx = itemidx;
                    // }

                    
                    if(this.is_multiselect) {
                        // if((settings.all_value || settings.all_value === 0) && settings.value == settings.all_value) {
                        //     this.multi_selected_idxs.push(itemidx); //If value is all_value, then select all
                        //     option_selected = true;
                        // }
                        // else {
                        //     if(settings.value && Array.isArray(settings.value)) {
                        //         for(let m of settings.value) {
                        //             if(p_options[i] == m) {
                        //                 this.multi_selected_idxs.push(itemidx);
                        //                 option_selected = true;
                        //             }
                        //         }
                        //     }                       
                        // }
                        this.all_idxs.push(itemidx);
                    }

                    let label = '';
                    //if(settings.label_column) { we take this out because c-select does it. If we ALSO do it it will be wrong
                    //    label = p_options[i][settings.label_column];
                    //}
                    //else {
                        let lkid = 0;
                        if(keys.length > 1) {
                            lkid = 1;
                        }
                        label = p_options[i][keys[lkid]];
                    //}

                    html += `<div id="${this.id}$i$${itemidx++}" class="c-${this.is_multiselect}select-item${option_selected?" c-multiselect-item-selected":""}">${label}</div>`;
                    this.options.push({ value: id, label: label, full: p_options[i].full, value_snapshot: p_options[i].value_snapshot });

                }
            }
        }

        if(!this.initialized) {
            let drop = document.getElementById(this.id+"$drop");
            if(drop) {
                drop.innerHTML = `<div class="c-select-search-container">
                <input type="text" id="${this.id}$search" class="form-element c-select-search-input" placeholder="Search" autocomplete="off"/>
            </div>
            <div id="${this.id}$items" tabindex="0" class="select-items">${html}</div>
            `;
            }
        }
        else {
            let items_container =  document.getElementById(`${this.id+"$items"}`);
            if(items_container) {
                items_container.innerHTML = html;
            }
        }
        
                           
        // let select_items = document.getElementById(this.id+"$items") as HTMLSelectElement;
        // if(select_items) {
        //     select_items.innerHTML = html;
        //     select_items.tabIndex = 0;
        // }

        let label = document.getElementById(`${this.id+"$label"}`);
        if(label) {
            label.innerHTML = settings.label || '';
            if((settings.label || "") == "") {
                label.classList.add("hidden");
            }
            else {
                label.classList.remove("hidden");
            }
        }

        let container = document.getElementById(`${this.id}`);
        if(container) {
            if(settings.hidden) {
                container.classList.add("hidden");
            }
            else {
                container.classList.remove("hidden");
            }
        }

        let self = this;
        
        if(select) {
            select.tabIndex = 0;
            // if(settings.disable) {
            //     select.disabled = true;
            // }
            // else {
            //     select.disabled = false;
            // }

            // if(this.is_multiselect && (self.multi_selected_idxs.length == self.all_idxs.length)) {
            //     select.innerHTML = self.getMultiselectLabel();
            // }
            // else {
            //     if(this.options && this.options.length > 0 && this.selected_option_idx !== null && this.selected_option_idx < this.options.length) {
            //         select.innerHTML = this.options[this.selected_option_idx].label;
            //     }
            // }

            if(!this.initialized) {
                //select.addEventListener("click", function(e: any) {
                    // if(self.popout.isOpen()) {
                    //     setTimeout(function() {
                    //         self.closeSelect();
                    //     }, 100);
                    // }
                    // else {
                        // if(!self.disabled) {
                        //     self.openSelect();
                        // }
                    //}
                //});

                select.addEventListener("keydown", function(event: any) {
                    if(event.key == "Enter" || event.key == "ArrowDown") {
                        // let select = document.getElementById(self.id+"$select") as HTMLElement;
                        // if(select) {
                        //     select.click();
                        // }
                        self.openSelect();
                    }
                });
            }

        }

        if(this.is_multiselect) {
            let all_item = document.getElementById(this.id+"$i$all");
            if(all_item) {
                all_item.addEventListener("click", function() {
                    if(!self.disabled) {
                        let all_item = document.getElementById(self.id+"$i$all");
                        
                        //If the configured all_option is clicked
                        if((self.multi_selected_idxs.length == self.all_idxs.length)) {
                            //All was already selected, so now Unselecting all

                            if(all_item) {
                                all_item.classList.remove(`c-multiselect-all-selected`);
                            }

                            for(let idx of self.multi_selected_idxs) {
                                let item = document.getElementById(self.id+"$i$"+idx);
                                if(item) {
                                    item.classList.remove(`c-multiselect-item-selected`);
                                }
                            }
                            self.multi_selected_idxs = [];

                        }
                        else {
                            //Select all
                            if(all_item) {
                                all_item.classList.add(`c-multiselect-all-selected`);
                            }
                            
                            let max = self.all_idxs.length;

                            if(self.settings.max_selections) {
                                for(let idx of self.multi_selected_idxs) {
                                    let item = document.getElementById(self.id+"$i$"+idx);
                                    if(item) {
                                        item.classList.remove(`c-multiselect-item-selected`);
                                    }
                                }

                                max = self.settings.max_selections;
                            }

                            self.multi_selected_idxs = [];

                            let count = 0;
                            for(let idx of self.all_idxs) {
                                self.multi_selected_idxs.push(idx);
                                let item = document.getElementById(self.id+"$i$"+idx);
                                if(item) {
                                    item.classList.add(`c-multiselect-item-selected`);
                                }
                                count++;
                                if(count >= max) {
                                    break;
                                }
                            }
                        }
                    
                        let select = document.getElementById(self.id+"$select") as HTMLElement;
                        if(select) {
                            select.innerHTML = self.getMultiselectLabel();
                        }

                        self.multiselection_changed = true;
                    }
                });
            }           
        }

        for(let o = 0; o < this.options.length; o++) {
            let item = document.getElementById(this.id+"$i$"+o);
            if(item) {
                item.addEventListener("click", function() {

                    let s = this.id.split("$");
                    let idx = s[s.length-1];

                    if(self.is_multiselect) {

                        let unselected = false;
                        for(let i = 0; i < self.multi_selected_idxs.length; i++) {
                            let mi = self.multi_selected_idxs[i];
                            if(mi == idx) {
                                //clicked an already selected option
                                self.multi_selected_idxs.splice(i, 1);

                                let prev_selected =  document.getElementById(self.id+"$i$"+mi);
                                if(prev_selected) {
                                    prev_selected.classList.remove(`c-multiselect-item-selected`);
                                }

                                unselected = true;

                                let select = document.getElementById(self.id+"$select") as HTMLElement;
                                if(select) {
                                    select.innerHTML = self.getMultiselectLabel();
                                }

                                break;
                            }
                        }

                        if(!unselected) {

                            if(settings.max_selections) {
                                if(self.multi_selected_idxs.length >= settings.max_selections) {
                                    let current_val = item.innerHTML;
                                    item.innerHTML = `<span class="hilight-red" style="padding:0">Max ${settings.max_selections} allowed</span>`;
                                    setTimeout(function() {
                                        item.innerHTML = current_val;
                                    }, 500)
                                    return;
                                }
                            }

                            self.multi_selected_idxs.push(Number(idx));

                            let select = document.getElementById(self.id+"$select") as HTMLElement;
                            if(select) {
                                select.innerHTML = self.getMultiselectLabel();
                            }

                            let all = document.getElementById(self.id+"$i$all");
                            if(all) {
                                all.classList.remove(`c-multiselect-item-selected`);
                            }

                            let selected = document.getElementById(self.id+"$i$"+idx);
                            if(selected) {
                                selected.classList.add(`c-multiselect-item-selected`);
                            }
                        
                        }

                        self.multiselection_changed = true;
                    }
                    else { //single select
                        // let prev_selected =  document.getElementById(self.id+"$i$"+self.selected_option_idx);
                        // if(prev_selected) {
                        //     prev_selected.classList.remove(`c-select-item-selected`);
                        // }
                        
                        // self.selected_option_idx = Number(idx);

                        // let select = document.getElementById(self.id+"$select") as HTMLElement;
                        // if(select) {
                        //     select.innerHTML = self.options[self.selected_option_idx].label;
                        // }

                        // let selected = document.getElementById(self.id+"$i$"+self.selected_option_idx);
                        // if(selected) {
                        //     selected.classList.add(`c-select-item-selected`);
                        // }
                        
                        self.setSingleSelectValue(self.options[Number(idx)].value, self.settings);

                        self.closeSelect();

                        //self.cb_emit({"@": self.options[self.selected_option_idx].value});
                        if(self.callback) {
                            self.callback(self.options[self.selected_option_idx || 0].value, self.options[self.selected_option_idx || 0].full, self.options[self.selected_option_idx || 0].snapshot);
                        }
                    }
                });
            }
        }
        
        if(!this.initialized) {
            let searchbox =  document.getElementById(this.id+"$search");
            if(searchbox) {
                searchbox.addEventListener("input", function(e: any) {
                    let text = e.target.value.toLowerCase();

                    for(let i = 0; i < self.options.length; i++) {
                        let option = self.options[i];
                        let option_element = document.getElementById(self.id+"$i$"+i);
                        if(option_element) {
                            if(text.length == 0 || option.label.toLowerCase().indexOf(text) != -1) {
                                //option_element.style.height = "block";
                                option_element.classList.remove("hidden");
                            }
                            else {
                                //option_element.style.display = "none";
                                option_element.classList.add("hidden");
                            }           
                        }             
                    }
                    
                });
            
            

                searchbox.addEventListener('keydown', function(event: any) {
                    if(event.key == "ArrowDown" || event.key == "Tab") {
                        //let searchbox =  document.getElementById(self.id+"$search");
                        //if(searchbox) {
                            let items = document.getElementById(self.id+"$items") as HTMLElement;
                            if(items) {
                                items.focus();
                            }
                            self.set_keyboard_selection(self.selected_option_idx || 0);
                            event.preventDefault();
                        //}
                    }
                    else if(event.key == "Escape") {
                        self.closeSelect();
                    }
                });
            }

            let items = document.getElementById(self.id+"$items") as HTMLElement;
            if(items) {
                items.addEventListener('keydown', function(event: any) {
                    if(event.key == "ArrowDown" || event.key == "Tab") {

                        if(event.key == "Tab" && self.keyboard_selected + 1 >= self.options.length) {
                            //CBWebUtil.UnFocus();

                            self.closeSelect();

                            //CBWebUtil.focusNextElement();
                            //console.log(CBWebUtil.focusNextElement(false).id);
                            let select = document.getElementById(self.id+"$select") as HTMLElement;
                            CBWebUtil.focusNextElement(false, select).focus();
                        }
                        else {
                            self.set_keyboard_selection(self.keyboard_selected + 1);   
                        }      
                    }
                    else if(event.key == "ArrowUp") {
                        if(self.keyboard_selected == 0) {
                            let searchbox =  document.getElementById(self.id+"$search");
                            if(searchbox && !self.search_hidden) {
                                searchbox.focus();
                                self.set_keyboard_selection(-1);
                            }
                        }
                        else {
                            self.set_keyboard_selection(self.keyboard_selected - 1);         
                        }                    
                    }
                    else if(event.key == "Enter") {
                        let item = document.getElementById(self.id+"$i$"+self.keyboard_selected);
                        if(item) {
                            item.click();
                        }
                    }
                    else if(event.key == "Escape") {
                        self.closeSelect();
                    }
                    event.preventDefault();        
                });
            }
        }

        let searchbox =  document.getElementById(this.id+"$search");
        if(searchbox) {
            let search_entries = settings.search_entries;
            if(isNaN(search_entries) || (!search_entries && search_entries !== 0)) {
                search_entries = 5;
            }
            if(this.options.length <= Number(search_entries)) {
                searchbox.classList.add("hidden");
                this.search_hidden = true;
            }
            else {
                searchbox.classList.remove("hidden");
                this.search_hidden = false;
            }
        }

        this.initialized = true;

        this.popout.init();


        // if(this.is_multiselect) {
        //     this.value = [];

        //     //If all_value set and all selected, send all_value
        //     if((settings.all_value || settings.all_value === 0) && (this.multi_selected_idxs.length == self.all_idxs.length)) {
        //         this.value = [settings.all_value];

        //         return this.value;
        //     }

        //     for(let mi of this.multi_selected_idxs) {
        //         let selected = document.getElementById(this.id+"$i$"+mi);
        //         if(selected) {
        //             selected.classList.add(`c-multiselect-item-selected`);
        //         }
        //         this.value.push(this.options[mi].value);

        //         this.callback(this.value, this.value);

        //         return this.value;
        //     }
        // }
        // else {
        //     let selected = document.getElementById(this.id+"$i$"+this.selected_option_idx);
        //     if(selected) {
        //         selected.classList.add(`c-select-item-selected`);
        //     }

        //     if(this.options && this.options.length > 0 && this.selected_option_idx !== null && this.selected_option_idx < this.options.length) {
        //         this.value = this.options[this.selected_option_idx].value;
        //         this.full = this.options[this.selected_option_idx].full;

        //         this.callback(this.value, this.full);

        //         return this.value;
        //     }
        // }
    }

    setSingleSelectValue(value: any, settings: any, may_recurse = true) : any {
        let ret_option = null;
        let option_set = !may_recurse;
        let first_option = null as any;
        if(this.options && this.options.length > 0) {
            let idx = 0;
            this.multi_selected_idxs = [];
            for(let option of this.options) {
                if(!first_option) {
                    first_option = option;
                }

                if(option.value == value) {
                    ret_option = option;

                    if(this.selected_option_idx !== null) {
                        let prev_selected =  document.getElementById(this.id+"$i$"+this.selected_option_idx);
                        if(prev_selected) {
                            prev_selected.classList.remove(`c-select-item-selected`);
                        }
                    }

                    this.selected_option_idx = idx;
                    
                    let select = document.getElementById(this.id+"$select") as HTMLElement;
                    if(select) {
                        select.innerHTML = this.options[this.selected_option_idx].label;
                    }

                    let selected = document.getElementById(this.id+"$i$"+this.selected_option_idx);
                    if(selected) {
                        selected.classList.add(`c-select-item-selected`);
                    }

                    this.selected_option_idx = idx;
                    option_set = true;
                    break;
                }
                idx++;
            }
        }
        else {
            let select = document.getElementById(this.id+"$select") as HTMLElement;
            if(select) {
                select.innerHTML = '';
            }
        }

        if(!option_set && first_option && may_recurse) {
            return this.setSingleSelectValue(first_option.value, settings, false);
        }

        return ret_option;    
    }

    setMultiSelectValue(value: any, settings: any) {
        let selected_options = [];
        this.multi_selected_idxs = [];
        if(settings.all_value !== "" && (value == settings.all_value && (value || "") != "" ||
            (this.multi_selected_idxs.length == this.all_idxs.length && this.multi_selected_idxs.length > 0) || 
            (this.multi_selected_idxs.length == 0 && this.settings.all_by_default))) {

            let all_item = document.getElementById(this.id+"$i$all");
            if(all_item) {
                all_item.classList.add(`c-multiselect-item-selected`);
            }

            let select = document.getElementById(this.id+"$select") as HTMLElement;
            if(select) {
                select.innerHTML = 'All';
            }

            return [{ value: settings.all_value }];
        }        
        else {

            let all_item = document.getElementById(this.id+"$i$all");
            if(all_item) {
                all_item.classList.remove(`c-multiselect-item-selected`);
            }

            let val_options = [];
            if(typeof value == "string" || typeof value == "number") {
                let str_options = (value + "").split(",");

                for(let s of str_options) {
                    val_options.push(s.trim());
                }
            }

            if(Array.isArray(value)) {
                for(let v of value) {
                    if(typeof v == "object") {
                        if(settings.value_column) {
                            val_options.push(value[settings.value_column]);
                        }
                        else {
                            let keys = Object.keys(v);
                            if(keys && keys.length > 0) {
                                let key = keys[0] as string;
                                val_options.push(v[key]);
                            }
                        }
                    }
                    else {
                        val_options.push(v);
                    }
                }
            }

            if(this.options && this.options.length > 0) {
                let idx = 0;
                for(let option of this.options) {
                    let is_selected = false;
                    for(let vo of val_options) {
                        if(option.value == vo) {

                            selected_options.push(option.full);
                            is_selected = true;

                            let selected = document.getElementById(this.id+"$i$"+idx);
                            if(selected) {
                                selected.classList.add(`c-multiselect-item-selected`);
                            }

                            this.multi_selected_idxs.push(idx);
                        }
                    }

                    if(!is_selected) {
                        let selected = document.getElementById(this.id+"$i$"+idx);
                        if(selected) {
                            selected.classList.remove(`c-multiselect-item-selected`);
                        }
                    }

                    idx++;
                }
            }
      
            let selected_label = this.getMultiselectLabel();
            let select = document.getElementById(this.id+"$select") as HTMLElement;
            if(select) {
                select.innerHTML = selected_label;
            }
        }

        return selected_options;
    }

    set_keyboard_selection(idx: number) {
        
        let item = document.getElementById(this.id +"$i$" + idx);
        if(item) {
            let prev_item = document.getElementById(this.id +"$i$" + this.keyboard_selected);
            if(prev_item) {
                prev_item.classList.remove("c-select-item-kb-select");
            }

            item.classList.add("c-select-item-kb-select");
            this.keyboard_selected = idx;
            item.scrollIntoView(false);
        } 
    }

    multiselectClosed() {
        if(this.is_multiselect && this.callback && this.multiselection_changed) {            
            this.value = [];
            //If all_value set and all selected, send all_value
            if((this.settings.all_value || this.settings.all_value === 0) && (this.multi_selected_idxs.length == this.all_idxs.length)) {
                this.callback([this.settings.all_value], [this.settings.all_value], [this.settings.all_value]);
                this.multiselection_changed = false;
                return; 
            }
            for(let mi of this.multi_selected_idxs) {
                this.value.push(this.options[mi].value);                           
            }     
            this.callback(this.value, this.value, this.value);       
            this.multiselection_changed = false;    
        }
    }

    getMultiselectLabel() {
        // if((this.multi_selected_idxs.length == this.all_idxs.length && this.multi_selected_idxs.length > 0) || ()) {
        //     let all_item = document.getElementById(this.id+"$i$all");
        //     if(all_item) {
        //         all_item.classList.add(`c-multiselect-all-selected`);
        //     }
        // }
        // else {
        //     let all_item = document.getElementById(this.id+"$i$all");
        //     if(all_item) {
        //         all_item.classList.remove(`c-multiselect-all-selected`);
        //     }
        // }

        if(this.multi_selected_idxs.length == this.all_idxs.length && this.multi_selected_idxs.length > 0) {
            return this.default_label || "All";
        }
        else if(this.multi_selected_idxs.length == 0) {
            return this.default_label || "None selected" + (this.settings.max_selections ? (" (max "+this.settings.max_selections+")") : "");
        }
        else if((this.multi_selected_idxs.length <= this.settings.collapse_amount || 0) || this.platform == "file") {
            let ret = "";
            for(let i of this.multi_selected_idxs) {
                if(ret != "") {
                    ret += ", ";
                }
                ret += this.options[i].label;
            }
            return ret;
        }
        else {
            return this.multi_selected_idxs.length + " selected";
        }
    }

    // static getRect(element:any) {
    //     let rect = element.getBoundingClientRect();
    //     let x = rect.x;
    //     let y = rect.y;

    //     y += window.scrollY;
    //     x += window.scrollX;

    //     return {x,y};
    // }

    openSelect() {
        this.popout.openPopout(this.popout);
        // let id = cb_select_object.id;

        // let prev_item = document.getElementById(this.id +"$i$" + this.keyboard_selected);
        // if(prev_item) {
        //     prev_item.classList.remove("c-select-item-kb-select");
        // }

        // //initialise global object
        // (<any>window).cb_select_global = (<any>window).cb_select_global as any || {
        //     cb_selects_by_id : {} as { [id:string] : cb_select },
        //     scroll: [] as {x: number, y: number}[],
        //     pop_idx: 0,

        //     window_click_handler: function(e: any) {
        //         //Close when clicking anywhere
        //         let cb_select_global = (<any>window).cb_select_global;
        //         if(cb_select_global.open_id !== undefined) {
        //             let select = document.getElementById(cb_select_global.open_id+"$select");
        //             if(select && select.contains(e.target)) {
        //                 return; //except select element
        //             }
        //             if(!cb_select_global.open_drop_guard) {
        //                 let open_drop = document.getElementById(cb_select_global.open_id+"$drop");
        //                 if(open_drop && select) {
                           
        //                     if(select.classList.contains("c-multiselect")) {
        //                         if(open_drop.contains(e.target)) {
        //                             return; //don't close on selection if multiselect
        //                         }
        //                     }

        //                     cb_select_global.cb_selects_by_id[cb_select_global.open_id].multiselectClosed();
        //                     cb_select_global.open_id = undefined;
                            
        //                     //open_drop.style.display = 'none';
        //                     open_drop.classList.remove("popover_show");
        //                     open_drop.style.removeProperty("transition"); 
                            
        //                 }
        //             }
        //         }
        //     } as any,

        //     mouse_wheel_handler: function(evt:any) {
        //         //Close the drop on scroll, otherwise the element will scroll away from the drop. 
        //         //We can't make the container relative because if the parent is overflow: scroll the drop will not be able to hover over the end, 
        //         // but will be hidden behind the scoll
        //         //Note: THis hides the drop on scroll. select2's solution is to block scroll when select open.
        //         if(cb_select_global.open_id !== undefined) {
               
        //             //But don't close if scolling on the drop element
        //             let elements_under_mouse = document.querySelectorAll(':hover');
        //             for(let elem of elements_under_mouse) {
        //                 if(elem.classList.contains("c-select-dropdown")) {

        //                     let cs = getComputedStyle(elem);
        //                     if(cs.height < cs.maxHeight) {
        //                         evt.preventDefault();
        //                     }                          
        //                     return;
        //                 }
        //             }

        //             let open_drop = document.getElementById(cb_select_global.open_id+"$drop");
        //             if(open_drop) {
        //                 if(cb_select_global.open_id !== undefined) {
        //                     cb_select_global.cb_selects_by_id[cb_select_global.open_id].multiselectClosed();
        //                 }
        //                 cb_select_global.open_id = undefined;
                        
        //                 //open_drop.style.display = 'none';
        //                 open_drop.classList.remove("popover_show");
        //                 open_drop.style.removeProperty("transition");         
        //             }
                    
        //         }
        //     },

        //     check_drop_moved: function(popidx: number) {
        //         let cb_select_global = (<any>window).cb_select_global;
        //         if(popidx != cb_select_global.pop_idx) {
        //             return;
        //         }
        //         if(cb_select_global.open_id !== undefined) {
        //             let cb_select_object = cb_select_global.cb_selects_by_id[cb_select_global.open_id];
        //             let select = document.getElementById(cb_select_global.open_id+"$select");

        //             //Only block scrolling if scolling on the drop element
        //             let on_drop = false;
        //             let elements_under_mouse = document.querySelectorAll(':hover');
        //             for(let e of elements_under_mouse) {
        //                 if(e.classList.contains("c-select-dropdown")) {
        //                     on_drop = true;
        //                     break;
        //                 }
        //             }
            
        //             if(select && cb_select_object.open_offset != null) {
        //                 var offset = cb_select.getRect(select);
            
        //                 if(on_drop || (cb_select_object.open_offset.x == offset.x && 
        //                     cb_select_object.open_offset.y == offset.y
        //                 )) {

        //                     setTimeout(cb_select_global.check_drop_moved.bind(null, popidx), 101);
        //                 }
        //                 else {

        //                     //console.log("drop moved close "+popidx);

        //                     if(cb_select_global.open_id !== undefined) {
        //                         cb_select_global.cb_selects_by_id[cb_select_global.open_id].multiselectClosed();
        //                     }                           
        //                     let drop = document.getElementById(cb_select_global.open_id+"$drop");
        //                     cb_select_global.open_id = undefined;
        //                     if(drop) {
        //                         //drop.style.display = 'none';    
        //                         drop.classList.remove("popover_show");
        //                         drop.style.removeProperty("transition");
        //                     }
        //                 }
                        
        //             }
        //         }
        //     }
        // };

        // let cb_select_global = (<any>window).cb_select_global;
        // cb_select_global.cb_selects_by_id[cb_select_object.id] = cb_select_object;

        // if(!cb_select_global.initialized) {
        //     //set global listeners
        //     window.addEventListener('click', cb_select_global.window_click_handler);
        //     cb_select_global.initialized = true;

        //     let body = document.querySelector("body");
            
        //     if(body) {
        //         body.addEventListener("mousewheel", cb_select_global.mouse_wheel_handler, { passive: false });
        //         body.addEventListener("touchmove", cb_select_global.mouse_wheel_handler, { passive: false });
        //     }
        // }

        // //If another one is open, close it
        // if(cb_select_global.open_id !== undefined) {
        //     let open_drop = document.getElementById(cb_select_global.open_id+"$drop");
        //     if(open_drop) {           
        //         //open_drop.style.display = 'none';
        //         open_drop.classList.remove("popover_show");
        //         open_drop.style.removeProperty("transition");
        //     }
        //     if(cb_select_global.open_id == id) {
        //         cb_select_global.open_id = undefined;
        //         return;
        //     }
        // }

        // //Open newly clicked drop
        // cb_select_global.open_id = id;
        // let new_drop = document.getElementById(cb_select_global.open_id+"$drop");
        // if(new_drop) {
        //     cb_select_global.open_drop_guard = true;
        //     setTimeout(() => (
        //         cb_select_global.open_drop_guard = false
        //     ), 100); //setTimeout so the window handler below does not close it immediately. You cant use stopproagation because it will prevent another open select from closing if you open this while that is open.

        //     let select = document.getElementById(cb_select_global.open_id+"$select");
        //     if(select) {

        //         let cs = getComputedStyle(select);

        //         //move the absolute positioned drop to the possibly moved (scrolled) element.
        //         let height = select.offsetHeight;
        //         let width = select.offsetWidth - Number(cs.paddingLeft.replace("px","")) - Number(cs.paddingRight.replace("px",""));
                
        //         new_drop.style.minWidth = width + "px";

        //         var offset = cb_select.getRect(select);
        //         cb_select_object.open_offset = { x: offset.x, y : offset.y };

        //         let use_top_offset = offset.y + height

        //         new_drop.style.top = (use_top_offset) + "px";
        //         new_drop.style.left = (offset.x) + "px";

        //         let the_drop = new_drop;

        //         setTimeout(function() {
                                            
        //             //new_drop.style.display = 'block';
        //             the_drop.classList.add("popover_show");
        //             let transition = getComputedStyle(the_drop).transition;
        //             the_drop.style.transition = transition; //Make the transition apply on close. We use the value from popover_show.

        //             //let transition_ms = Number(transition.split(" ")[1].replace("s","")) * 1000;
        //             //setTimeout(function() { //Don't focus before transition is complete, because drop may be off page before open
        //                 the_drop.scrollTo(0,0);
                    
        //                 let searchbox = document.getElementById(cb_select_global.open_id+"$search") as HTMLInputElement;
        //                 if(searchbox) {
        //                     searchbox.value = "";
        //                     searchbox.dispatchEvent(new Event('input', {bubbles:true}));
        //                     searchbox.focus();
        //                 }
        //             //}, transition_ms);

        //             //check if the drop would go out the bottom of the screen, if so pop to the top
        //             let dropHeight = the_drop.offsetHeight;
        //             let screenHeight = window.innerHeight|| document.documentElement.clientHeight || document.body.clientHeight;
        //             if((use_top_offset + dropHeight + height) > screenHeight + window.scrollY) {
        //                 use_top_offset -= dropHeight + height;
        //             }

        //             the_drop.style.top = (use_top_offset) + "px";   
        //             cb_select_global.pop_idx++; 
        //             setTimeout(cb_select_global.check_drop_moved.bind(null, cb_select_global.pop_idx), 100);   
        //         },1);
                
        //     }
        // }
        
    }


    closeSelect() {
        this.popout.closeSelect();
        // let cb_select_global = (<any>window).cb_select_global;
        // let open_drop = document.getElementById(cb_select_global.open_id+"$drop");
        // if(open_drop) {
        //     cb_select_global.cb_selects_by_id[cb_select_global.open_id].multiselectClosed();
        //     cb_select_global.open_id = undefined;
            
        //     //open_drop.style.display = 'none';
        //     open_drop.classList.remove("popover_show");
        //     open_drop.style.removeProperty("transition");

        //     let select = document.getElementById(this.id+"$select") as HTMLElement;
        //     if(select) {
        //         select.focus();
        //     }
        // }
    }



}