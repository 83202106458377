import { Brick } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class CBWebUtil {
	static UnFocus() {
		document.body.click();
		document.querySelector('[tabindex]').focus();
	}

	static focusNextElement(reverse, activeElem) {
		/*check if an element is defined or use activeElement*/
		activeElem = activeElem instanceof HTMLElement ? activeElem : document.activeElement;

		let queryString = [
			'a:not([disabled]):not([tabindex="-1"])',
			'button:not([disabled]):not([tabindex="-1"])',
			'input:not([disabled]):not([tabindex="-1"])',
			'select:not([disabled]):not([tabindex="-1"])',
			'[tabindex]:not([disabled]):not([tabindex="-1"])'
			/* add custom queries here */
		].join(','),
			queryResult = Array.prototype.filter.call(document.querySelectorAll(queryString), elem => {
				/*check for visibility while always include the current activeElement*/
				return elem.offsetWidth > 0 || elem.offsetHeight > 0 || elem === activeElem;
			}),
			indexedList = queryResult.slice().filter(elem => {
				/* filter out all indexes not greater than 0 */
				return elem.tabIndex == -1 ? false : true;
			}).sort((a, b) => {
				/* sort the array by index from smallest to largest */
				return a.tabIndex != 0 && b.tabIndex != 0
					? (a.tabIndex < b.tabIndex ? -1 : b.tabIndex < a.tabIndex ? 1 : 0)
					: a.tabIndex != 0 ? -1 : b.tabIndex != 0 ? 1 : 0;
			}),
			focusable = [].concat(indexedList, queryResult.filter(elem => {
				/* filter out all indexes above 0 */
				return elem.tabIndex == -1 ? true : false;
			}));

		/* if reverse is true return the previous focusable element
		   if reverse is false return the next focusable element */
		return reverse ? (focusable[focusable.indexOf(activeElem) - 1] || focusable[focusable.length - 1])
			: (focusable[focusable.indexOf(activeElem) + 1] || focusable[0]);
	}


	static escapeHtml(unsafe: string) {
		if (unsafe && typeof unsafe == "string") {
			return unsafe
				.replace(/&/g, "&amp;")
				.replace(/</g, "&lt;")
				.replace(/>/g, "&gt;")
				.replace(/"/g, "&quot;")
				.replace(/'/g, "&#039;");
		}
		return unsafe;
	}

	static unescapeHtml(unsafe: string) {
		if (unsafe && typeof unsafe == "string") {
			return unsafe
				.replace(/&amp;/g, "&")
				.replace(/&lt;/g, "<")
				.replace(/&gt;/g, ">")
				.replace(/&quot;/g, '"')
				.replace(/&#039;/g, "'");
		}
		return unsafe;
	}

	static BrickHtml(blueprint: Brick, container_brick: CodeBrick, idx: number, dc = "", classes = "") {
		let prefix = blueprint.type.split('-')[0];
		if (prefix.indexOf('i') != -1 || prefix[0] == 's') {
			classes += " child-hidden";
		}
		let attrs = "";
		if ((<any>window).codebricks_context.visual_editing) {
			attrs = ` class="${classes}" editor-info="${blueprint.name} (${blueprint.type})"`;

			let brick_name = blueprint.name;
			let brick_type = blueprint.type;
			setTimeout(function () { (<any>window).codebricks_context.visual_brick_endow(brick_name, brick_type, true); }, 0);
		}
		else {
			attrs = ` ${classes ? ` class="${classes}"` : ""}`;
		}
		let use_dc = dc || container_brick.dc || '';
		let brick = `<${blueprint.type}${attrs} cid="${container_brick.cid}" dc="${use_dc}" name="${blueprint.name}" idx="${idx}" container_id="${container_brick.brick_id}"></${blueprint.type}>`;
		return brick;
	}

	static async PostRequest(url: string, post_body: any) {
		let res_data: any;

		let method = "POST";

		let options = { method };

		(<any>options).body = JSON.stringify(post_body);

		(<any>options).credentials = 'include';

		(<any>options).headers = { "Content-Type": "application/json" };

		//console.log("web_sc_use "+this.brick_id+" post_body "+JSON.stringify(post_body));

		let res = await fetch(url, options);

		let body = await res.text();
		if (body === "") {
			return; //We do not emit an empty resonse. This gives the server side a way no not emit. Used for request sequencing #rr
		}

		//console.log("web_sc_use "+this.brick_id+" response body "+body);

		try {
			res_data = JSON.parse(body);
		}
		catch (err) {
			res_data = body;
		}
		return res_data;
	}

	static async GetRequest(url: string) {
		let res_data: any;

		let method = "GET";

		let options = { method };

		(<any>options).credentials = 'include';

		(<any>options).headers = { "Content-Type": "application/json" };

		//console.log("web_sc_use "+this.brick_id+" post_body "+JSON.stringify(post_body));

		let res = await fetch(url, options);

		let body = await res.text();
		if (body === "") {
			return; //We do not emit an empty resonse. This gives the server side a way no not emit. Used for request sequencing #rr
		}

		//console.log("web_sc_use "+this.brick_id+" response body "+body);

		try {
			res_data = JSON.parse(body);
		}
		catch (err) {
			res_data = body;
		}
		return res_data;
	}

	static GetElementStylesString(default_style: string, cfg: any, specifier: string, style = "") {
		let ret = "";

		let style_val = null;
		if(cfg) {
			style_val = cfg["styles"];
			if(style_val && specifier) {
				style_val = style_val[specifier];
			}
		}
		else if(!style) {
			return ret;
		}
		let classes = default_style;
		let styles = [] as string[];
		let addedStyles = [];
		if(style_val) {		
			for (let prop in style_val) {
				let val = style_val[prop];
				if (prop == "classes") {
					if (Array.isArray(val)) {
						classes += " " + val.join(" ");
					}
					else if (typeof (val) == "string") {
						classes += " " + val;
					}
				}
				else if(prop[0] == "(") {
					val = val.replaceAll("{","");
					val = val.replaceAll("}","");
					prop = prop.substring(1, prop.length - 1); //replaceAll(")","");
					let pairs = CBWebUtil.SplitByMultipleSeparators(val, ["\n","\r",";"]);
					let css = pairs.join(";") + ";";
					//inline_classes.push(prop + "{" + css +"})");
					addedStyles.push({ selector: prop, styles: css });
				}
				else {
					styles.push(prop+":"+val);
				}
			}	
		}

		

		if(styles.length > 0 || style) {
			//ret += ` style="${style}${styles.join(";")}"`;
			//inline_classes.push(`({${style ? style + ";" : ""}${styles.join(";")}})`)
			addedStyles.push({ selector: "", styles: (style ? style + ";" : "")+styles.join(";") });
		}

		if(addedStyles.length > 0) {
			// if(ret) {
			// 	ret += " ";
			// }
			// ret += ` inline-class="${inline_classes.join(" ")}"`;

			

			//(<any>window).inlineClassProcessor.queueNodeId(id, addedStyles);

			let inline_classes = (<any>window).inlineClassProcessor.rulesToStyles(addedStyles);

			classes += (classes ? " " : "") + inline_classes.join(" ");
		}

		if(classes && classes != " ") {
			ret += ` class="${classes}"`;
		}
		return ret;
	}

	static ApplyElementStyles(element: HTMLElement | null, cfg: any, specifier: string) {
		if(!element || !cfg) {
			return;
		}
		let style_val = cfg["styles"];
		if(style_val && specifier) {
			style_val = style_val[specifier];
		}
		if (!style_val) {
			return;
		}
		let addedStyles = [];

		let styldx = Number(element.getAttribute("styldx"));
		let updating = true;
		if(!styldx) {
			updating = false;
			(<any>window).codebricks_context.styldx = (<any>window).codebricks_context.styldx || 1;
			(<any>window).codebricks_context.styldx_classes = (<any>window).codebricks_context.styldx_classes || {};
			styldx = (<any>window).codebricks_context.styldx++;
			element.setAttribute("styldx", String(styldx));
			(<any>window).codebricks_context.styldx_classes[styldx] = [];

			for(let c = element.classList.length - 1; c >= 0; c--) { //dynamic elements need this
				if(element.classList[c] && element.classList[c].startsWith("inline-class-")) {	
					element.classList.remove(element.classList[c]);
				}
			}
		}

		let current_classes = [];
		let inline_styles = [];
		for (let prop in style_val) {
			let val = style_val[prop];
			if (prop == "classes") {
				if (Array.isArray(val)) {
					for(let cl of val) {
						if(cl && typeof cl == "string") {
							let clt = cl.trim();
							if(clt) {
								current_classes.push(clt);
								element.classList.add(clt);
							}
						}
					}
				}
				else if (typeof (val) == "string") {
					let classes = val.split(" ");
					for(let cl of classes) {
						if(cl) {
							current_classes.push(cl);
							element.classList.add(cl);
						}
					}
				}
			}
			else {
				if(prop[0] == "(") {
					val = val.replaceAll("{","");
					val = val.replaceAll("}","");
					//prop = prop.replaceAll(")","");
					//prop = prop.replaceAll("(","");
					prop = prop.substring(1, prop.length - 1);
					let pairs = CBWebUtil.SplitByMultipleSeparators(val, ["\n","\r",";"]);
					let css = pairs.join(";") + ";";
					//(<any>window).inlineClassProcessor.processNode(element, [{ selector: prop, styles: css }]);
					addedStyles.push({ selector: prop, styles: css });

				}
				else {
					inline_styles.push(`${prop}:${val}`);
				}
			}
		}

		if(inline_styles.length > 0) {
			//(<any>window).inlineClassProcessor.processNode(element, [{ selector: "", styles: inline_styles.join(";") }]);
			addedStyles.push({ selector: "", styles: inline_styles.join(";") });
		}

		if(addedStyles.length > 0) {
			let added_inline_classes = (<any>window).inlineClassProcessor.processNode(element, addedStyles);
			current_classes = current_classes.concat(added_inline_classes)
			//(<any>window).inlineClassProcessor.injectStyles();
			//(<any>window).inlineClassProcessor.queueNode(element, addedStyles);
		}

		if(updating) {
			for(let cl of (<any>window).codebricks_context.styldx_classes[styldx]) {
				if(!current_classes.includes(cl)) {
					element.classList.remove(cl); //This class was previously added here but is not not present so should be removed
				}
			}
		}

		(<any>window).codebricks_context.styldx_classes[styldx] = current_classes;
	}

	static ApplyElementIdStyles(id: string, cfg: any, specifier: string) {
		let element = document.getElementById(id);
		if(element) {
			CBWebUtil.ApplyElementStyles(element, cfg, specifier);
		}
	}

	static SplitByMultipleSeparators(str: string, separators: string[]): string[] {
		if (separators.length === 0) {
			return [str];
		}
	
		const escapedSeparators = separators.map(s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));
		const regex = new RegExp(escapedSeparators.join('|'), 'g');
		
		return str.split(regex).filter(Boolean);
	}
}