import { Clone } from "../codebricks-runtime/CBUtil";

export class GlobalEvent {
    static CreateHandler() {
        if(!(<any>window).ci_global_event_send) {
            (<any>window).ci_global_event_send = async function(event_name: string, event_data: any) {

                (<any>window).ci_global_event_data = (<any>window).ci_global_event_data || {};
                (<any>window).ci_global_event_data[event_name] = Clone(event_data);

                //console.log("ci_global_event_send "+event_name);
                if((<any>window).codebricks_context.global_event_listeners &&
                (<any>window).codebricks_context.global_event_listeners[event_name]) {
                    for(let handler of (<any>window).codebricks_context.global_event_listeners[event_name]) {
                        //console.log("ci_global_event_send call handler " +event_name+" "+JSON.stringify(event_data));
                        await handler(event_data);
                    }
                }
            }
        }
    }

    static async HandleEvent(input: string, cfg: any, owner: any) {
        if(input == "send") {
            if(cfg && cfg.event_name) {

                //console.log("ci-global-event "+this.brick_id+" global-send "+info.source+"->"+ cfg.event_name);

                await (<any>window).ci_global_event_send(cfg.event_name, cfg.event_data);
            }
        }
        else if(input == "listen") {
            if(cfg && Array.isArray(cfg)) {
                (<any>window).codebricks_context.global_event_listeners = (<any>window).codebricks_context.global_event_listeners || {};
                let self = this;
                for(let event_name of cfg) {
                    (<any>window).codebricks_context.global_event_listeners[event_name] = (<any>window).codebricks_context.global_event_listeners[event_name] || [];
                    
                    if(owner.listening[event_name]) {
                        //console.log("ci-global-event already listening to "+ event_name);
                        continue;
                    }

                    //console.log("ci-global-event "+this.brick_id+" register listen "+info.source+"->"+ event_name);
                    let event_name_c = event_name;

                    owner.listening[event_name] = async function(data: any) {
                        let emit_data = {} as any;
                        emit_data["@" + event_name_c] = data;

                        //console.log("ci-global-event "+self.brick_id+" handler_run "+info.source_brick_id+"->"+event_name_c+ " "+JSON.stringify(emit_data));

                        await owner.cb_emit(emit_data);
                    } as any;
                    
                    (<any>window).codebricks_context.global_event_listeners[event_name_c].push(owner.listening[event_name]);

                    //If event has finred, and someone subscribes after that, they must get the event when they subscribe. This solves race conditions.
                    if((<any>window).ci_global_event_data && (<any>window).ci_global_event_data[event_name]) {
                        let emit_data = {} as any;
                        emit_data["@" + event_name_c] = (<any>window).ci_global_event_data[event_name];

                        return emit_data;
                    }
                }
            }
        }
    }
}