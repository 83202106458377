import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_popout } from "../controls/cb_popout";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_popout_webcomponent extends HTMLElement {
    ci: web_cc_popout | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_popout(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-popout', cc_popout_webcomponent);

export class web_cc_popout extends CodeBrick {

    element: HTMLElement;
    handler: any;
    cement_classes = [] as string[];
    initialized = false;
    cement_html = "";

    popout: cb_popout;

    style_classes = [] as string[];

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        //this.render(this.blueprint.cfg["display"], null);

        this.element.innerHTML = `<div id="${this.brick_id}$select" class="cc-popout hidden"></div>`;

        this.popout = new cb_popout(this.brick_id, true, this.blueprint.ins.cfg, "cc-popout", false, true,
        function() {
            //pop opened
        },
        function() {
        });

        this.popout.init();

        this.init_cement();
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {


        let innerHTML = "";

        innerHTML += "";
        if(this.blueprint.contains) {
            let  i = 0;
            for(let sub of this.blueprint.contains) {
                let cement = cements[i];

                // let lop_style = "";
                // if(cement && cement.style) {
                //     for(let v in cement.style) {
                //         lop_style += v+':'+cement.style[v]+";"
                //     }
                // }
                let classes = '';
                // if(cement && cement.style_classes) {
                //     for(let cl in cement.style_classes) {
                //         classes += " " + cement.style_classes[cl];
                //     }
                // }

                if(cement && cement.hidden) {
                    classes += classes ? " hidden" : "hidden";
                }
                
                let brick = CBWebUtil.BrickHtml(sub, this, i);

                innerHTML += `<div id="${this.brick_id}$${i}" ${CBWebUtil.GetElementStylesString("cc-popout-item "+classes, cement, "")}>${brick}</div>`;

                i++;
            }
        }

        //if(this.initialized) {
        
        let container = document.getElementById(`${this.brick_id}$drop`);
        if(container) {
            container.innerHTML = innerHTML;

        }



    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
        
        let child = document.getElementById(this.brick_id+"$"+child_idx);

        if(child && cement) {
            if(cement && cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        //     if(cement.style_classes) {
        //         let new_cement_classes = [];
        //         for(let c of cement.style_classes) {
        //             c = (<string>c).trim();
        //             if(c) {
        //                 let cs = c.split(" ");
        //                 for(let s of cs) {
        //                     child.classList.add(s);
        //                     new_cement_classes.push(s);
        //                 }
        //             }
        //         }
        //         //Remove the classes that are were in the cement but are now removed
        //         for(let c of this.cement_classes) {
        //             if(new_cement_classes.indexOf(c) == -1) {
        //                 child.classList.remove(c);
        //             }
        //         }
        //         this.cement_classes = new_cement_classes;
        //     }
            CBWebUtil.ApplyElementStyles(child, cement, "");
        }
        
      
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        if(input == "show") {
            if(cfg) {
                //console.log("cc-popout show="+JSON.stringify(cfg));
                //this.handleClick();
                this.popout.openPopout(this.popout);
            }
        }
        else if(input == "collapse") {
            if(cfg) {
                //console.log("cc-popout show="+JSON.stringify(cfg));
                //this.handleClick();
                this.popout.closeSelect();
            }
        }
        else if(input == "cfg") {

            if(!this.initialized) {

                      
                let html = "";

                if(cfg.icon_left) {
                    if(cfg.icon_left[0] == "<") {
                        html += cfg.icon_left;
                    }
                    else {
                        html += `<img class="cc-popout-icon-left" src="${cfg.icon_left}"/>`;
                    }
                }
                if(cfg.label) {
                    html += '<div class="cc-popout-label">' + cfg.label + '</div>';
                }
                if(cfg.icon_right) {
                    if(cfg.icon_right[0] == "<") {
                        html += cfg.icon_right;
                    }
                    else {
                        html += `<img class="cc-popout-icon-right" src="${cfg.icon_right}"/>`;
                    }
                }

                //html += `<div class="cc-popout-popout" id="${this.brick_id}$drop"></div>`;

                //html += "</div>";
                let select = document.getElementById(this.brick_id+"$select");
                if(select) {
                    select.innerHTML = html;

                    CBWebUtil.ApplyElementStyles(select, cfg, "label");
                }
                this.style_classes = cfg.style_classes;

                let popout = document.getElementById(this.brick_id+"$drop");  
                if(popout) {   
                    CBWebUtil.ApplyElementStyles(popout, cfg, "popover");
                }

                this.initialized = true;
            }
            else {
                //TODO
                let select = document.getElementById(this.brick_id+"$select");
                if(select) {

                    if(cfg.style_classes) {
                        for(let cl of cfg.style_classes) {
                            select.classList.add(cl);
                        }
                        let classes = cfg.style_classes;
                        for(let had of this.style_classes) {
                            let has = false;
                            for(let v of classes) {
                                if(v == had) {
                                    has = true;
                                    break;
                                }
                            }
                            if(!has && had) {
                                select.classList.remove(had);
                            }
                        }
                        this.style_classes = cfg.style_classes;
                    }
                }
            }

            this.popout.setNoWindowClickClose(Boolean(cfg.no_window_click_close));

            let launcher = document.getElementById(this.brick_id+"$select"); 
            if(launcher) {
                if(cfg.hidden) {
                    launcher.classList.add("hidden");
                }
                else {
                    launcher.classList.remove("hidden");
                }
            }
            
        }
    }

    pop_hide() {

        //console.log("pop_hide"+this.ci_idx);

        let menu = document.getElementById(this.brick_id+"$popout");
        if(menu) {
            menu.classList.remove("cc-popout-show");
        }
    }

    handleClick = () => {
        let showing = false;
        let menu = document.getElementById(this.brick_id+"$popout");
        if (menu?.classList.contains("cc-popout-show")) {
            showing = true;
        }

        if(showing) {
            menu?.classList.remove("cc-popout-show");
        }
        else{
            menu?.classList.add("cc-popout-show"); 

            let self = this;
            if(!this.handler) {

                this.handler = function() {
                    let menu = document.getElementById(self.brick_id+"$popout");
                    menu?.classList.remove("cc-popout-show");
                    document.removeEventListener('click', self.handler);
                }
            }

            setTimeout(function() {
                document.addEventListener('click', self.handler);
            }, 1)

        }
    };

    cb_status(status: string): void {
    }
    cb_snapshot() {}
}


