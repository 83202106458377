import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_hyperlink_webcomponent extends HTMLElement {
    ci: web_c_hyperlink | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_hyperlink(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-hyperlink', c_hyperlink_webcomponent);

export class web_c_hyperlink extends CodeBrick {

    element: HTMLElement;
    link_comp = "";
    querystring="";
    new_tab = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        if(this.context.platform == "file") {
            this.element.innerHTML = `<span id="${this.brick_id}" class="c-hyperlink hidden"></span>`;
        }
        else {
            this.element.innerHTML = `<a id="${this.brick_id}" class="c-hyperlink hidden"></a>`;
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        //console.log("c-hyperlink "+this.brick_id+" cb_event "+input+" "+JSON.stringify(cfg));

        if(input == "cfg") {

            let disp = cfg.label;
            if(cfg.icon && cfg.icon.url) {
                if(cfg.icon.url[0] == "<") {
                    if(cfg.icon.placement == 'right') {
                        disp = `<div ${CBWebUtil.GetElementStylesString("", cfg, "icon")}>${cfg.label}${cfg.icon.url || ""}</div>`;
                    }
                    else {
                        disp = `<div ${CBWebUtil.GetElementStylesString("", cfg, "icon")}>${cfg.icon.url}${cfg.label || ""}</div>`;
                    }
                }
                else {
                    if(cfg.icon.placement == 'right') {
                        disp = `<div>${cfg.label}<img ${CBWebUtil.GetElementStylesString("c-hyperlink-icon-right", cfg, "icon")} src="${cfg.icon.url}"/></div>`;
                    }
                    else {
                        disp = `<div><img ${CBWebUtil.GetElementStylesString("c-hyperlink-icon-left", cfg, "icon")} src="${cfg.icon.url}"/>${cfg.label || ""}</div>`;
                    }
                }
            }

            // let target = '';
            // if(cfg.new_tab) {
            //     target = "target='_blank'";
            //     this.new_tab = true;
            // }

            // let download = '';
            // if(cfg.download) {
            //     if(cfg.download === true) {
            //         download = " download";
            //     }
            //     else {
            //         download = ` download="${cfg.download}"`;
            //     } 
            // }

            this.querystring = "";
            let p_count = 0;
            if(cfg.parameters) {
                if(typeof cfg.parameters == 'object') {
                    for(let k in cfg.parameters) {
                        if(p_count == 0) {
                            this.querystring = "?";
                        }
                        else {
                            this.querystring += "&";
                        }
                        this.querystring += k + "="+encodeURIComponent(cfg.parameters[k]);
                        p_count++;
                    }
                }
            }

            this.link_comp = cfg.target || '';

            let prefix = "/";
            if(this.link_comp[0] == "/" || this.link_comp.indexOf("://") != -1) {
                prefix = "";
            }

            //if(cfg.label || (cfg.icon && cfg.icon.url)) {
                //this.element.innerHTML = `<a id="${this.brick_id}" class="c-hyperlink ${classes}" style="${style}" href="${prefix}${this.link_comp}${this.querystring}" ${target}${download}>${disp}</a>`;

                let element = document.getElementById(this.brick_id);
                if(element) {

                    if(cfg.tooltip && cfg.tooltip.text) {
                        element.setAttribute("tooltip", cfg.tooltip.text);
                        cb_tooltip.set_tooltip(this.element);
                    }

                    if(cfg.hidden) {
                        element.classList.add("hidden");
                    }
                    else {
                        element.classList.remove("hidden");
                    }

                    if(cfg.new_tab) {
                        element.setAttribute("target", "_blank");
                        this.new_tab = true;
                    }

                    if(cfg.download) {
                        if(cfg.download === true) {
                            element.setAttribute("download", "");
                        }
                        else {
                            element.setAttribute("download", cfg.download);
                        } 
                    }
                    else {
                        element.removeAttribute("download");
                    }

                    element.setAttribute("href", prefix + this.link_comp + this.querystring);

                    element.innerHTML = disp;

                    CBWebUtil.ApplyElementStyles(element, cfg, "hyperlink");
                }
            //}

            
            
        }

        if(input == "trigger" && cfg && JSON.stringify(cfg) != "{}") {
            //console.log("c-hyperlink trigger cfg="+JSON.stringify(cfg)+" comp="+this.link_comp);       
            let prefix = "/";
            if(this.link_comp.indexOf("://") != -1) {
                prefix = "";
            }
            let url = `${prefix}${this.link_comp}${this.querystring}`;
            if(this.new_tab) {
                (<any>window).open(url);               
            }
            else {
                (<any>window).location = url;
            }
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }

    cb_snapshot() {}
}

