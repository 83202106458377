import { Brick } from "../codebricks-runtime/CBModels";
import { ObjectDeepMerge } from "../codebricks-runtime/CBUtil";

export class ExportFuncs {
    static ApplyBrickSnapshots(brick: Brick, snapshots: any) {
        if(snapshots[brick.name]) {
            console.log(brick.name+" has snapshot "+JSON.stringify(snapshots[brick.name]));// + " brick.ins "+JSON.stringify(brick.ins));
            ObjectDeepMerge(brick.ins, snapshots[brick.name]);
        }
        if(brick.contains) {
            for(let c of brick.contains) {
                ExportFuncs.ApplyBrickSnapshots(c, snapshots);
            }
        }    
    }
}