import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_image_webcomponent extends HTMLElement {
    ci: web_c_image | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_image(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-image', c_image_webcomponent);

export class web_c_image extends CodeBrick {

    element: HTMLElement;

    listener = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        let wh = "";
        if(cfg.width || cfg.width === 0) {
            if(!isNaN(cfg.width)) {
                wh += ` width="${cfg.width}px"`;
            }
            else {
                wh += ` width="${cfg.width}"`;
            }
            
        }
        if(cfg.height || cfg.height === 0) {
            if(!isNaN(cfg.width)) {
                wh += ` height="${cfg.height}px"`;
            }
            else {
                wh += ` height="${cfg.height}"`;
            }  
        }

        if(cfg.src) {
            this.element.innerHTML = `<img id="${this.brick_id}" src="${cfg.src}" alt="${cfg.alt || ""}"${wh} ${CBWebUtil.GetElementStylesString("c-image", cfg, "container")}/>`;
        }
        else {
            this.element.innerHTML = '';
        }


        let btn = document.getElementById(`${this.brick_id}`);
        if(btn && !this.listener) {
            this.listener = true;
            let self = this;
            btn.addEventListener('click', function() {
                self.cb_emit({"@":self.blueprint.name});
            });
            
        }
   
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}
}


