import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { Select } from "../../shared-funcs/Select";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_rangeslider_webcomponent extends HTMLElement {
    ci: web_c_rangeslider | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_rangeslider(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-rangeslider', c_rangeslider_webcomponent);

export class web_c_rangeslider extends CodeBrick {

    element: HTMLElement;
    options = [] as any[];
    value: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.element.innerHTML = `<div class="form-element-container c-rangeslider-container" id="${this.brick_id}$container">
                
                </div>`;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksrangeslider "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));

        if(input == 'value') {
            this.value = cfg;
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(input) {
                input.value = this.value;
                return {"@": this.value };
            }
        }
        else if(input == 'cfg') {
            this.options = Select.getOptions(cfg, this.blueprint);

            let options_html = "";
            if(this.options) {
                for(let option of this.options) {
                    options_html += `<option value="${option.value}" label="${option.label}"></option>`;
                }
            }

            if(options_html) {
                options_html = `<datalist id="${this.brick_id}$datalist" class="c-rangelsider-datalist">${options_html}</datalist>`;
            }

            let val = cfg.value !== undefined? `value="${cfg.value}"` : "";

            let html = `<label class="form-element-label c-rangeslider-label" id="${this.brick_id}$label" for="${this.brick_id}"></label>
                <input ${val} class="c-rangeslider" type="range" min="${cfg.min}" max="${cfg.max}" step=${cfg.step} id="${this.brick_id}" list="${this.brick_id}$list" />${options_html}`;

            let container = document.getElementById(this.brick_id + "$container");
            if(container) {
                container.innerHTML = html;
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
            }

            let input = document.getElementById(this.brick_id) as HTMLInputElement;
            if(input) {
                this.value = input.value;

                let self = this;
                input.addEventListener("input", async function() {
                    self.value = input.value;
                    await self.cb_emit({"@": self.value, "@user_changed" : self.value });
                });

                CBWebUtil.ApplyElementStyles(input, cfg, "slider");
            } 

            return { "@": this.value };
        }

    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): any {
    }

    cb_snapshot() {
        return { value: this.value };
    }
}
