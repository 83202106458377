import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_html_webcomponent extends HTMLElement {
    ci: web_c_html | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_html(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-html', c_html_webcomponent);

export class web_c_html extends CodeBrick {

    element: HTMLElement;
    html = "";
    no_loader = false;

    debug_waiting = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

        if(this.debug_waiting) {
            return;
        }

        //console.log("CodeBricksHTML "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));
        if(input == 'cfg') {
            // let container_style = "";
            // if(cfg.style) {
            //     for(let v in cfg.style) {
            //         container_style += v+':'+cfg.style[v]+";"
            //     }
            // }

            // let classes = '';
            // if(cfg.style_classes) {
            //     for(let cl in cfg.style_classes) {
            //         classes += " " + cfg.style_classes[cl];
            //     }
            // }

            let hidden = '';
            if(cfg.hidden || cfg.hide) {
                hidden = "hidden"
            }

            this.element.innerHTML = `<div id="${this.brick_id}" ${CBWebUtil.GetElementStylesString(hidden, cfg, "container")}>${this.html}</div>`;
            
            let btn = document.getElementById(this.brick_id);
            if(btn && this.blueprint.targets) {
                let self = this;

                btn.addEventListener('click', function() {
                    self.cb_emit({"@":self.blueprint.name});
                });

            }
            
            this.no_loader = cfg.no_loader;
        }
        else if(input == "html") {

            if(cfg === null || cfg === undefined) {
                this.html = "";
            }
            else {
                this.html = cfg;
            }

            this.setContent(this.html); 
            
        }  
        cb_tooltip.set_tooltips(this.element);
    }
    setContent(html: string, set_html_var = true) {
        let container = document.getElementById(this.brick_id);
        if(container) {
            container.innerHTML = html;
        }
        else {
            this.element.innerHTML = `<div id="${this.brick_id}">${html}</div>`;
            container = document.getElementById(this.brick_id);
        }   

        if(container && set_html_var) {
            this.html = (<any>window).inlineClassProcessor.processContainer(container);
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            if(status == "loading" && !this.no_loader) {
                
                this.render_skeleton();
            }
            else {
                this.setContent(this.html || "");  
            }
        }
        cb_tooltip.set_tooltips(this.element);
    }

    render_skeleton() {

        let skeleton_html = this.createHandlebarsSkeletonLoader(this.blueprint.ins.html);//this.stripHandlebarsTags(this.blueprint.ins.html);

        this.setContent(skeleton_html, false);

        //this.debug_waiting = true;
    }

    createHandlebarsSkeletonLoader(template: string): string {
        // Regular expression to match Handlebars tags
        const handlebarTagsRegex = /\{\{.*?\}\}/g;
        
        // Split the template into parts: text and Handlebars tags
        const parts = template.split(handlebarTagsRegex);
        
        // Function to create a skeleton block
        const createSkeletonBlock = (text: string): string => {
            // const words = text.trim().split(/\s+/);
            // return words.map(word => {
            //     const length = Math.max(Math.min(word.length, 10), 0); // Min 2, max 10 characters
            //     if(length == 0) {
            //         return "";
            //     }
            //     return `<span class="html-skeleton-block" style="width: ${length}ch;"></span>`;
            // }).join(' ');

            const length = Math.max(Math.min(text.length, 10), 0); // Min 2, max 10 characters
            if(length == 0) {
                return "";
            }
            return `<span class="html-skeleton-block" style="width: ${length}ch;"></span> `;
        };
        
        // Process each part
        const processedParts = parts.map((part, index) => {
            //if (index % 2 === 0) {
                // Text part: replace with skeleton blocks
                return createSkeletonBlock(part);
            //} else {
                // Handlebars tag: keep as is
            //    return `{{${part}}}`;
            //}
        });
        
        // Join the processed parts
        const skeletonHtml = processedParts.join('<span class="html-skeleton-block" style="width: 2ch;"></span>');
        
        // Wrap the result in a div with skeleton loader styles
        return `
            <div class="html-skeleton-loader">
                ${skeletonHtml}
            </div>
        `;
    }

    createStructuredHandlebarsSkeletonLoader(template: string): string {
        // Regular expression to match Handlebars tags
        const handlebarTagsRegex = /\{\{.*?\}\}/g;
        
        // Function to create a skeleton block
        const createSkeletonBlock = (text: string): string => {
            const words = text.trim().split(/\s+/);
            return words.map(word => {
                const length = Math.max(Math.min(word.length, 10), 0); // Min 2, max 10 characters
                let block = "";
                for(let i = 0; i < length; i++) {
                    block += "&#9608;";
                }
                if(length == 0) {
                    return "";
                }
                return `<span class="html-skeleton-block" style="width:${length}ch">&nbsp;</span>`; //block; //word.length ? "&#9608;&#9608;&#9608;" : ""; //word.length ? "&#9608" : ""; //"X";//block; //word.length ? "&#9608" : ""; //`<span class="skeleton-block" style="">X</span>`;
            }).join(' ');
        };
        
        // Function to process text nodes
        const processTextNode = (node: Node): void => {
            if (node.nodeType === Node.TEXT_NODE && node.textContent) {
                const skeletonContent = createSkeletonBlock(node.textContent);
                const tempDiv = document.createElement('span');
                tempDiv.innerHTML = skeletonContent;
                if (tempDiv.firstChild) {
                    node.parentNode?.insertBefore(tempDiv.firstChild, node);
                }
                node.parentNode?.removeChild(node);
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                Array.from(node.childNodes).forEach(processTextNode);
            }
        };

        template = template.replace(handlebarTagsRegex, "...");
    
        // Parse the HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(template, 'text/html');
    
        // Process all text nodes
        processTextNode(doc.body);
    
        // Replace Handlebars tags
        const content = doc.body.innerHTML;
        const processedContent = content ; //content.replace(handlebarTagsRegex, match => "&#9608;");
    
        console.log(template+" -> "+processedContent);

        // Wrap the result in a div with skeleton loader styles and animation
        return `
            <style>
                @keyframes pulse {
                    0% { opacity: 0.6; }
                    50% { opacity: 1; }
                    100% { opacity: 0.6; }
                }
                .skeleton-loader * {
                    color: #e0e0e0 !important;
                    border-radius: 4px;
                    animation: pulse 1.5s ease-in-out infinite;
                }
            </style>
            <div class="skeleton-loader">
                ${processedContent}
            </div>
        `;
    }

    cb_snapshot() {}
}