import { Brick, CBContext, CBEventInfo, Composition, InsLeaves, TagItem } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { LeafParser } from "../../codebricks-runtime/LeafParser";
import { TemplateUtil } from "../../codebricks-runtime/TemplateUtil";

export class c_brick_linker_webcomponent extends HTMLElement {
    ci: web_c_brick_linker | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_brick_linker(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-brick-linker', c_brick_linker_webcomponent);

interface Link {
    source_name: string,
    output: string,
    output_path: string,
    dest_name: string,
    input: string,
    input_path: string,   
    idx: number,
    color: string;
}

export class web_c_brick_linker extends CodeBrick {

    element: HTMLElement;
    initialized = false;
    compos: Composition | undefined;
    component_specs = {} as { [name: string] : any }[];

    bricks_by_name = {} as { [name:string] : Brick };

    lines = [] as any[];

    links_by_source = {} as { [name:string]: Link[] };
    links_by_dest = {} as { [name:string]: Link[] };
    link_idx = 0;

    active_links = [] as any[];

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;  

        (<any>window).open_brick_modal = function(brick_name: string) {
            let tree_container = document.getElementById("cb_0_brick_tree-component_tree_container");
            if(tree_container) {
                let brick_button = tree_container.querySelector(`[label="${brick_name}"]`) as HTMLButtonElement;
                if(brick_button) {
                    brick_button.click();
                }
            }
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        if(input == "cfg") {

            // if(!(<any>window).leader_line_loaded) {
            //     await new Promise((resolve, reject) => {
            //         let js = document.createElement("script"); 
            //         js.src = "/static/leader-line.min.js"; 
            //         js.onload = resolve; 
            //         js.onerror = reject; 
            //         document.body.appendChild(js)
            //       });
        
            //       await new Promise((resolve, reject) => {
            //         let js = document.createElement("script"); 
            //         js.src = "/static/anim-event.min.js"; 
            //         js.onload = resolve; 
            //         js.onerror = reject; 
            //         document.body.appendChild(js)
            //       });

            //       (<any>window).leader_line_loaded = true;
            // }

            this.compos = cfg.composition_tree;
            for(let cs of cfg.component_specs) {
                this.component_specs[cs.name] = cs.spec;
            }

            this.register_brick_by_name(this.compos as Brick);

            if(this.compos) {

                this.link_idx = 0;
                this.links_by_source = {};
                this.links_by_dest = {};
                

                let html = this.draw_composition(cfg.composition_tree);
                this.element.innerHTML = html;

                //this.draw_links(this.compos.name);
            }
        }
        else if(input == "clear_lines") {
            this.DeleteAllLines();
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
        
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}

    draw_composition(composition: Composition ) {

        if(!this.compos) {
            return "";
        }
        this.active_links = [];
        this.extract_links(this.compos.name);

        let html;
        html = this.draw_recurse(composition, true, 0);

        //console.log("html "+html);

        return html;
    }

    register_brick_by_name(brick: Brick) {

        brick.parsed_ins = {} as InsLeaves;
        brick.parsed_ins = LeafParser.Parse(brick.ins);

        this.bricks_by_name[brick.name] = brick;
        if(brick.contains) {
            for(let child of brick.contains) {
                this.register_brick_by_name(child);
            }
        }
    }

    // draw_recurse0(brick: Brick, alternate: boolean) {
    //     let ret = "";

    //     ret += `<div class="linker-brick${alternate ? " linker-brick-alternate":""}" id="linker_brick_${brick.name}">
    //     <div class="linker-brick-inputs">`;
    //     ret+= `</div>`;
    //     ret += `<div class="linker-brick-heading">${brick.name}<br /><span>${brick.type}</span></div><div class="linker-contains">`
    //     if(brick.contains) {
    //         for(let child of brick.contains) {
    //             ret += this.draw_recurse(child, !alternate, 0);
    //         }
    //     }
    //     ret += `</div><div class="linker-brick-outputs">`;
    //     ret += `</div></div>`;

    //     return ret;
    // }

    draw_recurse(brick: Brick, alternate: boolean, level: number) {
        let ret = "";

        let indent_px = 10;

        ret += `<div class="linker-row" onclick="open_brick_modal('${brick.name}')">`;
        ret += `<div class="linker-element" style="padding-left:${indent_px * level}px"><div class="linker-brick-heading">${brick.name}<span> (${brick.type})</span></div></div>`;
    
        ret += this.get_active_links_tds(null, null, false)+`</div>`;

        if(this.links_by_source[brick.name]) {
            for(let link of this.links_by_source[brick.name]) {

                if(!link.dest_name) {
                    continue;
                }

                let remove = false;
                if(this.active_links[link.idx] == null) {
                    this.active_links[link.idx] = link;
                }
                else {
                    remove = true;
                }

                ret += `<div class="linker-row" onclick="open_brick_modal('${link.dest_name}')"><div class="linker-element" style="padding-left:${indent_px * (level+2)}px"><div class="linker-brick-out">${link.output}${link.output_path}</div>`;

                ret += `<div class="linker-arrow-right"></div><div class="linker-hline" style="background-color:${link.color}"></div>`;

                ret += `</div>` + this.get_active_links_tds(link, null, remove) + `</div>`;

                if(remove) {
                    this.active_links[link.idx] =  null;
                }

            }
        }

        if(this.links_by_dest[brick.name]) {
            for(let link of this.links_by_dest[brick.name]) {

                if(!link.source_name) {
                    continue;
                }

                let remove = false;
                if(this.active_links[link.idx] == null) {
                    this.active_links[link.idx] = link;
                }
                else {
                    remove = true;
                }

                ret += `<div class="linker-row" onclick="open_brick_modal('${link.dest_name}')"><div class="linker-element" style="padding-left:${indent_px * (level+2)}px"><div class="linker-brick-in">${link.input}${link.input_path}</div>`;

                ret += `<div class="linker-arrow-left" ></div><div class="linker-hline" style="background-color:${link.color}"></div>`;

                ret += `</div>` + this.get_active_links_tds(null, link, remove) + `</div>`;

                if(remove) {
                    this.active_links[link.idx] =  null;
                }

            }
        }

        if(brick.contains) {
            for(let child of brick.contains) {
                ret += this.draw_recurse(child, !alternate, level + 1);
            }
        }

        return ret;
    }

    get_active_links_tds(source: Link | null, dest: Link | null, remove: boolean) {
        let ret = "";

        let source_end = 0;
        let source_color = "blue";
        if(source) {
            for(let l = 0; l < this.active_links.length; l++) {
                let link = this.active_links[l];
                if(link && link == source) {
                    source_end = l;
                    source_color = link.color;
                }
            }
        }

        let dest_end = 0;
        let dest_color = "blue";
        if(dest) {
            for(let l = 0; l < this.active_links.length; l++) {
                let link = this.active_links[l];
                if(link && link == dest) {
                    dest_end = l;
                    dest_color = link.color;
                }
            }
        }

        for(let l = 0; l < this.active_links.length; l++) {
            let link = this.active_links[l];
            if(link) {
                if(l < source_end) {
                    ret += `<div class="linker-v" style="border-color:${link.color}"><div class="linker-h" style="background-color:${source_color}"></div></div>`;
                }
                else if(l > 0 && (l == source_end || l == dest_end)) {
                    if(remove) {
                        ret += `<div class="linker-u" style="border-color:${link.color}"></div>`;
                    }
                    else {
                        ret += `<div class="linker-d" style="border-color:${link.color}"></div>`;
                    }
                }
                else if(l < dest_end) {
                    ret += `<div class="linker-v" style="border-color:${link.color}"><div class="linker-h" style="background-color:${dest_color}"></div></div>`;
                }
                else {
                    ret += `<div class="linker-v" style="border-color:${link.color}"></div>`;
                }
            }
            else {
                if(l < source_end) {
                    ret += `<div class="linker-connection"><div class="linker-h" style="background-color:${source_color}"></div></div>`;
                }
                else if(l < 0 && (l == source_end || l == dest_end)) {
                    if(remove) {
                        ret += `<div class="linker-u" style="border-color:${link.color}"></div>`;
                    }
                    else {
                        ret += `<div class="linker-d" style="border-color:${link.color}"></div>`;
                    }
                }
                else if(l < dest_end) {
                    ret += `<div class="linker-connection"><div class="linker-h" style="background-color:${dest_color}"></div></div>`;
                }
                else {
                    ret += `<div class="linker-connection"></div>`;
                }
            }
        }
        return ret;
    }

    extract_links(name: string) {

        let blueprint = this.bricks_by_name[name];

        let links = [] as { ti: TagItem, input: string, path: string[] }[];
        if(blueprint.parsed_ins && blueprint.parsed_ins) {
            for(let input in blueprint.parsed_ins) {
                for(let leaf of blueprint.parsed_ins[input].leaves) {
                    if(leaf.parts) {
                        for(let part of leaf.parts) {
                            if(part.tag && part.tag.items) {
                                for(let item of part.tag.items) {
                                    this.get_source_tagitems(item, links, input, leaf.path);
                                }
                            }
                        }
                    }
                }
            }
        }

        let dest_name = name;

        for(let link of links) {

            let term_parts = TemplateUtil.GetTermParts(link.ti.slice);
            let source_name = term_parts[0];
            let output = "@";
            let drill_start = 1;

            if(source_name[0] == "$") {
                continue;
            }
            
            if(term_parts.length > 1 && term_parts[1][0] == "@") {
                output = term_parts[0];
                drill_start = 2;
            }
            let drill = "";
            for(let d = drill_start; d < term_parts.length; d++) {
                drill += "." + term_parts[d];
            }

            let path_str = "";
            if(link.path.length > 0) {
                path_str = "." + link.path.join(".");
            }

            //console.log(source_name + "." + output + drill +" -> "+dest_name+path_str);
            //let input_id = "link_in."+dest_name+"."+link.input;
            //let output_id = "link_out."+source_name+"."+output;

            let link_obj = {
                source_name: source_name,
                output: output,
                output_path: drill,
                dest_name: dest_name,
                input: link.input,
                input_path: path_str,
                idx: ++this.link_idx,
                color: this.generateColor(this.link_idx)
            } as Link;

            this.links_by_source[source_name] = this.links_by_source[source_name] || [];
            this.links_by_source[source_name].push(link_obj);

            this.links_by_dest[dest_name] = this.links_by_dest[dest_name] || [];
            this.links_by_dest[dest_name].push(link_obj);

            this.active_links.push(null);

        //     let source_brick_element = document.getElementById("linker_brick_"+source_name);
        //     let dest_brick_element = document.getElementById("linker_brick_"+dest_name);
        //     if(source_brick_element && dest_brick_element) {
        //         let outputs_container = source_brick_element.lastElementChild;
        //         let inputs_container = dest_brick_element.firstElementChild;
        //         if(outputs_container && inputs_container) {
        //             let output_element = document.getElementById(output_id);
        //             let input_element = document.getElementById(input_id);
                    
        //             if(!output_element) {
        //                 outputs_container.insertAdjacentHTML("beforeend", `<div class="linker-brick-output" id="${output_id}">${output == "@" ? "" : output}</div>`);
        //                 output_element = document.getElementById(output_id);
        //             }
        //             if(!input_element) {
        //                 inputs_container.insertAdjacentHTML("beforeend", `<div class="linker-brick-input" id="${input_id}">${link.input}</div>`);
        //                 input_element = document.getElementById(input_id);
        //             }
                    
        //             if(output_element && input_element) {
        //                 this.DrawLine(output_element, input_element, { 
        //                     color: "#0051a7", //this.generateRandomColor(),//"#0071eb", 
        //                     path: "straight", 
        //                     size: 2 
        //                 });
        //             }
        //         }
        //     }
        }
       
        if(blueprint.contains) {
            for(let child of blueprint.contains) {
                this.extract_links(child.name);
            }
        }  
    }

    get_source_tagitems(ti: TagItem, links: {ti: TagItem, input: string, path: string[]}[], input: string,  path: string[]) {
        if(ti.type == "p") {
            links.push({ ti, input, path }); 
        }
        if(ti.type == "{" || ti.type == ",") {
            if(ti.items) {
                for(let c of ti.items) {
                    this.get_source_tagitems(c, links, input, path);
                }
            }
        }
    }

    DrawLine(from: HTMLElement, to: HTMLElement, options: any) {
        // //@ts-expect-error
        // let line = new LeaderLine(from, to, options);

        // let container = document.querySelector(".brick-linker-container");
        // if(container) {
        //     //@ts-expect-error
        //     container.addEventListener('scroll', AnimEvent.add(function() {
        //         try {
        //             line.position();
        //         }
        //         catch(err) {}
        //     }), false);
        // }

        // // let scroll_elems = this.get_Scrolls() as HTMLElement[];
        // // for(let s = 0; s < scroll_elems.length; s++) {
        // //   if(scroll_elems[s].id.startsWith("cb_1") && !scroll_elems[s].id.endsWith("$items")) {
        // //       //@ts-expect-error
        // //       scroll_elems[s].addEventListener('scroll', AnimEvent.add(function() {
        // //           line.position();
        // //         }), false);
        // //   }
        // // }
        // this.lines.push(line);
        // return line;
    }

    DeleteAllLines() {
        for(let line of this.lines) {
            line.remove();
        }
        this.lines = [];
    }

    get_Scrolls = (function() {
        //@ts-expect-error
        var getComputedStyle = document.body && document.body.currentStyle ? function(elem) {
            return elem.currentStyle;
            //@ts-expect-error
        } : function(elem) {
            //@ts-expect-error
            return document.defaultView.getComputedStyle(elem, null);
        };
    
        function getActualCss(elem: any, style: any) {
            return getComputedStyle(elem)[style];
        }
    
        function isXScrollable(elem: any) {
            return elem.offsetWidth < elem.scrollWidth &&
                autoOrScroll(getActualCss(elem, 'overflow-x'));
        }
    
        function isYScrollable(elem: any) {
            return elem.offsetHeight < elem.scrollHeight &&
                autoOrScroll(getActualCss(elem, 'overflow-y'));
        }
    
        function autoOrScroll(text: any) {
            return text == 'scroll' || text == 'auto';
        }
    
        function hasScroller(elem: any) {
            return isYScrollable(elem) || isXScrollable(elem);
        }
        return function ElemenetsWithScrolls() {
            return [].filter.call(document.querySelectorAll('*'), hasScroller);
        };
    })();

    generateColor(index: number) {
        // Ensure the index is within the range 0-19
        index = index % 20;
      
        // Calculate hue based on the golden ratio
        const hue = (index * 137.508) % 360;
      
        // Use high saturation and medium lightness for vibrant, distinguishable colors
        const saturation = 85;
        const lightness = 60;
      
        // Convert HSL to RGB
        const chroma = (1 - Math.abs(2 * lightness / 100 - 1)) * saturation / 100;
        const huePrime = hue / 60;
        const x = chroma * (1 - Math.abs(huePrime % 2 - 1));
        const m = lightness / 100 - chroma / 2;
      
        let r, g, b;
        if (huePrime >= 0 && huePrime < 1) { [r, g, b] = [chroma, x, 0]; }
        else if (huePrime >= 1 && huePrime < 2) { [r, g, b] = [x, chroma, 0]; }
        else if (huePrime >= 2 && huePrime < 3) { [r, g, b] = [0, chroma, x]; }
        else if (huePrime >= 3 && huePrime < 4) { [r, g, b] = [0, x, chroma]; }
        else if (huePrime >= 4 && huePrime < 5) { [r, g, b] = [x, 0, chroma]; }
        else { [r, g, b] = [chroma, 0, x]; }
      
        // Convert RGB to hex
        const toHex = (c:any) => Math.round((c + m) * 255).toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    }

}