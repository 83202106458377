import { SyntaxNode } from "@lezer/common";

interface State {
    line: string, 
    node: string, 
    parent?: State
}

export class TagElementPredictor {
    
    grammar: NodeGrammar;
    nodes: { [name:string] : {[child:string]: string[] } };
    values : {
        [name: string] : string[]
    }

    //This is a list because the parsing may temporarily split between paths
    current_states: State[];

    constructor(grammar: NodeGrammar) {
        this.grammar = grammar;
        this.nodes = this.grammar.grammar.nodes;
        this.values = this.grammar.grammar.values;

        this.current_states = [ 
            {
                line: "Top",
                node: "start"
            }
        ]
    }

    GetACOptions(nodeBefore: SyntaxNode) {
        let tokens = this.GetTokens(nodeBefore);
        this.RunNodeGrammar(tokens);

        let path_states = this.current_states;
        //console.log("result states "+JSON.stringify(path_states));

        let allowed_next = this.GetAllAllowedNextTags();

        //console.log("allowed_next "+JSON.stringify(allowed_next));

        return allowed_next;
    }

    GetTokens(nodeBefore: SyntaxNode) : string[] {
        let tokens = [];
        let node = nodeBefore as SyntaxNode | null;

        while(node != null && node.type.name && node.type.name != "⚠") {

            //console.log(node.type.name);

            tokens.unshift(node.type.name);

            node = node.prevSibling;
        }

        //console.log("tokens "+JSON.stringify(tokens));
        
        return tokens;
    }

    RunNodeGrammar(tokens: string[]) {
        for(let token of tokens) {

            //console.log("start token "+token + " states "+JSON.stringify(this.current_states));

            let new_states_lists = [] as State[][];
            for(let state of this.current_states) {
                let new_states = [] as State[];
                this.FollowNodeForToken(token, state, new_states);
                if(new_states.length == 0) {
                    //console.log("Dead end state at: "+token + " state "+JSON.stringify(state));
                }
                else {
                    new_states_lists.push(new_states);
                }
            }
            let united_states = [];
            for(let list of new_states_lists) {
                for(let state of list) {
                    united_states.push(state);
                }
            }
            this.current_states = united_states;

            //console.log("end token "+token + " states "+JSON.stringify(this.current_states));
        }
    }

    FollowNodeForToken(token: string, from_state: State,
        new_states: State[]
    ) {

        //console.log("FollowNodeForToken "+token+" from_state "+JSON.stringify(from_state));

        let node_nexts = this.nodes[from_state.line][from_state.node];
        for(let possible_next of node_nexts) {

            //console.log("checking if token "+token+" is in "+possible_next);

            if(possible_next == "done") {
                if(from_state.parent) {
                    this.FollowNodeForToken(token, from_state.parent, new_states);
                }
                continue;
            }

            let denumbered_possible_next = possible_next;
            if(possible_next.indexOf("_") != -1) {
                denumbered_possible_next = possible_next.split("_")[0];
            }

            let isvalue = this.values[denumbered_possible_next];
            let isnode = this.nodes[denumbered_possible_next];
            if(isvalue && isvalue[0] == token) {

                //console.log("found "+token);

                new_states.push(
                    {
                        line: from_state.line,
                        node: possible_next,
                        parent: from_state.parent
                    }
                )
            
            }
            else if(isnode) {

                let push_state = {
                    line: denumbered_possible_next,
                    node: "start",
                    parent: {
                        line: from_state.line,
                        node: possible_next,
                        parent: from_state.parent
                    }

                }

                //console.log("push "+JSON.stringify(push_state));

                this.FollowNodeForToken(token, push_state, new_states);
            }
            else {
                //console.log("Dead end path " +token + " "+JSON.stringify(from_state));
            }
        }

    }

    GetAllAllowedNextTags() {
        //this works off current_states after RunNodeGrammar was called to set it
        let result = [] as string[][];
        let has = {} as  any;
        for(let state of this.current_states) { 
            this.GetAllAllowedNextTagsRecurse(state, result, has);
        }
        return result;
    }

    GetAllAllowedNextTagsRecurse(state: State, result: string[][], has: any) {
        let allowed_nexts = this.nodes[state.line][state.node];
        for(let allowed_next of allowed_nexts) {

            if(allowed_next == "done") {
                if(state.parent) {
                    this.GetAllAllowedNextTagsRecurse(state.parent, result, has);
                }
                continue;
            }

            let denumbered_allowed_next = allowed_next;
            if(allowed_next.indexOf("_") != -1) {
                denumbered_allowed_next = allowed_next.split("_")[0];
            }

            let isvalue = this.values[denumbered_allowed_next];
            let isnode = this.nodes[denumbered_allowed_next];
            if(isvalue && !has[isvalue[0]]) {
                result.push(isvalue);
                has[isvalue[0]] = true;
            }
            else if(isnode) {
                let go_state = {
                    line: denumbered_allowed_next,
                    node: "start"
                }
                this.GetAllAllowedNextTagsRecurse(go_state, result, has);
            }   
        }
    }
}