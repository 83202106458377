import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

import { basicSetup, EditorView } from 'codemirror';
import {keymap} from "@codemirror/view";
import {indentWithTab} from "@codemirror/commands";
import {javascript, javascriptLanguage, scopeCompletionSource} from "@codemirror/lang-javascript";
import {css, cssLanguage, cssCompletionSource} from "@codemirror/lang-css";
import {html, htmlLanguage, htmlCompletionSource} from "@codemirror/lang-html";
import {yaml} from "@codemirror/lang-yaml";
import {autocompletion, CompletionContext} from "@codemirror/autocomplete";
import { syntaxTree } from '@codemirror/language';
import {parser as cbtemplateParser} from "../template_lang/cbtemplate-parser";
import {foldNodeProp, foldInside, indentNodeProp, indentUnit} from "@codemirror/language";
import {parseMixed} from "@lezer/common"
import {LRLanguage} from "@codemirror/language"

export class c_editor_code_webcomponent extends HTMLElement {
    ci: web_c_editor_code | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;

            //standalone
            let ins = this.getAttribute('ins') as string;

            this.ci = new web_c_editor_code(context, cid, name, dc, Number(idx), container_id, this, ins);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-editor-code', c_editor_code_webcomponent);

export class web_c_editor_code extends CodeBrick {
    
    element: HTMLElement;
    editor: any
    //shadowroot: ShadowRoot;
    token_list = null as any;
    cfg: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement, standalone_ins: string) {
        super(context, cid, name, dc, idx, container_id, standalone_ins);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        //Add this to all bricks that need to support standalone, as well as the ins parameter
        if(cid == undefined) {
            this.blueprint.name = element.id;
            this.brick_id = element.id + "_brick";
            (<any>window).so_bricks =  (<any>window).so_bricks || {};
            (<any>window).so_bricks[this.brick_id] = this;
        }

        let html = `<div class='c-editor-code-container'><div id="${this.brick_id}" class="c-editor-code"></div></div>`;
        //let html = `<div id="${this.brick_id}"></div>`;
        element.innerHTML = html;

        let self = this;
        
        //Add this also to all bricks that need to support standalone
        if(cid == undefined) {
            setTimeout(async function() {
                if(self.blueprint.ins) {
                    for(let input in self.blueprint.ins) {
                        let ret = await self.cb_event(input, self.blueprint.ins[input], {} as CBEventInfo);
                    }
                }
            } ,0);
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        // if(cfg == null || JSON.stringify(cfg) == "{}") {
        //     return;
        // }
        let self = this;

        if(input == "cfg") {
            this.cfg = cfg;
            let element = document.getElementById(this.brick_id);
            if(element && cfg.height) {
                let container = element.parentElement;
                if(container) {
                    container.style.height = cfg.height;
                }
            }
        }
        else if(input == 'value') {
            let div = document.getElementById(this.brick_id);
            if(div) {
                if(!this.editor) {

  

                    let extensions = [basicSetup, 
                        keymap.of([indentWithTab]),
                        EditorView.lineWrapping,
                        EditorView.updateListener.of((v:any) => {
                            if (v.docChanged) {
                                // Document changed
                                self.cb_emit({ "@": v.state.doc.toString() }); 
                            }
                        })
                    ];

                    // if(this.token_list) {
                    //     function myCompletions(context: any) {
                    //         let completions = [
                    //             {label: "{{panic", type: "keyword"},
                    //             {label: "{{park", type: "constant", info: "Test completion"},
                    //             {label: "{{password", type: "variable"},
                    //         ];
                    //         for(let token of self.token_list) {
                    //             completions.push({ label: "{{"+token, type: "variable"});
                    //         }

                    //         let before = context.matchBefore(/{{/)
                    //         // If completion wasn't explicitly started and there
                    //         // is no word before the cursor, don't open completions.
                    //         if (!context.explicit && !before) return null
                    //         return {
                    //             from: before ? before.from : context.pos,
                    //             options: completions,
                    //             validFor: /^{{*$/
                    //         }
                    //     }

                    //     //extensions.push(autocompletion({override: [myCompletions]}));
                    // }


                    if(this.cfg.language == "javascript") {
                        extensions.push(javascript());
                        extensions.push(indentUnit.of("    "));
                        extensions.push(javascriptLanguage.data.of({
                            autocomplete: scopeCompletionSource(globalThis)
                        }));
                    }
                    else if(this.cfg.language == "css") {
                        extensions.push(css());
                        extensions.push(indentUnit.of("    "));
                        extensions.push(cssLanguage.data.of({
                            autocomplete: cssCompletionSource
                        }));
                    }
                    else if(this.cfg.language == "html") {

                        const mixedCBTemplateParser = cbtemplateParser.configure({
                            props: [
                            //   // Add basic folding/indent metadata
                            //   foldNodeProp.add({Conditional: foldInside}),
                            //   indentNodeProp.add({Conditional: cx => {
                            //     let closed = /^\s*\{% endif/.test(cx.textAfter)
                            //     return cx.lineIndent(cx.node.from) + (closed ? 0 : cx.unit)
                            //   }})
                            ],
                            wrap: parseMixed(node => {
                              return node.type.isTop ? {
                                parser: htmlLanguage.parser,
                                overlay: node => node.type.name == "Text"
                              } : null
                            })
                          })
                          
                          const cbtemplateLanguage = LRLanguage.define({parser: mixedCBTemplateParser})


                        //extensions.push(html());
                        extensions.push(cbtemplateLanguage);

                        //extensions.push(indentUnit.of("    "));
                        // extensions.push(htmlLanguage.data.of({
                        //     autocomplete: htmlCompletionSource
                        // }));

                        const cbtemplateAutocompletion = cbtemplateLanguage.data.of({
                            autocomplete: function cbTemplateCompletionSource(context: CompletionContext) {

                                let {state, pos} = context;
                                let tree = syntaxTree(state).resolveInner(pos, -1);

                                let lang = cbtemplateLanguage;

                                let word = context.matchBefore(/{{.*/);//(/{{\w*/)
                                //if (word.from == word.to && !context.explicit)
                                //    return null
                                console.log(word.text);

                                

                                return {
                                    from: word.from,// + word.text.length,
                                    options: [
                                        {label: word.text, displayLabel: "CB Template...", type: "keyword"}
                                    // {label: "{{match", displayLabel: "match", type: "keyword"},
                                    // {label: "{{hello", type: "variable", info: "(World)"},
                                    // {label: "magic", type: "text", apply: "⠁⭒*.✩.*⭒⠁", detail: "macro"},
                                    // {label: "time", type: "text", apply: new Date().toTimeString(), detail: "macro"}
                                    ]
                                }
                            }
                        });

                        extensions.push(cbtemplateAutocompletion);

                        extensions.push(html().support);

                        //ADD ADDITIONAL COMPLETIONS TO LANGUAGE https://gist.github.com/bilobom/8076d2d4ff6bda55fc2a13aa5e803eea
                        // extensions.push(htmlLanguage.data.of({
                        //     autocomplete: function htmlCompletionSource(context: any) {
                        //       const { state, pos } = context;
                        //       let around = syntaxTree(state).resolveInner(pos),
                        //         tree = around.resolve(pos, -1);
                        //       for (
                        //         let scan = pos, before;
                        //         around === tree && (before = tree.childBefore(scan));
                  
                        //       ) {
                        //         const last = before.lastChild;
                        //         if (!last || !last.type.isError || last.from < last.to) break;
                        //         around = tree = before;
                        //         scan = last.from;
                        //       }
                        //       if (
                        //         tree.name === "TagName" &&
                        //         !(tree.parent && /CloseTag$/.test(tree.parent.name))
                        //       ) {
                        //         return {
                        //           from:tree.from,
                        //           to:pos,
                        //           options: [
                        //               {
                        //                   label:"audio",
                        //                   info:"HTML audio tag",
                        //               },
                        //               {
                        //                 label:"piesang1",
                        //                 info:"HTML aria-label attribute",
                        //             }
                        //           ],
                        //           span: /^\/?[:\-\.\w\u00b7-\uffff]*$/
                        //         };
                        //       } 
                        //       else if (
                        //           (context.explicit &&
                        //             (tree.name === 'OpenTag' || tree.name === 'SelfClosingTag')) ||
                        //           tree.name === 'AttributeName'
                        //         ) {
                        //           return {
                        //             from: tree.name === 'AttributeName' ? tree.from : pos,
                        //             to: pos,
                        //             options:  [
                        //               {
                        //                   label:"aria-label",
                        //                   info:"HTML aria-label attribute",
                        //               },
                        //               {
                        //                 label:"piesang2",
                        //                 info:"HTML aria-label attribute",
                        //             }
                        //           ],
                        //             span: /^[:\-\.\w\u00b7-\uffff]+$/
                        //           }
                        //         }
                        //     },
                        //   })
                        // );

                    }
                    else if(this.cfg.language == "yaml") {
                        extensions.push(yaml());
                        extensions.push(indentUnit.of("  "));
                    }
                    this.editor = new EditorView({
                        doc: cfg,
                        extensions: extensions,
                        parent: div
                    });

                }
                else {
                    let selection = this.editor.state.selection.main;

                    let was_val = this.getValue();

                    this.editor.dispatch({changes: {from: 0, to: this.editor.state.doc.length, insert: cfg}});

                    if(cfg == was_val) {
                        this.editor.focus();
                        this.editor.dispatch(
                            this.editor.state.update({
                                selection: selection,
                                scrollIntoView: true
                            })
                        );
                    }
                }
            }
        }
        else if(input == "append") {
            this.editor.dispatch({changes: {from: this.editor.state.doc.length, to: this.editor.state.doc.length, insert: cfg}});
        }

        else if(input == 'token_list') {
            this.token_list = cfg;
        }
        // else if(input == "focus") {
        //     this.editor.focus();
        // }

        if(this.editor) {
            let val = this.getValue();
            return { "@": val };
        }

    }

    getValue() {
        //for static component use
        return this.editor.state.doc.toString();
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            let outer = container.parentElement;
            if(outer) {
                if(status == "loading") {
                    container.classList.add("hidden");
                    outer.classList.add("loading");
                }
                else {
                    outer.classList.remove("loading");
                    container.classList.remove("hidden");
                }
            }
        }
    }

    cb_snapshot() {}
}