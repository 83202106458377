import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class cc_resizable_panes_webcomponent extends HTMLElement {
    ci: web_cc_resizable_panes | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cc_resizable_panes(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cc-resizable-panes', cc_resizable_panes_webcomponent);

export class web_cc_resizable_panes extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
   
        let innerHTML = `<div id="${this.brick_id}" style="display: flex;flex-direction: row;">`;
        let i = 0;
        let split_ids = [];
        if(this.blueprint.contains) {
            for(let sub of this.blueprint.contains) {
                let lop_style = "";
                if(sub.cement) {
                    lop_style = JSON.stringify(sub.cement).trim();
                    if(lop_style.length >= 2) {
                        //remove { }
                        lop_style = lop_style.substr(1, lop_style.length - 2);
                    }
                }
                split_ids.push('#'+this.blueprint.name+'_'+i);
                let brick = CBWebUtil.BrickHtml(sub, this, i);
                innerHTML += `<div style="float:left" class="cc-resizable-pane" id="${this.blueprint.name+'_'+(i++)}">${brick}</div>`;

                i++;
            }
        }
        innerHTML += '</div>';
        element.innerHTML = innerHTML;

        let split_Storage_id = this.context.composition_runners[this.cid].composition + this.brick_id+'_split-sizes';

        var stored_sizes = localStorage.getItem(split_Storage_id);

        let options = this.blueprint.ins.cfg || {};

        if (stored_sizes) {
            options.sizes = JSON.parse(stored_sizes)

            //console.log(JSON.stringify(stored_sizes));
        }
        
        options.onDragEnd = function(sizes:any) {

            //console.log(JSON.stringify(sizes));

            localStorage.setItem(split_Storage_id, JSON.stringify(sizes))
        }

        // @ts-expect-error
        Split(split_ids, options);

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {

    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}

}