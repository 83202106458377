import { Brick, InsLeaves, TagItem } from "./CBModels";
import { PrefabNameJoin } from "./CBUtil";

export class LinkMapper {

    static Map(bricks: { [name: string] : Brick }) {
        for(let name in bricks) {
            let brick = bricks[name];

            for(let input in brick.parsed_ins) {
                let leaves = brick.parsed_ins[input];
                for(let leaf of leaves.leaves) {
                    for(let part of leaf.parts) {
                        if(part.tag) {
                            LinkMapper.MapTagItemRecurse(part.tag, bricks, name, input);
                        }
                    }
                }

            }
        }
    }

    private static MapTagItemRecurse(ti: TagItem, bricks: { [name: string] : Brick }, to_name: string, to_input: string) {
        if(ti.type[0] == "p") {

            let s = ti.slice.split(/[\.\[]/); //split on . or [
            let source = s[0];
            if(source[0] == "$") {
                //We don't needt to map these. They will be picked up from nsod on init.
                return;
            }

            let output = "@";
            if(s.length > 1 && s[1][0] == "@") {
                output = s[1];
            }
      
            let dest_brick = bricks[to_name];

            //this source is not namespaced. It is as written in the tag. 
            //We have to add the source or dest ns to the source or dest 
            let dest_in_ns = dest_brick.nsid || "";

            //dest name should already be correct. Already prefixed

            let source_name = (dest_in_ns == "" ? "" : dest_in_ns + PrefabNameJoin ) + source;

            let source_brick = bricks[source_name];
            if(source_brick) {
                source_brick.targets = source_brick.targets || {};
                source_brick.targets[output] = source_brick.targets[output] || {};
                source_brick.targets[output][to_name] = source_brick.targets[output][to_name] || {};
                source_brick.targets[output][to_name][to_input] = 1;
            }
        }
        if(ti.items) {
            for(let item of ti.items) {
                LinkMapper.MapTagItemRecurse(item, bricks, to_name, to_input);
            }
        }
    }

    static GetSources(ins_leaves: InsLeaves, cid: string, nsid: string, out_sources: string[]) {
        for(let input in ins_leaves) {
            for(let leaf of ins_leaves[input].leaves) {
                for(let part of leaf.parts) {
                    if(part.tag) {
                        this.GetTagItemSources(part.tag, cid, nsid, out_sources);
                    }
                }
            }
        }
    }
    
    private static GetTagItemSources(ti: TagItem, cid: string, nsid: string, out_sources: string[]) {
        if(ti.type[0] == "p") {
            let s = ti.slice.split(".");
            let source = s[0];
            if(source[0] != "$") {
                out_sources.push((nsid ? (nsid + PrefabNameJoin) : "") + source);
            }
        }
        if(ti.items) {
            for(let item of ti.items) {
                this.GetTagItemSources(item, cid, nsid, out_sources);
            }
        }
    }

}