import { codebricks_format } from "../../codebricks-runtime/CBFormatting";
import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { Clone, DC_JOIN, ObjectDeepMerge } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { TagItemParser } from "../../codebricks-runtime/TagItemParser";
import { TagItemResolver } from "../../codebricks-runtime/TagItemResolver";
import { Prettyfy } from "../../shared-funcs/Util";
import { cb_pager } from "../controls/cb_pager";
import Sugar from "sugar";
import { CBWebUtil } from "../controls/cb_web_util";


export class cd_table_webcomponent extends HTMLElement {
    ci: web_cd_table | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_cd_table(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('cd-table', cd_table_webcomponent);

export class web_cd_table extends CodeBrick {

    element: HTMLElement;
    data = [] as any[];
    visible_data: any;
    page = 0;
    page_size = 8;
    pages = 1; 
    cfg:any;
    sort_col_idx=null as number|null;
    sort_dir="Descending";

    cfg_sort_col_idx = null;
    cfg_sort_dir = "Descending";

    col_totals = [] as { col: string, total: number, format: string }[];

    pager: cb_pager;
    initialized = false;

    responsive = false;
    may_stack = false;

    cements = {} as { [child_idx: number]: any };

    initial_search = "";

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //let shadowRoot = this.attachShadow({ mode: 'open' });

        this.cfg = this.blueprint.ins ? this.blueprint.ins.cfg : {};
        this.cfg.table = this.cfg.table || {};

        let title = '';
        if(this.cfg.table && this.cfg.table.title) {
            title = `<h2 class="cd-table-title hidden" id="${this.brick_id+"$title"}"></h2>`
        }

        let search = '';
        if(!this.cfg.table || (this.cfg.table && !this.cfg.table.search) || (this.cfg.table && this.cfg.table.search)) {
            search = `<div class="cd-table-search-container${(this.cfg.table.search.hidden || (this.context.platform == "file" && !this.cfg.table.search.value)) ? " hidden" : ""}"><input type="text" spellcheck="false" id="${this.brick_id+"$search"}" placeholder="${(this.cfg.table && this.cfg.table.search && this.cfg.table.search.label) ? this.cfg.table.search.label : 'Search'}" class="form-element cd-table-search" /><span class="cd-table-search-close" id="${this.brick_id+"$search_close"}"></span></div>`;
        }
        // else 
        if(this.cfg.table.title && this.cfg.table.search.hidden) {
            search = "&nbsp;" + search //This is to put the title on the left else the flexbox will put it where the search is if the search is hidden
        }

        if(this.cfg.table && this.cfg.table.page_size !== undefined) {
            this.page_size = this.cfg.table.page_size;
        }

        

        //let html = `<div id="${this.brick_id}$container" style="cd-table-container"><div class="cd-table-header">` + search + header_contained + title + `</div><table id="${this.brick_id}" class="cd-table"><table><div id="${this.brick_id+"$footer"}" class="cd-table-footer" ${hide_footer}>` + entries + pager +`</div></div>`;
        let html = `<div class="cd-table-header"><div><div id="${this.brick_id+"$header"}" class="hidden"></div>` + search + "</div>" + title + `</div>
                    <div id="${this.brick_id}$container" class="cd-table-container">`; 
                    if(this.responsive) {
        html +=         `<div id="${this.brick_id}$table_container" class="cd-table-r-container"></div>`;
                    }
                    else {      
        html +=         `<table id="${this.brick_id}" class="cd-table"></table>`;
                    }
        html +=     `<div id="${this.brick_id}$empty" class="empty-state cd-table-empty"></div></div>
                    <div id="${this.brick_id+"$footer"}" class="cd-table-footer ${(this.cfg.table.no_pager || this.context.platform == "file")  ? "hidden" : ""}"></div>`;


        this.element.innerHTML = `<div class="cd-table-outer" id="${this.brick_id}$outer">${html}</div>`;
        
        this.pager = new cb_pager(this.brick_id+"$footer", {},  
            async function(value:any) {
                self.cb_pager_evt(value);
            }
        );
        
        //let pager = 'pager';
        
        let self = this;
        let search_element = document.getElementById(this.brick_id+"$search") as HTMLInputElement;
        if(search_element) {
            search_element.addEventListener('input', function() {
                if(search_element) {
                    self.page = 0;
                    let pg = document.getElementById(`${self.brick_id+"$pg"}`);
                    if(pg) {
                        pg.innerHTML = String(self.page + 1);
                    }
                    self.render_data();
                }
            });
        }

        this.init_cement();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksTable "+this.brick_id+" cb_event "+info.source +"."+info.source_output+"->"+input+" "+JSON.stringify(cfg));

        if(input == "cfg") {

            if(this.data && cfg.data && this.data.length != cfg.data.length) {
                this.page = 0;
            }

            let title_element = document.getElementById(this.brick_id+"$title");
            if(title_element) {
                if(cfg.table && cfg.table.title) {
                    title_element.innerHTML = cfg.table.title;
                    title_element.classList.remove("hidden");
                }
                else {
                    title_element.classList.add("hidden");
                }
                CBWebUtil.ApplyElementStyles(title_element, cfg.table, "title");
            }
            
            //Clone, otherwise when table sorts data it can sort the source data which can affect other bricks like chart s using the same data
            this.data = cfg.data || [];
            this.cfg = cfg;

            this.may_stack = cfg.table && cfg.table.responsive;
            this.responsive = this.may_stack;

            //if(cfg.table && cfg.table.style_classes) {
                let outer = document.getElementById(this.brick_id+"$outer");
                if(outer) {
                    // for(let cl in cfg.table.style_classes) {
                    //     if(cfg.table.style_classes[cl]) {
                    //         outer.classList.add(cfg.table.style_classes[cl]);
                    //     }
                    // }
                    CBWebUtil.ApplyElementStyles(outer, cfg.table, "outer");
                }
            //}

            let table = document.getElementById(this.brick_id);
            if(table) {
                CBWebUtil.ApplyElementStyles(table, cfg.table, "table");
            }

            let search = document.getElementById(this.brick_id+"$search");
            if(search) {
                if(this.cfg.table && this.cfg.table.search && (this.cfg.table.search.value != this.initial_search || this.cfg.table.search.value == "")) {
                    (<HTMLInputElement>search).value = this.cfg.table.search.value === undefined ? "" : this.cfg.table.search.value;
                    this.initial_search = this.cfg.table.search.value;
                    if(this.cfg.table.search.focus) {
                        search.focus();
                    }
                }
                CBWebUtil.ApplyElementStyles(search, cfg.table, "search");
            }

            let header = document.getElementById(this.brick_id+"$header");
            if(header) {
                header.classList.remove("hidden");
                CBWebUtil.ApplyElementStyles(header, cfg.table, "header");
            }

            let title = document.getElementById(this.brick_id+"$title");
            if(title) {
                CBWebUtil.ApplyElementStyles(title, cfg.table, "title");
            }

            await this.render_data();

        }
        else if(input == "rowdata") {
            if(info.source_brick_id) {
                let source_dc = this.context.bricks[info.source_brick_id].dc;
                let this_dc_len = this.dc ? this.dc.split(DC_JOIN).length : 0;

                let source_dc_split = source_dc.split(DC_JOIN);
                let roidx = Number(source_dc_split[this_dc_len + 2]);

                let idx = roidx + (this.page * this.page_size);

                let before = JSON.stringify(this.data[idx]);

                ObjectDeepMerge(this.data[idx], cfg);

                let after = JSON.stringify(this.data[idx]);

                //console.log("rowdata "+idx+" : before "+before+" cfg " + JSON.stringify(cfg) + ' after '+after);
                console.log("rowdata "+idx+" : "+ JSON.stringify(cfg));

                if(before != after) { //prevent loops
                    this.cb_emit({ "@": this.data[idx] }, source_dc);

                    this.cb_emit({ "@rowdata": this.data });
                }
            }
        }
        // else if(input == "rowdelete") {
        //     if(info.source_brick_id) {
        //         let source_dc = this.context.bricks[info.source_brick_id].dc;
        //         let this_dc_len = this.dc ? this.dc.split(DC_JOIN).length : 0;

        //         let source_dc_split = source_dc.split(DC_JOIN);
        //         let roidx = Number(source_dc_split[this_dc_len + 2]);

        //         let idx = roidx + (this.page * this.page_size);
                
        //         this.data.splice(idx, 1);

        //         await this.render_data();

        //         this.cb_emit({ "@rowdata": this.data });
        //     }
        // }
        // else if(input == "push") {
        //     this.data.push(cfg);
        //     await this.render_data();
        //     this.cb_emit({ "@rowdata": this.data });
        // }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
        //console.log("cd-table "+this.brick_id+" cb_initial_cement "+JSON.stringify(cements));
        this.cements = cements;

        let header_contained = "";
        if(this.blueprint.contains) {
            let  i = 0;
            for(let sub of this.blueprint.contains) {
                let cement = cements[i] || sub.cement;
                if(!cement || !cement.column) {
                    let brick = CBWebUtil.BrickHtml(sub, this, i, this.dc||'');
                    header_contained += brick;
                }
                i++;
            }
        }
        if(header_contained) {
            let header = document.getElementById(this.brick_id+"$header");
            if(header) {
                header.innerHTML = header_contained;
            }
        }
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {

        //console.log("cd-table "+this.brick_id+" cb_update_cement child_idx "+child_idx + " row_idx " +row_idx+ " cement "+JSON.stringify(cement));

        //this.cements[child_idx] = cement; don't, it will save for all rows, and then apply to all rows when you page

        let dc_root = (this.dc || "") + (DC_JOIN + this.blueprint.name);

        let child_dc = dc_root + DC_JOIN + row_idx;

        let child = this.element.querySelector('td [dc="'+child_dc+'"][idx="'+child_idx+'"][container_id="'+this.brick_id+'"]');
        if(child) {
            if(cement.hidden) {
                child.classList.add("hidden");
            }
            else {
                child.classList.remove("hidden");
            }
        }
    }
    async render_data() {
        this.flush_dynamic();
        let search_term = '';
        let search = document.getElementById(this.brick_id+"$search");
        if(search) {
            
            search_term = (<HTMLInputElement>search).value;
            

            if(search_term) {
                search.classList.add("cd-table-search-active");
            }
            else {
                search.classList.remove("cd-table-search-active");
            }
            let search_close = document.getElementById(this.brick_id+"$search_close");
            if(search_close) {
                search_close.style.display = search_term == "" ? "none" : "inline-block";

                if(!this.initialized) {
                    let self = this;
                    search_close.addEventListener("click", async function() {
                        let search = document.getElementById(self.brick_id+"$search");
                        let search_term = (<HTMLInputElement>search).value;
                        if(search_term) {
                            (<HTMLInputElement>search).value = "";
                            await self.render_data();
                        }
                    });
                }
            }

        }

        let columns = this.cfg.columns;
     
        let search_data = [] as any[];
        if(search_term) {
            search_term = search_term.toLowerCase();

            //Only search configured columns
            let colnames = [];
            if(columns && columns.length > 0) {
                for(let c of columns) {
                    if(c) {
                        colnames.push(c.column);
                    }
                }
            }
            else if(this.data && this.data.length > 0) { //Or all columns if none configured
                colnames = Object.keys(this.data[0]);
            }

            for(let d of this.data) {
                for(let k of colnames) {
                    if(String(d[k]).toLowerCase().indexOf(search_term) != -1) {
                        search_data.push(d);
                        break;
                    }
                }
            }
        }
        else {
            search_data = this.data;
        }

        

        if(this.cfg.table) {
            if(this.cfg.table.sort_col_idx !== null && this.cfg.table.sort_col_idx !== undefined && this.cfg_sort_col_idx != this.cfg.table.sort_col_idx) {
                this.cfg_sort_col_idx = this.cfg.table.sort_col_idx;
                this.sort_col_idx = this.cfg.table.sort_col_idx;
            }

            if(this.cfg.table.sort_dir !== null && this.cfg.table.sort_dir !== undefined && this.cfg_sort_dir != this.cfg.table.sort_dir) {
                this.cfg_sort_dir = this.cfg.table.sort_dir;
                this.sort_dir = this.cfg.table.sort_dir;
            }
        }

        //No columns configured, so dynamically derive columns from data
        if(!columns || columns.length == 0) {
            columns = [];
            if(search_data && search_data.length > 0 && search_data[0]) {
                let col_names = Object.keys(search_data[0]);
                for(let c of col_names) {
                    let format = typeof (search_data[0][c]) as string;
                    let align = "";
                    if(format == "boolean") {
                        format = "Bool.toYesNo()";
                        align = "center";
                    }
                    else if(!isNaN(search_data[0][c])) {
                        if(this.cfg.default_formats && this.cfg.default_formats.number) {
                            format = this.cfg.default_formats.number || "Number";
                        }
                    }
                    else if(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d/.test(search_data[0][c])) {
                        if(this.cfg.default_formats && this.cfg.default_formats.date) {
                            format = this.cfg.default_formats.date || "Date";
                        }
                    }
                    columns.push({column: c, format: format, align: align });
                }
            }
        }

        //Column sorting
        if((this.sort_col_idx || this.sort_col_idx === 0) && search_data && search_data.length > 0) {
            let idx = 0;
            let sort_name = null as string | null;
            for(let colid in columns) {
                if(idx == this.sort_col_idx) {
                    sort_name = columns[colid].column;
                    break;
                }
                idx++;
            }
            if(sort_name !== null) {
                //console.log("sort_name "+sort_name+" sort_dir "+this.sort_dir);

                //If we change the incoming data we have to clone, else other bricks using the same data by reference will also be affected (e.g. charts)
                search_data = Clone(search_data); 

                search_data.sort((a:any, b:any) => {
                    if(sort_name !== null) {
                        let dir = this.sort_dir == "Ascending" ? 1 : -1;
                        if(typeof a[sort_name] == "string" && typeof b[sort_name] == "string") {
                            if(a[sort_name].toLowerCase() == b[sort_name].toLowerCase()) {
                                return 0;
                            }
                            return a[sort_name].toLowerCase() < b[sort_name].toLowerCase() ? -dir : dir;
                        }
                        else {
                            if(a[sort_name] == b[sort_name]) {
                                return 0;
                            }
                            return a[sort_name] < b[sort_name] ? -dir : dir;
                        }
                    }
                    return 0;
                });
            }
        }

        //Totals
        this.col_totals = [] as { col: string, total: number, format: string }[];
        for(let col of columns) {
            if(col && col.total) {
                this.col_totals.push({ col: col.column, total: 0, format: col.format} );
            }
        }
        if(this.col_totals.length > 0) {
            for(let row of search_data) {
                for(let t of this.col_totals) {
                    let n = row[t.col];
                    if(!isNaN(n)) {
                        t.total += Number(n);
                    }
                }
            }
        }

        let display_data = [] as any[];

        await this.pager.setPages(search_data.length, this.page_size, this.page);
        let footer = document.getElementById(this.brick_id + "$footer");
        if(footer) {
            if(this.context.platform == "file" || this.cfg.table.no_pager || search_data.length == 0) {
                footer.classList.add("hidden");
            }
            else {
                footer.classList.remove("hidden");
            }
        }

        let page_start = 0;
        let page_end = search_data.length || Object.keys(search_data).length;
        this.pages = Math.ceil(search_data.length / this.page_size);
        if(this.page_size > 0) {
            page_start = this.page*this.page_size;
            page_end = page_start + this.page_size;
            if(page_end > search_data.length) {
                page_end = search_data.length;
            }
        }

        if(this.context.platform == "file") {
            display_data = search_data;
        }
        else {
            for(let i = page_start; i < page_end; i++) {
                display_data.push(search_data[i]);
            }
        }

        this.visible_data = display_data;
        
        if(this.responsive) {
            this.render_responsive_table(columns, display_data);
        }
        else {
            this.render_table(columns, display_data);
        }

        await this.send_dynamic_initialisation_events(display_data);

        //Column sorting event handler
        if(!this.cfg.table || !this.cfg.table.no_sort) {
            let self = this;
            for(let colid in columns) {
                if(columns[colid] && !columns[colid].hidden && !columns[colid].no_sort) {
                    let col_header = document.getElementById(this.brick_id +"$sort$" + colid);
                    if(col_header) {
                        col_header.addEventListener('click', async function(evt: any) {
                            let s = this.id.split('$');
                            let col_idx = Number(s[s.length-1]);

                            //console.log("this.id "+this.id+" col_idx "+col_idx);

                            if(self.sort_col_idx == col_idx) {
                                self.sort_dir = (self.sort_dir == "Ascending" ? "Descending" : "Ascending");
                            }
                            self.sort_col_idx = col_idx;

                            await self.render_data();
                        });
                    }
                }
            }
        }

        this.initialized = true;
    }

    render_table(columns: any, display_data: any) {
        let html = '';
        //heading
        html += '<tr class="cd-table-tr">'
        let numcols = 0;
        let sort_class = "cd-table-sort-none";
        let th_sort_class = "cd-table-th-sort";
        let no_column_header = "";
        if(this.cfg.table) {
            if(this.cfg.table.no_sort) {
                sort_class = "";
                th_sort_class = "";
            }
            if(this.cfg.table.no_column_header) {
                no_column_header = `style="display:none"`;
            }
        }

        let num_visible_cols = 0;
        let total_fr = 0;
        for(let colid in columns) {
            if(columns[colid] && !columns[colid].hidden) {
                num_visible_cols++;
                if(columns[colid].width_fr) {
                    total_fr += columns[colid].width_fr;
                }
                else {
                    total_fr += 1;
                }
            }
        }
    

        for(let colid in columns) {
            if(columns[colid] && !columns[colid].hidden) {
                let heading = (typeof columns[colid].heading != "string" || columns[colid].heading == "") ? Prettyfy(columns[colid].column) :  columns[colid].heading;
                if(heading == " ") {
                    heading = "";
                }
                let col_sort_class = sort_class;
                if(Number(colid) === this.sort_col_idx && sort_class) {
                    col_sort_class = "cd-table-sort-"+this.sort_dir;
                }
                if(columns[colid].no_sort) {
                    col_sort_class = "";
                }

                let number_style = "";
                if(!columns[colid].align) {
                    if(columns[colid].format && columns[colid].format.toLowerCase().indexOf("number") == 0) {
                        number_style += " cd-table-th-num";
                    }
                    else if(display_data && Array.isArray(display_data) && display_data.length > 0) {
                        let val = display_data[0][columns[colid].column];
                        let f = codebricks_format(val, columns[colid].format, this.context.composition_runners[this.cid].compos?.system_options || {});
                        if(f.type == "number") {
                            number_style += " cd-table-th-num";
                        }
                    }
                }

                let width = "";
                if(columns[colid].width_fr) {
                    let w = (100 / total_fr) * columns[colid].width_fr;
                    width = ` width="${w}%"`;
                }
                
                html += `<th class="cd-table-th ${th_sort_class}${number_style}${columns[colid].align ? (" cd-table-th-" + columns[colid].align) : ""}"${width} id="${this.brick_id}$sort$${colid}" ${no_column_header}><div class="cd-table-col"><div>`+ heading + `</div><div class="${col_sort_class}"></div></div></th>`;
                numcols++;
            }
        }
        html += '</tr>';

        if(this.col_totals.length > 0) {
            html += '<tr>';
            for(let colid in columns) {
                let col = columns[colid];
                if(!col || col.hidden) {
                    continue;
                }
                let has_total = false;
                for(let ct of this.col_totals) {
                    if(ct.col == col.column) {
                        has_total = true;
                        ct.total = ct.total || 0;
                        let align = columns[colid].align;
                        let format = "Number";
                        if(ct.total.toFixed(2) == (ct.total.toFixed(0)+".00")) {
                            format = "Number(0)";
                        }
                        let f = codebricks_format(ct.total, ct.format || format, this.context.composition_runners[this.cid].compos?.system_options || {})
                        html += ` <td class='cd-table-total${(align && align != "auto") ? " text-align-"+align:""}'>${(align && align != "auto" ? `<div class="cd-table-td-${align}">` : "")}${f.value}${(align && align != "auto" ? "</div>" : "")}
                        </td>`;
                        break;
                    }
                }
                if(!has_total) {
                    html += "<td class='cd-table-nototal'></td>";
                }
            }
            html += '</tr>';
        }

        let row_idx = 0;
        for(let row of display_data) {
            html += '<tr>'
            let col_idx = 0;
            for(let colid in columns) {
                if(columns[colid] && !columns[colid].hidden) {
                    let child = "";
                    let col = columns[colid];
                    let val = row[col.column];
                    if(val === undefined || val === null) {
                        val = '';
                    }
                    let format_type = '';

                    let align = columns[colid].align || "auto";

                    if(this.blueprint.contains) {

                        let dc_root = (this.dc || "") + (DC_JOIN + this.blueprint.name);

                        

                        //TODO NB - Alllow number to be specified as sub.cement.column. 1 is first column and -1 is last column (first from the right)
                        //Must also work if no columns are specified (dynamic table)
                        let child_dc = dc_root + DC_JOIN + row_idx;

                        
                        let i = 0;
                        for(let sub of this.blueprint.contains) {

                            let cement = this.cements[i] || sub.cement;

                            if(cement && (cement.column == col.column || cement.column === col_idx)) {//} && !cement.hidden) {
                                if(cement.value == "before") {
                                    let f = codebricks_format(val, col.format, this.context.composition_runners[this.cid].compos?.system_options || {});
                                    child += f.value;
                                    format_type = f.type;
                                }

                                let hidden = (cement && cement.hidden) ? "hidden" : "";

                                let brick = CBWebUtil.BrickHtml(sub, this, i, child_dc, hidden);
                                child += brick;

                                //this.context.cb_indata[sub.brick.name + child_dc] = Object.create(this.context.cb_indata[sub.brick.name + this.dc] || {});
                                //ObjectDeepMerge(this.context.cb_indata[sub.brick.name + child_dc], row);

                                if(cement.value == "after") {
                                    let f = codebricks_format(val, col.format, this.context.composition_runners[this.cid].compos?.system_options || {})
                                    child += f.value;
                                    format_type = f.type;
                                }
                            }
                            i++;
                        }
                    }
                    if(child === "") {
                        
                        let f = codebricks_format(val, col.format, this.context.composition_runners[this.cid].compos?.system_options || {});
                        child = f.value;
                        format_type = f.type;
                    }
                    let modifier_classes = "";
                    let modifier_styles = "";
                    if(align && align != "auto") {
                        modifier_classes += "cd-table-td-"+align + " ";
                    }
                    if(col.highlight_rule) {
                        //TODO cache tagitem
                        let tagItem = TagItemParser.ParseTagItem(col.highlight_rule, { i: 0 }, "}}");

                        let sod = {} as any;
                        for(let k in row) {
                            sod[k] = { "@" : { d: row[k], r: 0, s: 0 }};
                        }

                        let tir = new TagItemResolver(sod, 0, "", "@", this.context.composition_runners[this.cid].compos?.system_options || {});
                        let highlight = tir.ResolveTag(tagItem, 0, []);
      

                        if(highlight) {
                            if(highlight.startsWith("rgb") || highlight.startsWith("hsl") || highlight.startsWith("#") || highlight.startsWith("var")) {
                                modifier_styles = ` style="background-color:${highlight}" `;
                            }
                            else {
                                modifier_classes += highlight;
                            }
                        }

                    }
                    html += `<td class="cd-table-td${(format_type == "number" && align == "auto") ? " cd-table-td-num" : ""}${(align && align != "auto") ? (" text-align-"+align + " ") : ""}" ${modifier_styles}>${(modifier_classes ? `<div class="${modifier_classes}">` : "")}${child}${(modifier_classes ? "</div>" : "")}</td>`;
                    //html += `<td class="cd-table-td${(format_type == "number" && align == "auto") ? " cd-table-td-num" : ""}${(align && align != "auto") ? (" text-align-"+align + " ") : ""}" ${modifier_styles}><div>${(modifier_classes ? `<div class="${modifier_classes}">` : "")}${child}${(modifier_classes ? "</div>" : "")}</div></td>`;              
                    //html += `<td class="cd-table-td${(format_type == "number" && align == "auto") ? " cd-table-td-num" : ""}${(align && align != "auto") ? (" text-align-"+align + " ") : ""}${modifier_classes}" ${modifier_styles}>${child}</td>`;
                }
                col_idx++;        
            }
            row_idx++;
            html += '</tr>';
        }
        let empty_element = document.getElementById(this.brick_id+"$empty");
        if(empty_element) {
            if(display_data.length == 0) {
                if(this.cfg.table) {
                    let msg = this.cfg.table.empty_table_message || 'No Results';
                    empty_element.innerHTML = msg;
                    empty_element.classList.remove("hidden");
                }
            }
            else {
                empty_element.classList.add("hidden");
            }
        }

        // let counts = document.getElementById(this.brick_id+"$counts");
        // if(counts) {
        //     if(this.page > page_end) {
        //         this.page = page_end;
        //     }
        //     counts.innerHTML = "Showing "+(page_start + 1)+" to "+page_end+" of "+search_data.length;
        // }
        
        let table = document.getElementById(this.brick_id);

        if(table) {
            //This must be firsthis.brick_id
            table.innerHTML = html;
        }

        //console.log("1(1) table HTML assigned  "+JSON.stringify(display_data));
    
    }

    render_responsive_table(columns: any, display_data: any, stacking_levels = 1) {

        //console.log("render_responsive_table "+stacking_levels);

        let numcols = 0;
        for(let colid in columns) {
            if(!columns[colid].hidden) {
                numcols++;
            }
        }

        let render_trs = false;

        //let cell_is_td_class = stacking_levels == 1 ? "cd-table-r-td " : "";

        let repeats = Sugar.Number.ceil(numcols/stacking_levels);

        let html = `<div id="${this.brick_id}" class="cd-table-r" style="grid-template-columns: repeat(${repeats}, minmax(auto, auto))">`;
        //heading
        if(render_trs) {
            html += '<div class="cd-table-rtr">';
        }
        let sort_class = "cd-table-sort-none";
        let th_sort_class = "cd-table-rth-sort";
        let no_column_header = "";
        if(this.cfg.table) {
            if(this.cfg.table.no_sort) {
                sort_class = "";
                th_sort_class = "";
            }
            if(this.cfg.table.no_column_header) {
                no_column_header = `style="display:none"`;
            }
        }
        let col_idx = 0;
        let stack_idx = 0;
        let sl = 0;
        for(sl = 0; sl < stacking_levels; sl++) {
            let col_idx = 0;
            let col_in_stack_row = 0;
            for(let colid in columns) {

                let row_in_stack = stacking_levels == 1 ? 0 : (col_idx) % stacking_levels;

                if(row_in_stack == sl) {
                   
                    // if(numcols > 5) {
                    //     console.log("sl "+sl+" col_idx "+col_idx+ " stacking_levels " +stacking_levels+" row_in_stack "+row_in_stack+" render "+(row_in_stack == sl)+ " " + columns[colid].heading);
                    // }

                    if(!columns[colid].hidden) {
                        let heading = (typeof columns[colid].heading != "string" || columns[colid].heading == "") ? Prettyfy(columns[colid].column) :  columns[colid].heading;
                        if(heading == " ") {
                            heading = "";
                        }
                        columns[colid].display_heading = heading;
                        let col_sort_class = columns[colid].no_sort ? "" : sort_class;
                        if(Number(colid) === this.sort_col_idx && sort_class && !columns[colid].no_sort) {
                            col_sort_class = "cd-table-sort-"+this.sort_dir;
                        }
                        
                        let row_end = "";
                        if(col_idx > 0 && stack_idx % repeats == (repeats - 1)) {
                            row_end = " cd-table-r-td-last";
                        }
                        // if(stacking_levels > 1 && col_idx % stacking_levels == 0) {
                        //     html += `<div class="cd-table-r-td cd-table-r-cell-stack${row_end}">`;
                        // }
                        // if(stacking_levels > 1) {
                        //     row_end = "";
                        // }
                        // html += `<div class="cd-table-r-cell cd-table-rth ${cell_is_td_class}${th_sort_class}${row_end}" id="${this.brick_id}$sort$${colid}"><div class="cd-table-col"><div>`+ heading + `</div><div class="${col_sort_class}"></div></div></div>`;
                        // if(stacking_levels > 1 && (col_idx % stacking_levels == (stacking_levels - 1) || col_idx == numcols - 1)) {
                        //     html += `</div>`;
                        //     stack_idx++;
                        // }
                        // if(stacking_levels == 1) {
                        //     stack_idx++;
                        // }

                        let is_row = "";
                        if(row_in_stack == stacking_levels - 1) {
                            is_row = " cd-table-r-tr";
                        }

                        let stack_class = "";
                        if(stacking_levels > 1) {
                            stack_class = "cd-table-r-row" +sl + " ";
                        }

                        // let corner_classes = "";
                        // if(sl == 0 && col_idx == 0) {
                        //     corner_classes += " cd-table-r-th-tl";
                        // }
                        // if(col_in_stack_row == repeats - 1 && sl == 0) {
                        //     corner_classes += " cd-table-r-th-tr";
                        // }
                        // if(col_in_stack_row == repeats - 1 && sl == stacking_levels - 1) {
                        //     corner_classes += " cd-table-r-th-br";
                        // }
                        // if(col_in_stack_row == 0 && sl == stacking_levels - 1) {
                        //     corner_classes += " cd-table-r-th-bl";
                        // }

                        let side_classes = "";
                        if(sl == 0) {
                            side_classes += " cd-table-th-top";
                        }
                        if(sl == stacking_levels - 1) {
                            side_classes += " cd-table-th-bottom";
                        }
                        if(col_in_stack_row == 0) {
                            side_classes += " cd-table-th-left";
                        }
                        if(col_in_stack_row == repeats - 1) {
                            side_classes += " cd-table-th-right";
                        }

                        if(columns[colid].format && columns[colid].format.toLowerCase().indexOf("number") == 0) {
                            side_classes += " cd-table-r-th-num";
                        }

                        html += `<div class="cd-table-r-td cd-table-r-th ${stack_class}${th_sort_class}${is_row}${row_end}${side_classes}" ${no_column_header} id="${this.brick_id}$sort$${colid}"><div class="cd-table-col"><div>`+ heading + `</div><div class="${col_sort_class}"></div></div></div>`;

                        col_in_stack_row++;
                    }
                }
                col_idx++;
            }
        }
        let remainder = Sugar.Number.ceil(numcols / stacking_levels) * stacking_levels - numcols;
        for(let r = 0; r < remainder; r++) {
            let corner_classes = " cd-table-th-bottom";
            if(r == remainder - 1) {
                corner_classes += " cd-table-th-right";
            }

            html += `<div class="cd-table-r-td cd-table-r-th cd-table-r-row${sl - 1} cd-table-r-tr${corner_classes}"> </div>`;
        }
        if(render_trs) {
            html += '</div>';
        }

        if(this.col_totals.length > 0) {
            if(render_trs) {
                html += '<div class="cd-table-rtr>';
            }
            for(let col of columns) {
                let has_total = false;
                for(let ct of this.col_totals) {
                    if(ct.col == col.column) {
                        has_total = true;
                        let f = codebricks_format(ct.total, ct.format || "Number", this.context.composition_runners[this.cid].compos?.system_options || {})
                        html += " <div class='cd-table-rtd cd-table-total'>"+f.value+"</div>";
                        break;
                    }
                }
                if(!has_total) {
                    html += "<div class='cd-table-rtd cd-table-nototal'></div>";
                }
            }
            if(render_trs) {
                html += '</div>';
            }
        }

        let row_idx = 0;
        for(let row of display_data) {
            if(render_trs) {
                html += '<div class="cd-table-r-tr">';
            }       
            stack_idx = 0;
            sl = 0;
            let odd = "";
            if(stacking_levels > 1 && row_idx % 2 == 1) {
                odd = " cd-table-r-td-odd ";
            }
            for(sl = 0; sl < stacking_levels; sl++) {
                col_idx = 0;
                for(let colid in columns) {
    
                    let row_in_stack = stacking_levels == 1 ? 0 : (col_idx) % stacking_levels;
                    
                    //console.log("sl "+sl+" col_idx "+col_idx+ " stacking_levels " +stacking_levels+" row_in_stack "+row_in_stack+" render "+(row_in_stack == sl)+ " " + columns[colid].heading);

                    if(row_in_stack == sl) {
                        if(!columns[colid].hidden) {
                            let child = "";
                            let col = columns[colid];
                            let val = row[col.column];
                            if(val === undefined || val === null) {
                                val = '';
                            }
                            let format_type = '';

                            if(this.blueprint.contains) {

                                let dc_root = (this.dc || "") + (DC_JOIN + this.blueprint.name);

                                let child_dc = dc_root + DC_JOIN + row_idx;
                              
                                

                                let i = 0;
                                for(let sub of this.blueprint.contains) {

                                    let cement = this.cements[i] || sub.cement;

                                    if(cement && (cement.column == col.column || cement.column === col_idx) && !cement.hidden) {
                                        if(cement.value == "before") {
                                            let f = codebricks_format(val, col.format, this.context.composition_runners[this.cid].compos?.system_options || {});
                                            child += f.value;
                                            format_type = f.type;
                                        }
        
                                        child += `<${sub.type} cid="${this.cid}" dc="${child_dc}" name="${sub.name}" idx="${i}" container_id="${this.brick_id}"></${sub.type}>`;

                                        if(cement.value == "after") {
                                            let f = codebricks_format(val, col.format, this.context.composition_runners[this.cid].compos?.system_options || {})
                                            child += f.value;
                                            format_type = f.type;
                                        }
                                    }
                                    i++;
                                }
                            }
                            if(child === "") {
                                
                                let f = codebricks_format(val, col.format, this.context.composition_runners[this.cid].compos?.system_options || {});
                                child = f.value;
                                format_type = f.type;
                            }
                            let row_end = "";
                            if(col_idx > 0 && stack_idx % repeats == (repeats - 1)) {
                                row_end = " cd-table-r-td-last";
                            }
                            // if(stacking_levels > 1 && col_idx % stacking_levels == 0) {
                            //     html += `<div class="cd-table-r-td cd-table-r-cell-stack${row_end}">`;
                            // }
                            // if(stacking_levels > 1) {
                            //     row_end = "";
                            // }
                            // html += '<div class="cd-table-r-cell'+row_end+' '+cell_is_td_class+(format_type == "number" ? "cd-table-rtd-num" : "")+'">'+ child + '</div>';
                            // if(stacking_levels > 1 && (col_idx % stacking_levels == (stacking_levels - 1) || col_idx == numcols - 1)) {
                            //     html += `</div>`;
                            //     stack_idx++;
                            // }
                            // if(stacking_levels == 1) {
                            //     stack_idx++;
                            // }
                            let is_row = "";
                            if(row_in_stack == stacking_levels - 1) {
                                is_row = " cd-table-r-tr";
                            }

                            let stack_class = "";
                            //if(stacking_levels > 1) {
                                stack_class = " cd-table-r-row" +sl + " ";
                            //}

                            let heading = "";
                            if(stacking_levels > 1) {
                                heading = `<span class="cd-table-r-cell-heading">${col.display_heading}</span>`;
                            }
                            
                            html += '<div class="cd-table-r-td '+is_row+row_end+stack_class+odd+' '+(format_type == "number" ? "cd-table-rtd-num" : "")+'">'+ heading + child + '</div>';
                        }
                    }
                    col_idx++;   
                }            
            }

            let remainder = Sugar.Number.ceil(numcols / stacking_levels) * stacking_levels - numcols;
            for(let r = 0; r < remainder; r++) {
                html += `<div class="cd-table-r-td cd-table-r-row${sl - 1} cd-table-r-tr${odd}"></div>`;
            }
            row_idx++;
            if(render_trs) {
                html += '</div>';
            }
        }
        if(display_data.length == 0) {
            let msg = 'No Results';
            if(this.cfg.table && this.cfg.table.empty_table_message) {
                msg = this.cfg.table.empty_table_message;
            }
            html += `<div class="empty-state cd-table-empty">${msg}</div>`
        }

        let table_container = document.getElementById(this.brick_id +"$table_container");

        if(table_container) {
            
            //This must be firsthis.brick_id
            table_container.innerHTML = html + "</div>";

            let self = this;
            //setTimeout(function() {
            if(this.may_stack) {
                let table = document.getElementById(self.brick_id+"$container");
                if(table) {
                    //console.log("table offsetWidth "+table.offsetWidth);
                    //console.log("table scrollWidth "+table.scrollWidth );

                    if(table.scrollWidth > table.offsetWidth) {
                        if(stacking_levels + 1 <= 4) {
                            self.render_responsive_table(columns, display_data, stacking_levels + 1);
                        }
                    }
                }
            }
            //}, 0);

            //table.style.gridTemplateColumns = "repeat("+(numcols/stacking_levels)+", auto);"
            

        }
    }

    cb_status(status: string): void {
        if(status == "loading") {
            if(this.responsive) {
                let table = document.getElementById(this.brick_id+"$table_container");
                if(table) {
                    table.innerHTML = "";
                    table.classList.add("loading");
                }
            }
            else {
                let table = document.getElementById(this.brick_id);
                if(table) {
                    table.innerHTML = "";
                    //table.classList.add("loading");

                    let self = this;
                    setTimeout(function() { self.render_skeleton(); }, 0); //this is weird, setTimeout otherwise it applies the class before it applies the new html
                }
            }

            let empty_element = document.getElementById(this.brick_id+"$empty");
            if(empty_element) {
                empty_element.classList.add("hidden");
            }

            let footer =  document.getElementById(this.brick_id+"$footer");
            if(footer) {
                footer.style.visibility = 'hidden';
            }
        }
        else {

            if(this.responsive) {
                let table = document.getElementById(this.brick_id+"$table_container");
                if(table) {
                    table.classList.remove("loading");
                }
            }
            else {
                let table = document.getElementById(this.brick_id);
                if(table) {
                    //table.classList.remove("loading");
                    table.classList.remove("cd-table-skeleton");
                    let search = document.getElementById(this.brick_id+"$search");
                    if(search) {
                        search.classList.remove("cd-table-search-skeleton");
                    }
                }
            }

            let footer =  document.getElementById(this.brick_id+"$footer");
            if(footer) {
                footer.style.visibility = 'visible';
            }
        }
    }

    render_skeleton() {
        let skeleton_cols = 4;
        let skeleton_rows = 6;
        
        if(this.blueprint.ins && this.blueprint.ins.cfg && this.blueprint.ins.cfg.columns) {
            skeleton_cols = this.blueprint.ins.cfg.columns.length;
        }

        let html = "<tr>";
        for(let i = 0; i < skeleton_cols; i++) {
            html += "<th><div></div></th>"
        }
        html += "</tr>";
        for(let r = 0; r < skeleton_rows; r++) {
            html += "<tr>";
            for(let i = 0; i < skeleton_cols; i++) {
                html += "<td><div></div></td>"
            }
            html += "</tr>";
        }
       

        let table = document.getElementById(this.brick_id);
        if(table) {
            table.innerHTML = html;
            table.classList.add("cd-table-skeleton");
        }

        let search = document.getElementById(this.brick_id+"$search");
        if(search) {
            search.classList.add("cd-table-search-skeleton");
        }
    }

    async cb_pager_evt(goto_page: number) {
        this.page = goto_page;
        await this.render_data();
    }

    cb_snapshot() {
        let snapshot = {} as any;
        let search = document.getElementById(this.brick_id+"$search");
        if(search) {
            let search_term = (<HTMLInputElement>search).value;
            if(search_term) {
                Sugar.Object.set(snapshot, "cfg.table.search.value", search_term);
            }
        }
        return snapshot;
    }
}


