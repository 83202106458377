import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_pager } from "../controls/cb_pager";
import Sugar from "sugar";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_pager_webcomponent extends HTMLElement {
    ci: web_c_pager | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_pager(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-pager', c_pager_webcomponent);

export class web_c_pager extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    value = "";
    count = 0;
    page_size = 12;
    page = 0;
    num_pages = 1;
    data: any;

    initial_sent = false;

    pager: cb_pager;

    //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        let html = `<div id="${this.brick_id}"></div>`;

        this.element.innerHTML = html;

        let self = this;
        this.pager = new cb_pager(this.brick_id, {},  
        async function(value:any) {
            self.cb_pager_evt(value);
        });
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksSelect "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.cfg)+" => "+JSON.stringify(cfg));

        if(input == 'cfg') {

            this.count = cfg.count || 0;

            if(cfg.data) {
                this.count = cfg.data.length;
                this.data = cfg.data;
            }

            if(cfg.page !== undefined) {
                this.page = cfg.page;
            }

            this.page_size = cfg.page_size || 12;

            this.num_pages = Math.ceil(this.count / this.page_size);

            let container = document.getElementById(this.brick_id);
            if(container) {
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
            }

            return {"@": await this.setPage(this.page, false) };           
        }
    }

    async setPage(page: number, emit = true) {
        this.page = page;

        let offset = page * this.page_size;
        let page_data = [];
        if(this.data) {
            if(this.data.length > offset) {
                for(let i = offset; i < this.data.length && i < offset + this.page_size; i++) {
                    page_data.push(this.data[i]);
                }
            }
        }

        await this.pager.setPages(this.count, this.page_size, this.page);

        let obj = { offset: offset, page_size: this.page_size, page: page, page_data: page_data };

        if(emit) {
            this.cb_emit({ "@": obj });
        }

        return obj;
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {

        //console.log("cb_status "+this.brick_id + " " +status);

        if(status == "required") {
            let input = document.getElementById('#'+this.brick_id);
            if(input) {
                (<HTMLSelectElement>input).classList.add("required");
            }
        }
        if(status == "loading") {
            let input = document.getElementById('#'+this.brick_id);
            if(input) {
                (<HTMLSelectElement>input).classList.add("loading");
            }
        }
        else if(status == "") {
            let input = document.getElementById('#'+this.brick_id);
            if(input) {
                (<HTMLSelectElement>input).classList.remove("required");
                (<HTMLSelectElement>input).classList.remove("loading");
            }
        }
    }

    async cb_pager_evt(goto_page: number) {
        this.setPage(goto_page);
    }

    cb_snapshot() {}
}
