import { TagItem } from "../codebricks-runtime/CBModels";
import { DrillGet} from "../codebricks-runtime/CBUtil";
import { CodeBrick } from "../codebricks-runtime/CodeBrick";
import { TemplateUtil } from "../codebricks-runtime/TemplateUtil"


export class SUse {
    static GetSUseParams(ci: CodeBrick, input: string) {
        //If we get here, it means the template resolved on the client, so we should post to the server
        //We want to jsut send a nsod the the server, containing only the data referenced in the template p values.
        //This is so the server can run the original template, for security reasons (so altered hacked values will not work)
        //So we need to extract the list of p values, from the parsed ins, and send that as a params object to the server.

        let params = {} as any;
        if(ci.parsed_ins) {
            let pi = ci.parsed_ins[input];

            let pvalues = [] as string[];
            if(pi) {             
                for(let leaf of pi.leaves) {
                    for(let part of leaf.parts) {
                        if(part.tag) {
                            if(part.tag.items) {
                                for(let ti of part.tag.items) {
                                    SUse.GetPValuesRecurse(ti, pvalues);
                                }
                            }
                        }
                    }
                }
            }

            let nsid = (ci.blueprint.nsid || "") + (ci.dc || "");
            let ns = ci.context.composition_runners[ci.cid].nsods[nsid];
          
            for(let pvalue of pvalues) {

                let tparts = pvalue.split('.');
                let dparts = [tparts[0]];

                if(tparts.length > 1 && tparts[1][0] == '@') {
                    dparts.push(tparts[1]);                
                }
                else {
                    dparts.push("@");
                }
                dparts.push('d');

                let val = DrillGet(dparts, ns);

                if(val !== undefined) {
                    params[dparts[0]] = params[dparts[0]] || {};
                    params[dparts[0]][dparts[1]] = val;
                }
            }
        }
        return params;
    }

    static GetPValuePairs(ci: CodeBrick, input: string) {
        //This is like above, but only {p: value}, for use by c-fileupload, which only posts form data kvps.
        let params = {} as any;
        if(ci.parsed_ins) {
            let pi = ci.parsed_ins[input];

            let pvalues = [] as string[];
            if(pi) {             
                for(let leaf of pi.leaves) {
                    for(let part of leaf.parts) {
                        if(part.tag) {
                            if(part.tag.items) {
                                for(let ti of part.tag.items) {
                                    SUse.GetPValuesRecurse(ti, pvalues);
                                }
                            }
                        }
                    }
                }
            }

            let nsid = (ci.blueprint.nsid || "") + (ci.dc || "");
            let ns = ci.context.composition_runners[ci.cid].nsods[nsid];
          
            for(let pvalue of pvalues) {

                // let tparts = pvalue.split('.');
                // let dparts = [tparts[0]];

                // if(tparts.length > 1) {
                //     if(tparts[1][0] == '@') {
                //         dparts.push(tparts[1]);
                //     }

                // }
                // else {
                //     dparts.push("@");
                // }
                // dparts.push('d');
                let tparts = TemplateUtil.GetTermParts(pvalue);//tv.split('.');
                let pparts = TemplateUtil.GetTermParts(pvalue);
                
                //convert a to a.@, and a.b to a.@.b, but leave a.@o, for template output including default (omitted @)
                if(tparts.length == 1) {
                    tparts.push('@');
                    pparts.push('@');
                    tparts.push('d'); //add the d because seq_out_data has the seq_obj inserted, and the data is under d
                }
                else if(tparts[1][0] != '@') {
                    tparts.splice(1, 0, '@', 'd');
                    pparts.splice(1, 0, '@');
                }
                else {
                    tparts.splice(2, 0, 'd');
                }

                let val = DrillGet(tparts, ns);

                if(val !== undefined) {
                    params[pparts.join(".")] = val;
                }
            }
        }
        return params;
    }

    private static GetPValuesRecurse(tag: TagItem, out_pvalues: string[]) {
        if(tag.type[0] == "p" && tag.slice && tag.slice[0] != "$") {
            out_pvalues.push(tag.slice);
        }
        if(tag.items) {
            for(let ti of tag.items) {
                this.GetPValuesRecurse(ti, out_pvalues);
            }
        }
    }
}