import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { GlobalEvent } from "../../shared-funcs/GlobalEvent";

export class ci_global_event_webcomponent extends HTMLElement {
    ci: web_ci_global_event | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_global_event(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-global-event', ci_global_event_webcomponent);

export class web_ci_global_event extends CodeBrick {

    element: HTMLElement;

    listening = {} as { [event:string] : any };

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        GlobalEvent.CreateHandler();
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        GlobalEvent.HandleEvent(input, cfg, this);
    }
    cb_validate(): boolean {
        return this.validate_sources("");
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        this.emit_state(status);
    }
    cb_snapshot() {}

    async cb_unload() {
        //console.log("ci-global-event "+this.brick_id+" UNLOAD ");
        let self = this;
        for(let event_name in self.listening) {
            let fun = self.listening[event_name];
            for(let i = (<any>window).codebricks_context.global_event_listeners[event_name].length - 1; i >=0; i--) {
                let gfun = (<any>window).codebricks_context.global_event_listeners[event_name][i];
                if(fun == gfun) {
                    //console.log("ci-global-event UNLOAD "+i);
                    (<any>window).codebricks_context.global_event_listeners[event_name].splice(i, 1);
                }
            }
        }
    }
}