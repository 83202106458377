
import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { ObjectDeepMerge } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { Chart, ChartItem } from 'chart.js/auto'
import { CBWebUtil } from "../controls/cb_web_util";

export class c_gauge_webcomponent extends HTMLElement {
    ci: web_c_gauge | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_gauge(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-gauge', c_gauge_webcomponent);

export class web_c_gauge extends CodeBrick {

    element: HTMLElement;
    chart: any;
    cfg: any;

    //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        let html = `<div class="c-gauge-layout" id="${this.brick_id}$layout">
                        <div>
                            <div class="c-gauge-title" id="${this.brick_id}$title"></div>
                            <div class="c-gauge" id="${this.brick_id}$container">
                                <canvas id="${this.brick_id}"></canvas>
                                <div class="c-gauge-center" id="${this.brick_id}$center"></div>
                            </div>
                        </div>
                        <div id="${this.brick_id}$legend" class="c-gauge-legend"></div>
                    </div>`;

        this.element.innerHTML = html;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksInput "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.cfg)+" => "+JSON.stringify(cfg));

        if(input == "cfg") {

            this.cfg = cfg;

            let container = document.getElementById(this.brick_id+'$container');
            if(container) {
                if(cfg.size) {
                    container.style.height = cfg.size;
                    container.style.maxWidth = cfg.size;
                    container.style.paddingBottom = "8%";              
                }

                // if(cfg.style_classes) {
                //     for(let c of cfg.style_classes) {
                //         container.classList.add(c);
                //     }
                // }
                CBWebUtil.ApplyElementStyles(container as HTMLElement, cfg, "gauge");
            }

            let self = this;
    
            let title_container = document.getElementById(self.brick_id+'$title');
            if(title_container) {
                if(cfg.title) {
                    title_container.innerHTML = '<h2>'+cfg.title+'</h2>';
                    CBWebUtil.ApplyElementStyles(title_container.firstElementChild as HTMLElement, cfg, "title");
                }
                else {
                    title_container.innerHTML = '';
                }
                
            }

            let center = document.getElementById(self.brick_id+'$center');
            if(center) {
                center.innerHTML = cfg.center_text || "";
                CBWebUtil.ApplyElementStyles(center.firstElementChild as HTMLElement, cfg, "center");
            }
        
            let canvas = document.getElementById(this.brick_id) as ChartItem;
            
            //let data = [cfg.value, cfg.max]
            if(canvas) {
                let chartconfig = {} as any;

                //let keys = Object.keys(data[0]);
                
                let labels = [] as string[];
                let values = [] as number[];
                let colors = [] as string[];
                let sum = 0;
                for(let segment of cfg.segments) {
                    if(segment.value && segment.value !== 0) {
                        labels.push(segment.name);
                        values.push(segment.value);
                        colors.push(segment.color)

                        sum += segment.value;
                    }
                }

                let remainder = cfg.max - sum;

                labels.push(cfg.remainder_label);
                values.push(remainder);
                colors.push(cfg.remainder_color);
                
                let dataset = {
                    label: "",
                    data: values,
                    backgroundColor: colors
                } as any;


                if(cfg.legend && cfg.legend != "none" && values.length > 0) {
                    let legends = "";
                    for(let i = 0; i < values.length; i++) {
                        let label; 
                        if(labels[i] && (label = labels[i].trim())) { //yes we are assigning, so only one =, we ajust are checking first that it is not undefined
                            legends += `<div class="c-gauge-legend-row"><div class="c-gauge-legend-label"><div class="c-gauge-legend-color" style="background-color:${colors[i]}"> </div>${label}</div><div class="c-gauge-legend-value">${values[i]}</div></div>`
                        }
                    }
                    let layout = document.getElementById(this.brick_id + "$layout");
                    if(layout) {
                        let direction = "column";
                        if(cfg.legend == "right") {
                            direction = "row";
                        }
                        else if(cfg.legend == "top") {
                            direction = "column-reverse";
                        }
                        else if(cfg.legend == "left") {
                            direction = "row-reverse";
                        }
                        layout.style.flexDirection = direction;

                        CBWebUtil.ApplyElementStyles(layout as HTMLElement, cfg, "layout");
                    }
                    
                    let legend_element = document.getElementById(this.brick_id + "$legend");
                    if(legend_element) {
                        legend_element.innerHTML = legends;
                        CBWebUtil.ApplyElementStyles(legend_element as HTMLElement, cfg, "legend");
                    }
                }
            
                // if(cfg.colors) {
                //     dataset.backgroundColor = [];
                //     for(let i = 0; i < 2; i++) {
                //         if(cfg.colors[i]) {
                //             dataset.backgroundColor.push(cfg.colors[i])
                //         }
                //     }
                // }

                // dataset.borderRadius = 6;
                // dataset.borderSkipped = false;

                let options = {
                    responsive: true,
                    maintainAspectRatio: false,
                    // plugins: {
                    //     datalabels: {
                    //         display: function(context:any) {  //We can't put this in the nativeoptions in the yaml - because it would be a string "function(..."
                    //             return context.dataset.data[context.dataIndex] !== 0; //This hides 0 labels
                    //         }
                    //     }
                    // },
                    plugins: {
                        legend: {
                            display: false,
                            position: 'bottom'
                        },
                        datalabels: {
                            display: false
                        }
                    },
                    rotation: cfg.start_angle, // start angle in degrees
                    circumference: cfg.end_angle - cfg.start_angle // sweep angle in degrees
                } as any;


                //if(cfg.legend) {
                    //if(cfg.legend != 'none') {
                        //options.plugins.legend.display = false;
                        //options.plugins.legend.position = cfg.legend;
                    //}
                //}

                chartconfig = {
                    type: "doughnut",
                    data: {
                        labels: labels,
                        datasets: [dataset]
                    },
                    options: options
                }

                if(this.cfg.nativeoptions && this.cfg.nativeoptions.chartjs) {
                    chartconfig = ObjectDeepMerge(chartconfig, this.cfg.nativeoptions.chartjs);
                }

                if(this.chart) {
                    this.chart.destroy();
                }
            
                this.chart = new Chart(
                    canvas,
                    chartconfig
                );
            }
        
        
            return {"@": cfg.value};
        }
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }

    cb_status(status: string): void {
        if(status == "required") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.add("required");
            }
        }
        else if(status == "") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.remove("required");
            }
        }
    }

    cb_snapshot() {}

}
