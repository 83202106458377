import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

import { basicSetup, EditorView } from 'codemirror';
import {keymap} from "@codemirror/view";
import {indentWithTab} from "@codemirror/commands";
import {javascript, javascriptLanguage, scopeCompletionSource} from "@codemirror/lang-javascript";
import {css, cssLanguage, cssCompletionSource} from "@codemirror/lang-css";
import {html, htmlLanguage, htmlCompletionSource} from "@codemirror/lang-html";
import {yaml} from "@codemirror/lang-yaml";
import {Cassandra, MariaSQL, MSSQL, MySQL, PLSQL, PostgreSQL, sql, SQLite} from "@codemirror/lang-sql";
import {autocompletion, CompletionContext} from "@codemirror/autocomplete";
import { syntaxTree } from '@codemirror/language';
import {parser as cbtemplateParser} from "../template_lang/cbtemplate-parser";
import {foldNodeProp, foldInside, indentNodeProp, indentUnit} from "@codemirror/language";
import {parseMixed} from "@lezer/common"
import {LRLanguage} from "@codemirror/language"
import {MergeView} from "@codemirror/merge"
import {EditorState} from "@codemirror/state"

export class c_editor_code_diffwebcomponent extends HTMLElement {
    ci: web_c_editor_code_dff | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;

            //standalone
            let ins = this.getAttribute('ins') as string;

            this.ci = new web_c_editor_code_dff(context, cid, name, dc, Number(idx), container_id, this, ins);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-editor-code-diff', c_editor_code_diffwebcomponent);

export class web_c_editor_code_dff extends CodeBrick {
    
    element: HTMLElement;
    view: any
    //shadowroot: ShadowRoot;
    token_list = null as any;
    cfg: any;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement, standalone_ins: string) {
        super(context, cid, name, dc, idx, container_id, standalone_ins);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        //Add this to all bricks that need to support standalone, as well as the ins parameter
        if(cid == undefined) {
            this.blueprint.name = element.id;
            this.brick_id = element.id + "_brick";
            (<any>window).so_bricks =  (<any>window).so_bricks || {};
            (<any>window).so_bricks[this.brick_id] = this;
        }

        let html = `<div class='c-editor-code-diff-container'><div id="${this.brick_id}" class="c-editor-code-diff"></div></div>`;
        //let html = `<div id="${this.brick_id}"></div>`;
        element.innerHTML = html;

        let self = this;
        
        //Add this also to all bricks that need to support standalone
        if(cid == undefined) {
            setTimeout(async function() {
                if(self.blueprint.ins) {
                    for(let input in self.blueprint.ins) {
                        let ret = await self.cb_event(input, self.blueprint.ins[input], {} as CBEventInfo);
                    }
                }
            } ,0);
        }
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        // if(cfg == null || JSON.stringify(cfg) == "{}") {
        //     return;
        // }
        //let self = this;

        if(input == "cfg") {
            if(!this.view) {
                let div = document.getElementById(this.brick_id);
                if(div) {
                    this.cfg = cfg;
                    let element = document.getElementById(this.brick_id);
                    if(element && cfg.height) {
                        let container = element.parentElement;
                        if(container) {
                            container.style.height = cfg.height;
                        }
                    }

                    let extensions = [basicSetup];
                    if(cfg.readonly) {
                        extensions.push(EditorView.editable.of(false));
                        extensions.push(EditorState.readOnly.of(true));
                    }

                    if(this.cfg.language == "javascript") {
                        extensions.push(javascript());
                        extensions.push(indentUnit.of("    "));
                        extensions.push(javascriptLanguage.data.of({
                            autocomplete: scopeCompletionSource(globalThis)
                        }));
                    }
                    else if(this.cfg.language == "css") {
                        extensions.push(css());
                        extensions.push(indentUnit.of("    "));
                        extensions.push(cssLanguage.data.of({
                            autocomplete: cssCompletionSource
                        }));
                    }
                    else if(this.cfg.language == "html") {

                        const mixedCBTemplateParser = cbtemplateParser.configure({
                            props: [
                            //   // Add basic folding/indent metadata
                            //   foldNodeProp.add({Conditional: foldInside}),
                            //   indentNodeProp.add({Conditional: cx => {
                            //     let closed = /^\s*\{% endif/.test(cx.textAfter)
                            //     return cx.lineIndent(cx.node.from) + (closed ? 0 : cx.unit)
                            //   }})
                            ],
                            wrap: parseMixed(node => {
                            return node.type.isTop ? {
                                parser: htmlLanguage.parser,
                                overlay: node => node.type.name == "Text"
                            } : null
                            })
                        })
                        
                        const cbtemplateLanguage = LRLanguage.define({parser: mixedCBTemplateParser})

                        extensions.push(cbtemplateLanguage);

                        extensions.push(html().support);

                    }
                    else if(this.cfg.language == "yaml") {
                        extensions.push(yaml());
                        extensions.push(indentUnit.of("  "));
                    }
                    else if(this.cfg.language && this.cfg.language.startsWith("sql")) {
                        if(this.cfg.language.toLowerCase() == "sql-postgresql") {
                            extensions.push(sql({dialect: PostgreSQL}));
                        }
                        else if(this.cfg.language.toLowerCase() == "sql-mssql") {
                            extensions.push(sql({dialect: MSSQL}));
                        }
                        else if(this.cfg.language.toLowerCase() == "sql-mysql") {
                            extensions.push(sql({dialect: MySQL}));
                        }
                        else if(this.cfg.language.toLowerCase() == "sql-mariasql") {
                            extensions.push(sql({dialect: MariaSQL}));
                        }
                        else if(this.cfg.language.toLowerCase() == "sql-sqlite") {
                            extensions.push(sql({dialect: SQLite}));
                        }
                        else if(this.cfg.language.toLowerCase() == "sql-cassandra") {
                            extensions.push(sql({dialect: Cassandra}));
                        }
                        else if(this.cfg.language.toLowerCase() == "sql-plsql") {
                            extensions.push(sql({dialect: PLSQL}));
                        }
                        else {
                            extensions.push(sql());
                        }
                    }

                    this.view = new MergeView({
                        a: {
                        doc: cfg.value_left,
                        extensions: extensions
                        },
                        b: {
                        doc: cfg.value_right,
                        extensions: extensions
                        },
                        parent: div
                    });
                }
            }
            else {
                this.view.a.dispatch({changes: {from: 0, to: this.view.a.state.doc.length, insert: cfg.value_left}});
                this.view.b.dispatch({changes: {from: 0, to: this.view.b.state.doc.length, insert: cfg.value_right}});
            }

        }
    }

    getValue() {
        //for static component use
        //return this.editor.state.doc.toString();
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        let container = document.getElementById(this.brick_id);
        if(container) {
            let outer = container.parentElement;
            if(outer) {
                if(status == "loading") {
                    container.classList.add("hidden");
                    outer.classList.add("loading");
                }
                else {
                    outer.classList.remove("loading");
                    container.classList.remove("hidden");
                }
            }
        }
    }

    cb_snapshot() {}
}