import { CBCompositionRunner } from "./CBCompositionRunner";
import { Brick, InsLeaves } from "./CBModels";
import { CodeBrick } from "./CodeBrick";

export const PrefabNameJoin: string = "»";
export const DC_JOIN: string = "#";


export function drill(path: string, data: any) {
    let tparts = path.split('.');

    let v = data as any;
    let res = v;
    
    let done = false;
    for(let p of tparts) {
        if(done) {
            res = undefined;
            break;
        }
        if(v && v[p] !== undefined) {
            if(typeof v[p] == 'object' && v[p] !== null) {
                v = v[p];
            }
            else {
                res = v[p];
                done = true;
            }
        }
        else {
            res = undefined;
            done = true;
        }
    }
    if(!done) {
        res = v;
    }

    return res;
}

export function DrillGet(tparts: string[], data: any) {
    let v = data as any;
    let res = v;
    
    let done = false;
    for(let p of tparts) {
        if(done) {
            res = undefined;
            break;
        }
        if(v && v[p] !== undefined) {
            if(typeof v[p] == 'object' && v[p] !== null) {
                v = v[p];
            }
            else {
                res = v[p];
                done = true;
            }
        }
        else {
            res = undefined;
            done = true;
        }
    }
    if(!done) {
        res = v;
    }

    return res;
}


export function DrillSet(dest_data: any, path: string, source_data: any) {
    let s = path.split('.');
    let d = dest_data || {};
    for(let p = 0; p < s.length; p++) {
        if(p == s.length - 1) {
            d[s[p]] = source_data;
        }
        else {
            if(d[s[p]] === undefined) {
                d[s[p]] = {};
            }
            d = d[s[p]];
        }
    }
    return dest_data;
}

export function GetPathsData(paths: any, data:any, target: any) {
    if(paths) {
        for(let p in paths) {
            if(paths[p] === 1) {
                target[p] = data[p];             
            }
            else if(data[p] !== undefined && data[p] !== null) {
                target[p] = target[p] || {};
                GetPathsData(paths[p], data[p], target[p]);
            }
        }
    }
}

export function MergePath_d(tparts: string[], from: any, into: any) {
    let from_at = from;
    let into_at = into.d;
    if(tparts.length == 1) {
        into.d = from;
        return;
    }
    for(let t = 1; t < tparts.length; t++) {
        let tpart = tparts[t];
        if(from_at === undefined) {
            return;
        }
        if(t == tparts.length - 1) {
            into_at[tpart] = from_at[tpart];
        }
        else {       
            into_at[tpart] = into_at[tpart] || {};
            into_at = into_at[tpart];
            if(!from_at) {
                return; //error path not found
            }
            from_at = from_at[tpart];
        }
    }
}

export function EscapeHtml(text: string) {
    if(text && typeof text == "string") {
        var map = {
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
        } as any;
        
        return text.replace(/[<>"']/g, function(m) { return map[m]; });
    }
    else {
        return text;
    }
  }

  
export function ValueFromSchema(schema: any) : any {
    let ret = {} as any;

    for(let key in schema) {
        if(schema[key].value) {
            ret[key] = schema[key].value;
        }
        else if(schema[key].properties) {
            ret[key] = ValueFromSchemaValue(schema[key]);
        }
    }

    return ret;
}

export function ValueToSchema(value: any) {
    let ret = {} as any;
    if(value) {
        for(let key in value) {
            ret[key] = {
                value: value[key]
            };
        }
    }
    return ret;
}

function ValueFromSchemaValue(schemavalue: any) : any {
    if(schemavalue.value) {
        return schemavalue.value;
    }
    else if(schemavalue.properties && schemavalue.type == "object") {
        let ret = {} as any;
        for(let p in schemavalue.properties) {
            ret[p] = ValueFromSchemaValue(schemavalue.properties[p]);
        }
        return ret;
    }
    //what about [] values
    else if(schemavalue.default !== undefined) {
        return schemavalue.default;
    }
    else {
        return "";
    }   
}

export function OutputSchemaToNodes(output_schema: any) {
    let ret = {} as any;

    if(output_schema) {
        let type = output_schema.type;
        if(type == "object") {
            let properties = output_schema["properties"];
            for(let p in properties) {  
                ret[p] = properties[p].desc;
            }
        }
    }
    
    return ret;
}

export function StripValuesFromSchema(schema: any) : any {
    let ret = {} as any;

    for(let key in schema) {
        if(schema[key].value) {
            delete schema[key].value;
        }
        else if(schema[key].properties) {
            for(let p in schema[key].properties) {
                StripValuesFromSchema(schema[key].properties[p]);
            }
        }
    }

    return ret;
}

export function ObjectDeepMerge(into: { [key:string]:any}, from: { [key:string]:any}) {
    //console.log(" enter "+JSON.stringify(into)+" <- "+ JSON.stringify(from));
    if(Array.isArray(from)) {
        //console.log(" array ");
        into = from;
        return into;
    }

    if(typeof from == 'object' && from !== null && typeof into == 'object' && into !== null) {
        for(let k2 in from) {

            //console.log(" "+k2+" in "+JSON.stringify(from));
            if(from[k2] !== undefined) { //This is wierd, there were phantom props? {} had props...?
                if(typeof from[k2] == "object" && from[k2]) {
                    //console.log( "recurse "+k2+" "+JSON.stringify(into[k2])+"<-"+ JSON.stringify(from[k2]));
                    into[k2] = ObjectDeepMerge(into[k2] || {}, from[k2]);
                }
                else {
                    //console.log(" assign "+k2+" "+into[k2]+"="+from[k2]);
                    into[k2] = from[k2];
                }
            }
        }
    }
    else {
        //console.log(" direct "+into+"="+from);
        into = from;
    }
    //console.log(" return "+JSON.stringify(into));
    return into;
}

export function Clone(source: { [key:string]:any}) : any {

    if(typeof source != "object" || source === null) {
        return source;
    }

    if(source instanceof RegExp) {
        let copy = new RegExp(source.source, source.flags);
        copy.lastIndex = source.lastIndex;
        return source;
    }

    if(Array.isArray(source)) {
        if(source.length == 0) {
            return source; //Some arrays look like the are len 0, but have values? If we don't do this they do not clone right.
        }

        let clone = [] as any;
        for(let i = 0; i < source.length; i++) {
            clone.push(Clone(source[i]));
        }

        return clone;
    }
    let clone = {} as any;

    for(let k in source) {
        clone[k] = Clone(source[k]);
    }

    return clone;
}
export function CloneMerge(source: { [key:string]:any}, target: { [key:string]:any}) {
    let clone = Clone(target || {});
    ObjectDeepMerge(clone, source);
    return clone;
}

export function CloneLimit(source: { [key:string]:any}, max_array: number, max_object: number) : any {

    return Clone(source); //For now the limiting is too annoying in the debugger

    if(typeof source != "object" || source === null) {
        return source;
    }

    if(Array.isArray(source)) {
        if(source.length == 0) {
            return source; //Some arrays look like the are len 0, but have values? If we don't do this they do not clone right.
        }

        let clone = [] as any[];
        for(let i = 0; i < source.length && i < max_array; i++) {
            clone.push(CloneLimit(source[i], max_array, max_object));
        }

        return clone;
    }
    let clone = {} as any;

    let i = 0;
    for(let k in source) {
        clone[k] = CloneLimit(source[k], max_array, max_object);
        i++;
        if(i > max_object) {
            break;
        }
    }

    return clone;
}

export function RegisterSOCallback(id: string, output: string, callback: Function) {
    (<any>window).so_callbacks = (<any>window).so_callbacks || {};
    (<any>window).so_callbacks[id+"_brick"] = (<any>window).so_callbacks[id+"_brick"] || {};
    (<any>window).so_callbacks[id+"_brick"][output] = [];

    (<any>window).so_callbacks[id+"_brick"][output].push(callback);
}

export function FindBrick(from: Brick, name: string) : Brick | null {
    if(from.name == name) {
        return from;
    }
    if(from.contains) {
        for(let child of from.contains) {
            let found = FindBrick(child, name);
            if(found) {
                return found;
            }
        }
    }
    return null;
}

export function FindBrickContainer(from: Brick, brick_name: string) : Brick | null {
    if(from.contains) {
        for(let child of from.contains) {
            if(child.name == brick_name) {
                return from;
            }
            let found = FindBrickContainer(child, brick_name);
            if(found) {
                return found;
            }
        }
    }
    return null;
}

export function SplitGetLast(val: string, splitter: string) {
    let s = val.split(splitter);
    return s[s.length-1];
}

export function array_move(arr: any, old_index: number, new_index: number) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing purposes
};

export async function IterateCompositionBricks(composition: Brick, fn: (brick: Brick) => Promise<void>) {
    await fn(composition);
    if(composition.contains) {
        for(let child of composition.contains) {
            await IterateCompositionBricks(child, fn);
        }
    }
}

export function EscapeRegExp(text: string) {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}


//Debugger
export interface BrickInfo {
    blueprint: Brick;
    parsed_ins?: InsLeaves;
    contains: BrickInfo[];
    debug_ios: { last_ins: { [input:string] : any }, last_outs: { [output:string]: any } };
    dci: number;
}

export function createBrickInfoTree(runner: CBCompositionRunner) {
    if(runner) {
        let root_brick_id = "cb"+runner.cid + "_" + runner.compos?.name;
        let root_ci = runner.context.bricks[root_brick_id];

        let brick_info_tree = createBrickInfoTreeRecurse(root_ci, null);

        return brick_info_tree;
    }
}

function createBrickInfoTreeRecurse(ci: CodeBrick, dci: number | null) : BrickInfo {
    let ret = {
        blueprint: { name: ci.blueprint.name, type: ci.blueprint.type, ins: ci.blueprint.ins, cement: ci.blueprint.cement, targets: {}, contains: [] },
        parsed_ins: ci.parsed_ins,
        contains: [],
        debug_ios: ci.debug_ios,
        dci: dci
    } as BrickInfo;

    if(ci.debug_ios && ci.debug_ios.last_outs && ci.debug_ios.last_outs["@brick_info_tree"]) {
        //splice in brick_info_tree from server sc-container
        ret.contains = Clone(ci.debug_ios.last_outs["@brick_info_tree"].contains);
        //delete ci.debug_ios.last_outs["@brick_info_tree"];
    }
    else if(ci.blueprint.contains) {
        for(let child_blueprint of ci.blueprint.contains) {
            let child_brick_ids = ci.context.composition_runners[ci.cid].get_brick_ids(child_blueprint.name, ci.dc);
            let i = child_brick_ids.length > 1 ? 0 : null;
            for(let child_brick_id of child_brick_ids) {
                let child_brick = ci.context.bricks[child_brick_id];
                if(child_brick) {
                    ret.contains.push(createBrickInfoTreeRecurse(child_brick, i));
                }
                else {
                    //console.log("createBrickInfoTreeRecurse: "+child_brick_id+" not found");
                }
                if(i !== null) {
                    i++;
                }
            }
        }
    }

    return ret;
}

export function RecuresePrependBrickInfoTree(brick_info: BrickInfo | undefined, pre: string) {
    if(brick_info) {
        brick_info.blueprint.name = pre + PrefabNameJoin + brick_info.blueprint.name;
        if(brick_info.contains) {
            for(let b of brick_info.contains) {
                RecuresePrependBrickInfoTree(b, pre); 
            }
        }
    }
}