export class DataTransform {

    //copied from v2
    static pivotData(data: any, row: any, col: any, dataCols: any, multiHeader = false) {
        if(data.length == 0) {
            return data;
        }
    
        var pivotCols = [] as any[];
        for(var i = 0; i < data.length; i++) {
            for(var d = 0; d < dataCols.length; d++) {
                DataTransform.addDistinctOrdered(pivotCols, DataTransform.dataCol('' + data[i][col], dataCols, d, multiHeader)); 
            }
        }
    
        var ret = [] as any[];
        var atRow = "-1";
        var r = null as any;
        var keys = Object.keys(data[0]); 
        for(var dr = 0; dr < data.length; dr++) {
            var drow = data[dr];
            var rowAdded = false;
            if(drow[row] != atRow) {
                r = DataTransform.findRow(ret, row, drow[row]);
                if(r == null) {
                    r = {}; 
                    atRow = drow[row];
                    for(var k = 0; k < keys.length; k++)
                    {
                        var dc = false;
                        for(var d = 0; d < dataCols.length; d++) {
                            if(keys[k] == dataCols[d]) {
                                dc = true;
                                break;
                            }
                        }
                        if(dc) {
                            continue;
                        }
                        if(keys[k] != col)
                        {
                            r[keys[k]] = drow[keys[k]] || "";
                        }
                    }
                    for(var pc = 0; pc < pivotCols.length; pc++) {
                        var set = false;
                        for(var d = 0; d < dataCols.length; d++) {
                            if(pivotCols[pc] == DataTransform.dataCol(drow[col], dataCols, d, multiHeader)) {
                                r[pivotCols[pc]] = drow[dataCols[d]] ? drow[dataCols[d]] : 0;
                                set = true;
                            }
                        }
                        if(!set) {
                            r[pivotCols[pc]] = 0;
                        }
                    }
                    ret.push(r);
                    rowAdded = true;
                }
                else {
                    atRow = drow[row];
                }
            }
            
            if(!rowAdded) {
                for(var d = 0; d < dataCols.length; d++) {
                    r[DataTransform.dataCol(drow[col], dataCols, d, multiHeader)] = r[DataTransform.dataCol(drow[col], dataCols, d, multiHeader)] + drow[dataCols[d]];
                }
            }
        }
        //console.log("pivoted: "+JSON.stringify(ret));
        return ret;
    }


    static groupData(data: any, groupcols: string[], countcols: string[], sumcols: string[]) {
        var groupDict = {} as any;
        for(var r = 0; r < data.length; r++) {
            var group = "";
            for(var g = 0; g < groupcols.length; g++) {
                group += data[r][groupcols[g]] + "^";
            }
            var grouprow = groupDict[group];
            if(!grouprow) {
                grouprow = {};
                for(var g = 0; g < groupcols.length; g++) {
                    grouprow[groupcols[g]] = data[r][groupcols[g]];
                }
                for(var c = 0; c < countcols.length; c++) {
                    grouprow[countcols[c]] = 1;
                }
                for(var s = 0; s < sumcols.length; s++) {
                    grouprow[sumcols[s]] = data[r][sumcols[s]];
                }
                groupDict[group] = grouprow;
            }
            else {
                for(var c = 0; c < countcols.length; c++) {
                    grouprow[countcols[c]]++;
                }
                for(var s = 0; s < sumcols.length; s++) {
                    grouprow[sumcols[s]] += data[r][sumcols[s]];
                }
            }
        }
    
        var keys = Object.keys(groupDict);
        var ret = [] as any[];
        for(var k = 0; k < keys.length; k++) {
            ret.push(groupDict[keys[k]]);
        }
        return ret;
    }

    static transformCols(data: any, coltransforms: { property: string, rename: string, hidden: boolean}[]) {
        if(data.length == 0) {
            return data;
        }
    
        var ret = [] as any[];
    
        var ikeys = Object.keys(data[0]); 
        var okeys = [] as any[];
        for(var k = 0; k < ikeys.length; k++) {
            var found = false;
            for(var t = 0; t < coltransforms.length; t++) {
                if(ikeys[k] == coltransforms[t].property) {
                    if(!coltransforms[t].hidden) {
                        okeys.push({ s : ikeys[k], d : coltransforms[t].rename || ikeys[k] });
                    }
                    found = true;
                    break;
                }
            }
            if(!found) {
                okeys.push({ s : ikeys[k], d : ikeys[k] });
            }
        }
    
        for(var r = 0; r < data.length; r++) {
            var row = {} as any;
            for(var k = 0; k < okeys.length; k++) {
                row[okeys[k].d] = data[r][okeys[k].s];
            }
            ret.push(row);
        }
        return ret;
    }

    private static dataCol(col: any, dataCols: any, d: any, multiHeader: any) {
        if(dataCols.length == 1 && !multiHeader) {
            return col; //dataCols[0];
        }
        return col + '_' + dataCols[d];
    }
    
    private static addDistinctOrdered(list: any, v: any) {
        for(var i = 0; i < list.length; i++) {
            if(v == list[i]) {
                return;
            }
            if (DataTransform.compareMaybeDate(v, list[i])) {
                list.splice(i, 0, v);
                return;
            }
        }
        list.push(v);
    }

    private static compareMaybeDate(v1: any, v2: any) {
        //check if date in form MMM-YY
        if (!v1) {
            v1 = '';
        }
        if (!v2) {
            v2 = '';
        }
        // if (v1.length == 6 && v2.length == 6 && v1[3] == '-' && v2[3] == '-') {
        //     return moment(v1, "MMM-YY") < moment(v2, "MMM-YY");
        // }
        return v1 < v2;
    }
    
    private static addDistinct(list: any, v: any) {
        for(var i = 0; i < list.length; i++) {
            if(v == list[i]) {
                return;
            }
        }
        list.push(v);
    }
    
    private static findRow(data: any, colname: any, value: any) {
        for(var r = 0; r < data.length; r++) {
            if(data[r][colname] == value) {
                return data[r];
            }
        }
        return null;
    }
}