export class Select {
    static getOptions(cfg: any, blueprint: any) :{ value: string, label: string, full: any, value_snapshot: any }[] {
        let options = [] as { value: string, label: string, full: any, value_snapshot: any }[];

        if(cfg.extra_options && cfg.extra_options.length > 0) {
            this.addOptions(cfg.extra_options, cfg, blueprint, options);
        }

        if(cfg.options && cfg.options.length > 0) {
            this.addOptions(cfg.options, cfg, blueprint, options);
        }
        return options;
    }

    private static addOptions(add_options: any, cfg: any, blueprint: any, options: { value: string, label: string, full: any, value_snapshot: any }[]) {
        if(add_options && add_options.length > 0) {
            
            let keys = Object.keys(add_options[0]);
            for(let i in add_options) {
                if(typeof add_options[i] != "object") {
                    let value_snapshot = undefined;
                    if(blueprint.ins.add_options && blueprint.ins.add_options[i]) {
                        //If we have extra options like {{'Today';date}}, we want that in the snapshot
                        value_snapshot = blueprint.ins.add_options[i];
                    }
                    options.push({value: add_options[i], label: add_options[i], full: add_options[i], value_snapshot: value_snapshot});
                }
                else if(typeof add_options[i] == "object") {
                    let id = '';
                    if(cfg.value_column && keys.includes(cfg.value_column)) {
                        id = add_options[i][cfg.value_column];
                    }
                    else {
                        id = add_options[i][keys[0]];
                    }

                    let label = '';
                    if(cfg.label_column && keys.includes(cfg.label_column)) {
                        label = add_options[i][cfg.label_column];
                    }
                    else {
                        let lkid = 0;
                        if(keys.length > 1) {
                            lkid = 1;
                        }
                        label = add_options[i][keys[lkid]];
                    }

                    let value_snapshot = undefined;
                    if(blueprint.ins.add_options && blueprint.ins.add_options[i]) {
                        //If we have extra options like {{'Today';date}}, we want that in the snapshot
                        value_snapshot = blueprint.ins.add_options[i][id];
                    }

                    options.push({value: id, label: label, full: add_options[i], value_snapshot: value_snapshot });

                }
            }
        }
    }

    static getSingleValue(cfg: any, options: { value: string, label: string, full: any }[], val: any) : { value: string | undefined, label: string | undefined, full: any } {

        if(options ) {
            if(val || val === 0) {
                for(let option of options) {
                    if(val == option.value) {
                        return { value: option.value, label: option.label, full: option.full };
                    }
                }
            }
        }
        if((val === "" || val === undefined || val === null) && options.length > 0) {
            return { value: options[0].value, label: options[0].label, full: options[0].full };
        }
        return { value: undefined, label: undefined, full: {} };
    }

    static getMultiValue(cfg: any, options: { value: string, label: string }[]) : { value: string | undefined, label: string | undefined } {
        let val = cfg.value;

        let selected_options = [] as { value: string, label: string }[];
        if(options ) {
            if(val !== "" && val !== undefined && val !== null) {
                let vals = val.split(",");
                for(let v_ of vals) {
                    let v = v_.trim();

                    for(let option of options) {
                        if(v == option.value) {
                            selected_options.push(option);
                        }
                    }
                }
            }
        }

        let select_all = false;
        if(val == "" && cfg.all_by_default) {
            select_all = true;
        }
        if(selected_options.length == options.length) {
            select_all = true;
        }

        if(select_all && cfg.all_value) {
            return { value:cfg.all_value, label: "All" }; 
        }

        let value_str = "";
        let label_str = "";
        for(let o of selected_options) {
            if(value_str) {
                value_str += ",";
                label_str+= ", ";
            }
            value_str += o.value;
            label_str += o.label;
        }

        return { value: value_str, label: label_str };
    }
}