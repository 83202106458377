import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class ci_script_js_webcomponent extends HTMLElement {
    ci: web_ci_script_js | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_ci_script_js(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('ci-script-js', ci_script_js_webcomponent);

export class web_ci_script_js extends CodeBrick {

    element: HTMLElement;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {

        //console.log(this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.cfg)+" => "+JSON.stringify(cfg));

        if(input == "cfg")
        {
            let js = cfg.function;
            try {
            
                //console.log("script "+this.blueprint.name+" params: "+JSON.stringify(cfg.params));

                let func = eval('(' + js + ')');

                let cid = this.cid;

                let cb = {
                    brick_event : async function(brick_name: string, input: string, cfg: any) : Promise<any> {
                        let brick_brick_id = "cb"+cid + "_" + brick_name;
                        //console.log("brick_brick_id "+brick_brick_id);
                        return await (<any>globalThis).codebricks_context.bricks[brick_brick_id].cb_event(input, cfg, cfg);
                    }
                }

                let res = await func(cfg.params, cb);

                // if(this.blueprint.name == "NoRoleSelected") {
                //     console.log("script "+this.blueprint.name+" params: "+JSON.stringify(cfg.params) + " res "+JSON.stringify(res));
                // }

                //console.log(this.brick_id+" script response "+JSON.stringify(res));//+" "+JSON.stringify(res.headers));

                //this.cb_emit({"@": res}, trigger);

                return {"@": res };
                //this.cb_emit({"@": res}, undefined, true);
            }
            catch(err) {
                console.error("ci-script-js "+this.brick_id+": "+err +" "+js+"; "+err.stack);
            }
        }
    }
    cb_validate(): boolean {
        return this.validate_sources("");
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        this.emit_state(status);
    }
    cb_snapshot() {}
}
