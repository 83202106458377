import { InsLeaves, ParsedLeaf, TPart } from "./CBModels";
import { TagItemParser } from "./TagItemParser";


export class LeafParser {

    static Parse(ins: any): InsLeaves {
        let ret = { } as InsLeaves;
        if(ins) {
            for(let input in ins) {
                let input_leaves = [] as ParsedLeaf[];
                let path = [] as string[];
                let has_tags = LeafParser.ParseRecurse(ins[input], path, input_leaves);
                if(input_leaves.length > 0) {
                    ret[input] = { leaves: input_leaves, has_tags: has_tags, has_ran: false };
                }
            }
        }
        return ret;
    }

    private static ParseRecurse(val: any, path: string[], leaves: ParsedLeaf[]): boolean {
        if(typeof val == "string") {
            let pl = LeafParser.ParseString(val);
            if(pl.has_tags) {
                leaves.push( { path: path, parts: pl.tparts, firing: false } );
                return true;
            }
            return false;
        }
        else if(Array.isArray(val)) {
            let has_tags = false;
            for(let i = 0; i < val.length; i++) {
                let split_path = [] as string[];
                for(let p of path) {
                    split_path.push(p);
                }
                split_path.push("" + i);
                if(this.ParseRecurse(val[i], split_path, leaves)) {
                    has_tags = true;
                }
            }
            return has_tags;
        }
        else if(typeof val == "object") {
            let has_tags = false;
            for(let prop in val) {
                let split_path = [] as string[];
                for(let p of path) {
                    split_path.push(p);
                }
                split_path.push(prop);
                if(this.ParseRecurse(val[prop], split_path, leaves)) {
                    has_tags = true;
                }
            }
            return has_tags;
        }
        return false;
    }

    private static ParseString(tstr: string) : { has_tags: boolean, tparts: TPart[] } {
        let i = 0;
        let start = 0;
        let ret = {
            has_tags: false,
            tparts: [] as TPart[]
        };
        while(i < tstr.length) {
            if(tstr[i] == '{') {
                i++;
                if(tstr[i] == '{') {
                    if(i-1 > start) {
                        ret.tparts.push({ tag: null, str: tstr.substring(start, i-1) });
                    }
                    i++;
    
                    let pass_i = { i : i };

                    let tag_item = TagItemParser.ParseTagItem(tstr, pass_i, "}}");
                    ret.tparts.push({tag: tag_item, str: null});
                    ret.has_tags = true;

                    i = pass_i.i;
                    start = i;
                }
            }
            else {
                i++;
            }
        }
        if(start <= tstr.length - 1) {
            ret.tparts.push({tag: null, str: tstr.substring(start)});
        }
        return ret;
    }

}