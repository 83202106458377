import { InLeaves } from "./CBModels";
import { Clone } from "./CBUtil";
import { TagItemResolver } from "./TagItemResolver";

export class LeafResolver {

    static Resolve(in_leaves: InLeaves, template_input_obj: any, nsod: any, run_id: number, source: string, source_output: string, system_options: any, unresolved_out: string[]) : any  {

        if(in_leaves == undefined || !in_leaves.has_tags) {
            return template_input_obj; 
        }

        let resolver = new TagItemResolver(
            nsod, 
            run_id, 
            source,
            source_output,
            system_options
        );

        let res_obj = undefined;
        if(typeof template_input_obj == "object") {
            res_obj = Clone(template_input_obj);
        }

        let all_resolved = true;
        let firing = false;

        for(let leaf of in_leaves.leaves) {
            let leaf_val;
            leaf.firing = false;
            for(let part of leaf.parts) {
                if(part.tag) {                
                    let mode = source == "" ? 0 : 1;
                    let init_val = undefined              //this is from the tag_val send by sc-use
                    if(!in_leaves.has_ran && mode == 1 && part.tag.val === undefined) {
                        init_val = resolver.ResolveTag(part.tag, 0, unresolved_out); //It has never ran, so init phase first
                        if(part.tag.firing) {
                            firing = true;
                        }
                    }
                                   
                    let tag_val = resolver.ResolveTag(part.tag, mode, unresolved_out);
                    
                    if(tag_val === undefined && init_val !== undefined) {
                        tag_val = init_val;
                    }

                    if(part.tag.firing) {
                        firing = true;
                        leaf.firing = true;
                    }
                
                    if(tag_val === undefined) {
                        all_resolved = false;
                    }
                    else {
                        if(leaf_val === undefined) {
                            leaf_val = tag_val;
                        }
                        else {
                            leaf_val = "" + (leaf_val || "") + tag_val;
                        }
                    }
                }
                else if(part.str) {
                    leaf_val = "" + (leaf_val || "") + (part.str || "");
                }
            }
            if(res_obj !== undefined) {
                LeafResolver.SetPath(leaf.path, leaf_val, res_obj);
            }
            else {
                res_obj = leaf_val; //cfg is a string, so it is the leaf
            }
        }

        in_leaves.has_ran = true;

        if(!all_resolved || !firing) {
            return undefined;
        }

        return res_obj; 
    }

    static SetPath(path: string[], value: any, dest_obj: any) {
        if(path.length == 0) {
            return value;
        }
        if(typeof dest_obj != "object") {
            dest_obj = {};
        }

        let ret = dest_obj || {};
        for(let p = 0; p < path.length; p++) {
            if(p == path.length - 1) {
                ret[path[p]] = value;
            }
            else {
                ret[path[p]] = ret[path[p]] || {};
                ret = ret[path[p]];
            }
        }
    }
};