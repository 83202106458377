import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";

export class c_notifier_webcomponent extends HTMLElement {
    ci: web_c_notifier | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_notifier(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-notifier', c_notifier_webcomponent);

export class web_c_notifier extends CodeBrick {

    element: HTMLElement;
    handler: any;
    clicked_on = false;

    latest_notification_id = 0;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        let innerHTML = `<div class="c-notifier-base" id="${this.brick_id}"><div class="c-notifier-container">
            <div id="${this.brick_id}$notification" class="c-notifier-notification c-notifier-info"></div>
        </div></div>`;

        //this.element.innerHTML = innerHTML;
        window.document.body.insertAdjacentHTML('beforeend', innerHTML);
        let self = this;

        let container = document.getElementById(this.brick_id);
        if(container) {
            window.addEventListener("mousedown",function(e: any) {
                //Hide the menus if visible
                if(container) {
                    
                    if(container.contains(e.target)) {
                        self.clicked_on = !self.clicked_on;
                        let notification = document.getElementById(self.brick_id + "$notification");
                        if(notification) {
                            if(self.clicked_on) {
                                notification.classList.remove("c-notifier-pin");
                                notification.classList.add("c-notifier-pinned"); 
                            }
                            else {
                                notification.classList.remove("c-notifier-pinned");
                                notification.classList.add("c-notifier-pin");
                            }
                        }
                    }
                    else {
                        container.classList.remove('c-notifier-container-show');
                        self.clicked_on = false;
                        let notification = document.getElementById(self.brick_id + "$notification");
                        if(notification) {
                            notification.classList.remove("c-notifier-pinned");
                            notification.classList.add("c-notifier-pin");
                        }
                    }
                    // if(self.clicked_on) {
                    //     setTimeout(function() {
                    //         let notification = document.getElementById(self.brick_id+"$notification");
                    //         if(notification) {
                    //             notification.innerHTML = "";
                    //         }
                    //     }, 300);
                    // }
                }
            });
            // container.addEventListener("mousedown", function(event){
            //     self.clicked_on = true;
            //     event.stopPropagation();
            // });
        }

        // window.addEventListener('click', function(e:any) {
        //     let notification_container = document.getElementById(self.brick_id);
        //     if(notification_container) {
        //         if(!notification_container.contains(e.target)) {
        //             notification_container.classList.remove('c-notifier-container-show');
        //         }
        //     }
        // });

    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        if(input == "show") {
            //this.clicked_on = false;
            
            let notification = document.getElementById(this.brick_id+"$notification");
            if(notification) {
                notification.innerHTML = cfg.message;
                notification.classList.remove('c-notifier-error');
                notification.classList.remove('c-notifier-alert');
                notification.classList.remove('c-notifier-success');
                notification.classList.remove('c-notifier-info');
                notification.classList.remove("c-notifier-pinned");
                notification.classList.add("c-notifier-pin");
                if(cfg.type) {
                    cfg.type = cfg.type.toLowerCase();
                }
                if(cfg.type == 'error') {
                    notification.classList.add('c-notifier-error');
                }
                else if(cfg.type == 'alert') {
                    notification.classList.add('c-notifier-alert');
                }
                else if(cfg.type == 'success') {
                    notification.classList.add('c-notifier-success');
                }
                else {
                    notification.classList.add('c-notifier-info');
                }
            }
            let container = document.getElementById(this.brick_id);
            if(container) {             
                this.latest_notification_id++;
                container.classList.add('c-notifier-container-show');
                // container.classList.remove("hidden");
                if(this.blueprint.ins.cfg && this.blueprint.ins.cfg.auto_hide_ms) {
                    let self = this;
                    let open_id = this.latest_notification_id;
                    setTimeout(function() {
                        if(container && self.latest_notification_id == open_id && !self.clicked_on) {//!self.clicked_on) {
                            container.classList.remove('c-notifier-container-show');
                        }
                    }, this.blueprint.ins.cfg.auto_hide_ms);

                    // setTimeout(function() {
                    //     if(container && !self.clicked_on) {
                    //         let notification = document.getElementById(self.brick_id+"$notification");
                    //         if(notification) {
                    //             notification.innerHTML = "";
                    //         }
                    //     }
                    // }, this.blueprint.ins.cfg.auto_hide_ms+300);
                }
            }
        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}

}


