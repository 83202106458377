import { cb_popout } from "./cb_popout";

export class cb_tooltip {
    static set_tooltips(parent_element = null as Element | null) {

        let tooltip_elements = (parent_element || document).querySelectorAll('[tooltip]');

        for(let t = 0; t < tooltip_elements.length; t++) {
            cb_tooltip.set_tooltip(tooltip_elements[t]);
        }

    }

    static set_tooltip(tooltip_element: Element) {
        let te = tooltip_element;

        let has_tooltip_handler = te.getAttribute("has_tooltip_handler");
        if(has_tooltip_handler) {
            return;
        }

        if(!(<any>window).codebricks_context.has_tooltip) {
            let tooltip_pop = `<div id="cb_tooltip" class="tooltip-pop hidden"></div><div id="cb_tooltip_arrow" class="tooltip-arrow hidden"></div>`;

            window.document.body.insertAdjacentHTML('beforeend', tooltip_pop);

            (<any>window).codebricks_context.has_tooltip = true;
        }

        te.addEventListener("mouseenter", function(event: any) {

            let new_drop = document.getElementById("cb_tooltip");
            let new_arrow = document.getElementById("cb_tooltip_arrow");

            if(new_drop == null || new_arrow == null) {
                return
            }

            let tooltip = this.getAttribute("tooltip");

            if(tooltip == "" || tooltip == null) {
                return;
            }

            new_drop.innerHTML = tooltip;

            let width = this.offsetWidth;

            var offset = cb_tooltip.getRect(this);
            new_drop.classList.remove("hidden");
            new_arrow.classList.remove("hidden");

            let drop_computed_style = getComputedStyle(new_drop);

            let dropHeight = Number(drop_computed_style.height.replace("px",""));

            let use_top_offset = offset.y - dropHeight;// - height

            let dropWidth = Number(drop_computed_style.width.replace("px",""));

            let use_offset_x = offset.x + width/2 - dropWidth/2;
            
            let arrow_height = 4; 
            new_arrow.style.top = (offset.y - arrow_height) + "px";
            new_arrow.style.left = (offset.x + width/2) + "px";

            //prevent the drop from going out the screen horizontally   
            let screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if(offset.x + (dropWidth/2) > screenWidth) {
                use_offset_x = offset.x - dropWidth + offset.w;
            }
            else if(use_offset_x - (dropWidth/2) < 0) {
                use_offset_x = 0;
            }

            new_drop.style.top = (use_top_offset - arrow_height) + "px";
            new_drop.style.left = (use_offset_x) + "px";
            
        });

        te.addEventListener("mouseleave", function(event: any) {
            cb_tooltip.hide();
        });

        te.setAttribute("has_tooltip_handler", "true");
    }

    static hide() {
        let new_drop = document.getElementById("cb_tooltip");
        let new_arrow = document.getElementById("cb_tooltip_arrow");

        if(new_drop == null || new_arrow == null) {
            return
        }

        new_drop.classList.add("hidden");
        new_arrow.classList.add("hidden");
    }

    static getRect(element:any) {
        let rect = element.getBoundingClientRect();
        let x = rect.x;
        let y = rect.y;

        y += window.scrollY;
        x += window.scrollX;

        return { x : x, y : y, w: rect.width, h : rect.height};
    }
}